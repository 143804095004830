import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import Profile from './Profile';
// import Details from './Details';
import SptView from './../Company/Spt/Spt.view';
import SptFormView from './../Company/Spt/Spt.form.view';
import A1View from './../Company/SptIn/A1/A1.view';
import A2View from './../Company/SptIn/A2/A2.view';
import B1View from './../Company/SptIn/B1/B1.view';
import B2View from './../Company/SptIn/B2/B2.view';
import B3View from './../Company/SptIn/B3/B3.view';
import Induk1111FormView from './../Company/SptIn/Induk1111/Induk1111.form.view';
import AB1111FormView from './../Company/SptIn/AB1111/AB1111.form.view';
import DDMView from './../Company/SptIn/DDM/DDM.view';
import DDMFormView from './../Company/SptIn/DDM/DDM.form.view';
import SSPIIView from './../Company/SptIn/SSPII/SSPII.view';
import SSPIIFormView from './../Company/SptIn/SSPII/SSPII.form.view';
import SSPIIIView from './../Company/SptIn/SSPIII/SSPIII.view';
import SSPIIIFormView from './../Company/SptIn/SSPIII/SSPIII.form.view';
import SSPIVView from './../Company/SptIn/SSPIV/SSPIV.view';
import SSPIVFormView from './../Company/SptIn/SSPIV/SSPIV.form.view';
import SSPVView from './../Company/SptIn/SSPV/SSPV.view';
import SSPVFormView from './../Company/SptIn/SSPV/SSPV.form.view';
import SptListView from './../Company/Spt/Spt.list.view';
import WpForm from './../Company/Wp/Wp.form.view';
import WpView from './../Company/Wp/Wp.view';
import HistoryView from './../Company/history/history.view';
import SptWpView from './../Company/Spt/Spt.wp.view';
import SptBulkFromView from './../Company/Spt/Spt.form.bulk.view';

export default () => (
  <Switch>
    {/* <Route path="/widget/profile" render={(props) => (
      <Profile router={props}/>
    )}/>
    <Route path="/widget/details/:tab" render={(props) => (
      <Details router={props}/>
    )}/> */}
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-vs/:id" render={(props) => ( <SSPVFormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-vs" render={(props) => ( <SSPVView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-ivs/:id" render={(props) => ( <SSPIVFormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-ivs" render={(props) => ( <SSPIVView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-iiis/:id" render={(props) => ( <SSPIIIFormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-iiis" render={(props) => ( <SSPIIIView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-iis/:id" render={(props) => ( <SSPIIFormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/spt-1111-ssp-iis" render={(props) => ( <SSPIIView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/uangdimuka/:id" render={(props) => ( <DDMFormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/uangdimuka" render={(props) => ( <DDMView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/induk" render={(props) => ( <Induk1111FormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/ab" render={(props) => ( <AB1111FormView router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/b3" render={(props) => ( <B3View router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/b2" render={(props) => ( <B2View router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/b1" render={(props) => ( <B1View router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/a2" render={(props) => ( <A2View router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan/a1" render={(props) => ( <A1View router={props}/> )}/>
    <Route path="/widget/spt_open/:id/:npwp/:tahun/:masa/:pembetulan" render={(props) => ( <SptListView router={props}/> )}/>
    <Route path="/widget/spt-bulk/:id" render={(props) => ( <SptBulkFromView router={props}/> )}/>
    <Route path="/widget/spt/:id" render={(props) => ( <SptFormView router={props}/> )}/>
    <Route path="/widget/spt/:id" render={(props) => ( <SptFormView router={props}/> )}/>
    <Route path="/widget/spt" render={(props) => ( <SptView router={props}/> )}/>
    <Route path="/widget/wp/:npwp" render={(props) => ( <WpForm router={props}/> )}/>
    <Route path="/widget/wp" render={(props) => ( <WpView router={props}/> )}/>
    <Route path="/widget/history" render={(props) => ( <HistoryView router={props}/> )}/>
  </Switch>
)