import React from 'react';
import { TabsContainer, Tabs, Tab, DialogContainer, List, ListItem, Button, DataTable, TableBody, TableRow, TableColumn, Card } from 'react-md';
import ListView from '../../../components/Base/ListView';
import SptService from './Spt.service';
import { connect } from 'react-redux';
import moment from 'moment';
import { SearchField } from '../../../libs/react-mpk';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { validation, TextfieldMask, Searchfield, Textfield, FileInputNext } from '../../../components/Form';
import counterpart from 'counterpart';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import Induk1111Service from './../SptIn/Induk1111/Induk1111.service';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import SptBpe from './Spt.bpe'
import WpService from '../Wp/Wp.service';
import utilservice from '../../../services/utilsService'

@reduxForm({form: 'SptViewForm', destroyOnUnmount: true, initialValues: {tahun: (new Date()).getFullYear(),}})
@connect((state)=> ({
    // EPPT CORE
    ...SptService.stateConnectSetting()(state),
    formData: getFormValues('SptViewForm')(state),
    auth: state.auth.user,
    // EPPT CORE
}), SptService.actionConnectSetting())
class SptView extends ListView {
    service = SptService;

    apiPath = 'getSptByNpwpAndTahun'

    plainTable(){ return false; }

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    constructor(props){
        super(props)
        this.state = {
          ...this.state,
          LAPOR: '',
          bulkBPE: [],
          formReady: false,
          wps: [],
          wpsMap: {}
        }
    } 

    componentDidMount(){
      this._getWp()
    }

    beforeFetch(params) {
      params.npwp = this.props.formData.npwp
      params.tahun = this.props.formData.tahun
      return params
    }

    async _getWp() {
      var wpRes = await WpService.api.getAll();
      this.setState({wps: wpRes.data, wpsMap: wpRes.data.reduce((pv, cv)=> {
        pv[cv.npwp] = cv
        return pv
      }, {}), formReady: true});
    }

    overideTotal(res){
      return res.data.data.length
    }

    overideData(res){
      return res.data.data
    }

    upperTopBar(){
        return (
          <div id="bpe_bulk" style={{ height: '0', overflow: 'hidden' }}>
            {this.state.bulkBPE.length > 0 &&
              <div>
                {this.state.bulkBPE.map((d, index)=> {
                  return <div style={{ width: 720, height: 900 }} className={`bpe_p${index}`} dangerouslySetInnerHTML={{ __html: d }}></div>
                })}
              </div>
            }
          </div>
        );
    }

    header(){
      return (
        <div>
          <TabsContainer colored activeTabIndex={0}>
            <Tabs tabId="simple-tab" style={{ padding: "0px !important;" }}>
              <Tab
                label="SPT"
                onClick={() => {
                  var path = this.props.router.location.pathname;
                  var pathnext = path.split("/")[1];
                  this.navService.redirectTo(`/${pathnext}/spt`);
                }}
              ></Tab>
              <Tab
                label="Wajib Pajak"
                onClick={() => {
                  var path = this.props.router.location.pathname;
                  var pathnext = path.split("/")[1];
                  this.navService.redirectTo(`/${pathnext}/wp`);
                }}
              ></Tab>
              {/* <Tab
                label="Riwayat"
                onClick={() => {
                  var path = this.props.router.location.pathname;
                  var pathnext = path.split("/")[1];
                  this.navService.redirectTo(`/${pathnext}/history`);
                }}
              ></Tab> */}
            </Tabs>
          </TabsContainer>
          {this.state.formReady && <Card>
            <div className="md-grid">
              <Field
              label         = 'Wajib Pajak'
              name          = 'npwp'
              className     = 'md-cell md-cell--5'
              component     = {Searchfield}
              valueField    = 'npwp'
              searchField   = 'npwp'
              searchField2  = 'name'
              options = {this.state.wps}
              itemTemplate  = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp+" "+d.name,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
              <Field
                label="Tahun"
                name="tahun"
                className="md-cell md-cell--3"
                component={Textfield}
                validate={validation.required}
              />
              <div className="md-cell md-cell--2">
                <Button
                  secondary
                  flat
                  style={{ width: "100%", marginTop: 20 }}
                  onClick={this.handleSearchClear.bind(this)}
                >
                  Hapus
                </Button>
              </div>
              <div className="md-cell md-cell--2">
                <Button
                  primary
                  raised
                  style={{ width: "100%", marginTop: 20 }}
                  onClick={this.props.handleSubmit(
                    this.handleSearch.bind(this)
                  )}
                >
                  Cari
                </Button>
              </div>
            </div>
          </Card>}
          <br />
        </div>
      );
    }

    async handleSearchClear(){
      this.props.initialize({})
      this.fetchData()
  }

  async handleSearch(){
    this.fetchData({npwp: this.props.formData.npwp, tahun: this.props.formData.tahun});
  }

    async bulkBPE(){
        var selected = this.props.table.selected
        var getNtte = selected
        var validasiStatus = []
        getNtte.map((cekStatus, index)=>{
          if(cekStatus  .fgStatusRekam == 1){

          } else {
            validasiStatus.push(cekStatus)
          }
        })
        if(validasiStatus.length > 0){
          izitoast.warning({
            title: "Validasi",
            message: "Status selain Lapor Sukses, Gagal Menarik NTTE, dan Berhasil Menarik NTTE tidak dapat didownload."
          })
        } else {
          var htmlBpes = []
          getNtte.map((data, index)=> {
            // parameter
            var ntte = ''
            var ntpa = ''
            var timeStamp = ''
            var aspNo = ''
            var amount = ''
            var status = ''
            var pembetulan = ''
            var masaTahun = ''
            var taxType = ''
            var address = ''
            var name = ''
            var npwp = '' // 0 - 14
            var npwp16 = '-'
            var nitku = '-'
            if(data.idNtte) ntte = data.idNtte
            if(data.ntpa) ntpa = data.ntpa
            if(data.tglTerima){
              var a = data.tglTerima.split("-").join("/")
              timeStamp = a
            }
            if(data.aspNo) aspNo = ''
            if(data.nilaiLbkbn){
              var d = accounting.formatMoney(data.nilaiLbkbn, { symbol: "Rp.", precision: 2, thousand: ",", decimal: "." });
              amount = d
            }
            if(data.fgLbkbn){
              var x = data.fgLbkbn.split('')
              var fgLbkbnx = x[0]
              if(fgLbkbnx == "0" || fgLbkbnx == 0){
                status = "Nihil"
              } else if(fgLbkbnx == "1" || fgLbkbnx == 1){
                status = "Kurang Bayar"
              } else if(fgLbkbnx == "2" || fgLbkbnx == 2){
                status = "Lebih Bayar"
                amount = "( " + amount + " )"
              }
            }
            if(data.pembetulan){
              pembetulan = data.pembetulan + ""
            } else {
              pembetulan = "0"
            }
            if(data.masa1){
              if(data.masa1 == 1) masaTahun = "Januari"
              if(data.masa1 == 2) masaTahun = "Februari"
              if(data.masa1 == 3) masaTahun = "Maret"
              if(data.masa1 == 4) masaTahun = "April"
              if(data.masa1 == 5) masaTahun = "Mei"
              if(data.masa1 == 6) masaTahun = "Juni"
              if(data.masa1 == 7) masaTahun = "Juli"
              if(data.masa1 == 8) masaTahun = "Agustus"
              if(data.masa1 == 9) masaTahun = "September"
              if(data.masa1 == 10) masaTahun = "Oktober"
              if(data.masa1 == 11) masaTahun = "November"
              if(data.masa1 == 12) masaTahun = "Desember"
            }
            if(data.tahun) masaTahun += " / " + data.tahun
            taxType = "SPT PPN dan PPnBM 1111"
            address = this.state.wpsMap[data.npwp].address
            name = this.state.wpsMap[data.npwp].name
            if(this.state.wpsMap[data.npwp] && this.state.wpsMap[data.npwp].npwp22) {
              if (this.state.wpsMap[data.npwp].npwp22 != '' || this.state.wpsMap[data.npwp].npwp22 != null) {
                npwp16 = this.state.wpsMap[data.npwp].npwp22.substring(0, 16);
                nitku = this.state.wpsMap[data.npwp].npwp22;
              }
            }
            npwp = data.npwp
            // paremeter
            var templateBpe = SptBpe.bpe(taxType, npwp, npwp16, nitku, name, address, masaTahun, pembetulan, status, amount, "002", timeStamp, ntpa, ntte);
            htmlBpes.push(templateBpe)
          })
          await this.setState((state, props)=> {
            return {
              bulkBPE: htmlBpes
            }
          })
          var doc = new jsPdf('p', 'mm');
          getNtte.map((d, index)=> {
            html2canvas(document.querySelector(`.bpe_p${index}`), {scale: 2}).then(canvas => {
              var imgData = canvas.toDataURL('image/png');
              var imgWidth = 190; 
              var pageHeight = 275;  
              var imgHeight = canvas.height * imgWidth / canvas.width;
              var heightLeft = imgHeight;
              var position = 0;
              position = heightLeft - imgHeight;
              if(index > 0){
                doc.addPage();
              }
              doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, '', 'FAST');
            })
          })
          setTimeout(()=> {
            doc.save( 'bpe' );
          }, 2000 * getNtte.length)
        }
    }

    async addItemBulk(){
      var path = this.props.router.location.pathname
      var pathnext = path.split("/")[1]
      this.navService.redirectTo(`/${pathnext}/spt-bulk/new`)
    }

    _barActions = [
        {
            label:'word.createBulk',
            iconClassName:'mdi mdi-plus-circle',
            onClick:() => this.addItemBulk()
        },
        {
            label:'custom.words.create',
            iconClassName:'mdi mdi-plus',
            onClick:() => this.addItem(),
        },
        {
            label:'custom.words.refresh',
            iconClassName:'mdi mdi-refresh',
            onClick:() => this.fetchData()
        },
        {
            label:'word.bulkPosting',
            iconClassName:'mdi mdi-upload',
            onClick:() => this.multiPosting()
        },
        {
            label:'word.bulkSubmit',
            iconClassName:'mdi mdi-send',
            onClick:() => this.multiLapor()
        },
        // {
        //     label:'word.bulkNtte',
        //     iconClassName:'mdi mdi-file',
        //     onClick:() => this.multiNtte()
        // },
        {
            label:'word.bulkBpe',
            iconClassName:'mdi mdi-file-pdf',
            onClick:() => this.bulkBPE()
        }
    ]

    async multiPosting(){
        var router = this.props.router.match.params
        var ids = []
        var warning = []
        var selected = this.props.table.selected
        selected.map((d)=> {
            var item = d
            // if(item.statusLapor != 'Sudah Lapor' && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')){
            if(item.fgStatusRekam === 0){
              ids.push(d)
            } else {
              warning.push(d.id)
            }
        })
        var res = await this.context.showDialog(()=> ({
            title: null,
            initialValue: {},
            width: 800,
            height: 150,
            contentStyle: {
              height: 150
            },
            text: (
              <div>
              <p> Apa anda yakin akan menposting data terpilih ?</p>
              </div>
            )
        }))
        if(res){
          try {
              var res = await Promise.all(ids.map(x => this.service.api.postingUlang(x)))
              izitoast.success({
                  title: counterpart('word.done'),
                  message: counterpart('word.donePostingSpt')
              })
          } catch (e){
              izitoast.error({
                  title: counterpart('word.fail'),
                  message: counterpart('word.failPostingSpt')
              })
          }
          this.removeCheckbox();
          this.fetchData();
        }
    }

    async multiNtte(){
      var router = this.props.router.match.params
      var ids = []
      var warning = []
      var selected = this.props.table.selected
      selected.map((d)=> {
          var item = d
          if(item.statusLapor == 'Sudah Lapor'){
            ids.push(d.id)
          } else {
            warning.push(d.id)
          }
      })
      var res = await this.context.showDialog(()=> ({
          title: null,
          initialValue: {},
          width: 800,
          height: 150,
          contentStyle: {
            height: 150
          },
          text: (
            <div>
            <p> Apa anda yakin akan men get ntte data terpilih ?</p>
            </div>
          )
      }))
      if(res){
        try {
            var res = await this.service.api.multiGetNtte(ids)
            izitoast.success({
                title: counterpart('word.done'),
                message: counterpart('custom.doneRequestNtteSpt')
            })
        } catch (e){
            izitoast.error({
                title: counterpart('word.fail'),
                message: counterpart('custom.failRequestNtteSpt')
            })
        }
        this.removeCheckbox();
        this.fetchData();
      }
  }

    async multiLapor(){
        var router = this.props.router.match.params
        var ids = []
        var warning = []
        var selected = this.props.table.selected
        selected.map((d)=> {
            var item = d
            if((item.statusUpload == 'Posting Sukses' || item.statusUpload == 'Gagal Submit Induk' || item.statusUpload == 'Gagal Lapor' || item.statusUpload == 'Gagal Upload Attachment') && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')){
              ids.push(d.id)
            } else {
              warning.push(d.id)
            }
        })
        var res = await this.context.showDialog(()=> ({
            title: null,
            initialValue: {},
            width: 800,
            height: 150,
            contentStyle: {
              height: 150
            },
            text: (
              <div>
              <p> Apa anda yakin akan melaporkan data terpilih ?</p>
              </div>
            )
        }))
        if(res){
          var askLapor = await this.context.showDialog((this_dialog, resolve, reject)=> ({
            title: 'Lampiran Pelaporan',
            initialValue: {
              selected: selected
            },
            width: 800,
            height: 600,
            okText: "Lapor dengan Lampiran",
            cancelText: "Lapor tanpa Lampiran",
            contentStyle: {
              height: 600
            },
            text: (
              <div>
                {selected.map((d, index)=>{
                  return (
                    <DataTable key={index} plain>
                      <TableBody>
                        <TableRow>
                          <TableColumn>
                            <div className="md-grid">
                              <Field
                                  label='Lampiran'
                                  name='lampiran'
                                  className="md-cell md-cell--6"
                                  component={FileInputNext}
                                  validate={validation.required}
                                  onChange={async (e, value)=> {
                                    try {
                                      var formData = new FormData()
                                      formData.append("file", e)
                                      let res = await this.service.api.uploadLampiran(formData, d.company.npwp, d.tahun, d.masa, d.pembetulan)
                                      izitoast.success({
                                        title: "Upload",
                                        message: "berhasil."
                                      })
                                    } catch(e){
                                      var message = ""
                                      if(e && e.message) message = e.message
                                      if(e && e.response && e.response.data && e.response.data.message) message = e.response.data.message
                                      if(e && e.response && e.response.data && e.response.data.detail) message = e.response.data.detail
                                      izitoast.success({
                                        title: "Upload",
                                        message: "gagal dengan pesan : " + message
                                      })
                                    }
                                  }}
                              />
                              <Field
                                  label='NPWP'
                                  name={`selected[${index}].company.npwp`}
                                  className="md-cell md-cell--4"
                                  component={Textfield}
                                  validate={validation.required}
                                  disabled={true}
                              />
                              <Field
                                  label='Nama Perusahaan'
                                  name={`selected[${index}].company.name`}
                                  className="md-cell md-cell--4"
                                  component={Textfield}
                                  validate={validation.required}
                                  disabled={true}
                              />
                              <Field
                                  label='Masa'
                                  name={`selected[${index}].masa`}
                                  className="md-cell md-cell--1"
                                  component={Textfield}
                                  validate={validation.required}
                                  disabled={true}
                              />
                              <Field
                                  label='Tahun'
                                  name={`selected[${index}].tahun`}
                                  className="md-cell md-cell--2"
                                  component={Textfield}
                                  validate={validation.required}
                                  disabled={true}
                              />
                              <Field
                                  label='Pembetulan'
                                  name={`selected[${index}].pembetulan`}
                                  className="md-cell md-cell--1"
                                  component={Textfield}
                                  validate={validation.required}
                                  disabled={true}
                              />
                            </div>
                          </TableColumn>
                        </TableRow>
                      </TableBody>
                    </DataTable>
                  )
                })}
              </div>
            )
          }))
          if(askLapor){
            try {
                var res = await this.service.api.multiLapor(ids, true)
                izitoast.success({
                    title: counterpart('word.done'),
                    message: counterpart('word.doneLaporSpt')
                })
            } catch (e){
                izitoast.error({
                    title: counterpart('word.fail'),
                    message: counterpart('word.failLaporSpt')
                })
            }
            this.removeCheckbox();
            this.fetchData();
          } else {
            try {
                var res = await this.service.api.multiLapor(ids, false)
                izitoast.success({
                    title: counterpart('word.done'),
                    message: counterpart('word.doneLaporSpt')
                })
            } catch (e){
                izitoast.error({
                    title: counterpart('word.fail'),
                    message: counterpart('word.failLaporSpt')
                })
            }
            this.removeCheckbox();
            this.fetchData();
          }
        }
    }

    async postingSpt(item){
      var res = await this.context.showDialog(()=> ({
          title: null,
          initialValue: {},
          width: 800,
          height: 150,
          contentStyle: {
            height: 150
          },
          text: (
            <div>
            <p> Apa anda yakin akan menposting data terpilih ?</p>
            </div>
          )
      }))
      if(res){
        try {
            var res = await this.service.api.postingUlang(item)
            izitoast.success({
                title: counterpart('word.done'),
                message: counterpart('word.donePostingSpt')
            })
        } catch (e){
            izitoast.error({
                title: counterpart('word.fail'),
                message: counterpart('word.failPostingSpt')
            })
        }
        this.fetchData();
      }
    }

    async openSPT(item){
        this.navService.redirectTo(`spt_open/spt/${item.npwp}/${item.tahun}/${item.masa1}/${item.revNo}/rev`)
        localStorage.setItem("spt_lapor_company", JSON.stringify(item.company))
        if(item.statusLapor == 'Sudah Lapor'){
            localStorage.setItem('disabledSave', 'true')
        } else {
            localStorage.setItem('disabledSave', 'false')
        }
    }

    async lapors(item){
      var res = await this.context.showDialog(()=> ({
          title: null,
          initialValue: {},
          width: 800,
          height: 150,
          contentStyle: {
            height: 150
          },
          text: (
            <div>
            <p> Apa anda yakin akan melaporkan data terpilih ?</p>
            </div>
          )
      }))
      if(res){
        await this.laporEfaktur(item)
      }
    }

    async laporEfaktur(item){
        var _this = this
        var induk = await Induk1111Service.api.getInduk1111(item.npwp, item.tahun, item.masa1, item.revNo)
        // STATUS SPT
        var nilaiPPN_status   = ""
        var nilaiPPNBM_status = ""
        if(induk && induk.data){
            var form = induk.data
            if(form.section2Ag){
                if(form.section2Ag.jmlIIDPPN == 0) nilaiPPN_status = "NIHIL"
                if(form.section2Ag.jmlIIDPPN > 0) nilaiPPN_status = "KURANG BAYAR"
                if(form.section2Ag.jmlIIDPPN < 0) nilaiPPN_status = "LEBIH BAYAR"
            }
            // if(form.section5){
            //     if(form.section5.jmlIIDPPN == 0) nilaiPPNBM_status = "NILAI PPNBM = NIHIL"
            //     if(form.section5.jmlIIDPPN > 0) nilaiPPNBM_status = "NILAI PPNBM = KURANG BAYAR"
            //     if(form.section5.jmlIIDPPN < 0) nilaiPPNBM_status = "NILAI PPNBM = LEBIH BAYAR"
            // }
        }
        // STATUS SPT
        accounting.settings.symbol = "Rp. "
        var res = await this.context.showDialog(()=> ({
          title: null,
          initialValue: {},
          width: 800,
          height: 600,
          okText: "Lapor",
          contentStyle: {
            height: 600
          },
          text: (
            <div>
              <List>
                <ListItem primaryText={<b>{counterpart('custom.askLaporEfaktur')}</b>}></ListItem>
                <ListItem primaryText="STATUS PPN" secondaryText={<b>{nilaiPPN_status}</b>}></ListItem>
                {/* <ListItem primaryText="NILAI PPNBM = " secondaryText={<b>{nilaiPPNBM_status}</b>}></ListItem> */}
                <ListItem primaryText="D. PPN kurang atau lebih Bayar" secondaryText={accounting.formatMoney(induk.data.section2Ag.jmlIIDPPN, "Rp. ",0)}></ListItem>
                <ListItem primaryText="E. PPN kurang atau lebih Bayar pada SPT yang dibetulkan" secondaryText={accounting.formatMoney(induk.data.section2Ag.jmlIIEPPN, "Rp. " ,0)}></ListItem>
                <ListItem primaryText="F. PPN kurang atau lebih Bayar karena pembetulan" secondaryText={accounting.formatMoney(induk.data.section2Ag.jmlIIFPPN, "Rp. " ,0)}></ListItem>
              </List>
            </div>
          )
        }))
        if(res){
          var askLapor = await this.context.showDialog((this_dialog, resolve, reject)=> ({
            title: 'Lampiran Pelaporan',
            initialValue: {},
            width: 800,
            height: 600,
            okText: "Lapor dengan Lampiran",
            cancelText: "Lapor tanpa Lampiran",
            contentStyle: {
              height: 600
            },
            text: (
              <div>
                <Field
                    label='Lampiran'
                    name='lampiran'
                    className="md-cell md-cell--6"
                    component={FileInputNext}
                    validate={validation.required}
                    onChange={async (e, value)=> {
                      try {
                        var formData = new FormData()
                        formData.append("file", e)
                        let res = await this.service.api.uploadLampiran(formData, item.npwp, item.tahun, item.masa1, item.revNo)
                        izitoast.success({
                          title: "Upload",
                          message: "berhasil."
                        })
                      } catch(e){
                        var message = ""
                        if(e && e.message) message = e.message
                        if(e && e.response && e.response.data && e.response.data.message) message = e.response.data.message
                        if(e && e.response && e.response.data && e.response.data.detail) message = e.response.data.detail
                        izitoast.success({
                          title: "Upload",
                          message: "gagal dengan pesan : " + message
                        })
                      }
                    }}
                />
              </div>
            )
          }))
          if(askLapor){
            try {
              var res = await this.service.api.laporEfaktur({}, item.npwp, item.tahun, item.masa1, item.revNo)
              izitoast.success({
                title: counterpart('custom.done'),
                message: counterpart('custom.doneLaporEfaktur')
              })
            } catch (e){
              console.log(e.response)
              izitoast.error({
                title: counterpart('custom.fail'),
                message: e.response?e.response.data.message:counterpart('custom.failLaporEfaktur')
              })
            }
            this.fetchData()
          } else {
            try {
              var res = await this.service.api.laporEfaktur({}, item.npwp, item.tahun, item.masa1, item.revNo)
              izitoast.success({
                title: counterpart('custom.done'),
                message: counterpart('custom.doneLaporEfaktur')
              })
            } catch (e){
              console.log(e.response)
              izitoast.error({
                title: counterpart('custom.fail'),
                message: e.response?e.response.data.message:counterpart('custom.failLaporEfaktur')
              })
            }
            this.fetchData()
          }
        }
    }

    async pembetulan(item){
        item.id = null
        item.revNo += 1
        item.masa = item.masa1
        item.company = {
          npwp: item.npwp,
        }
        try {
          await this.service.api.saveByParam(item)
        } catch (e) {
          
        }
        
        this.fetchData()
    }

    async previewBPE(item) {
      // console.log(item, 'item', this.state.wpsMap[item.npwp])
        try {
          var data = item
          // parameter
          var ntte = ''
          var ntpa = ''
          var timeStamp = ''
          var aspNo = ''
          var amount = ''
          var status = ''
          var pembetulan = ''
          var masaTahun = ''
          var taxType = ''
          var address = ''
          var name = ''
          var npwp = '' // 0 - 14
          var npwp16 = '-' //
          var nitku = '-' //
          if(data.idNtte) ntte = data.idNtte
          if(data.ntpa) ntpa = data.ntpa
          if(data.tglTerima){
            var a = data.tglTerima.split("-").join("/")
            timeStamp = a
          }
          if(data.aspNo) aspNo = ''
          if(data.nilaiLbkbn){
            var d = accounting.formatMoney(data.nilaiLbkbn, { symbol: "Rp.", precision: 2, thousand: ",", decimal: "." });
            amount = d
          }
          if(data.fgLbkbn){
            var x = data.fgLbkbn.split('')
            var fgLbkbnx = x[0]
            if(fgLbkbnx == "0" || fgLbkbnx == 0){
              status = "Nihil"
            } else if(fgLbkbnx == "1" || fgLbkbnx == 1){
              status = "Kurang Bayar"
            } else if(fgLbkbnx == "2" || fgLbkbnx == 2){
              status = "Lebih Bayar"
              amount = "( " + amount + " )"
            }
          }
          if(data.revNo){
            pembetulan = data.revNo + ""
          } else {
            pembetulan = "0"
          }
          if(data.masa1){
            if(data.masa1 == 1) masaTahun = "Januari"
            if(data.masa1 == 2) masaTahun = "Februari"
            if(data.masa1 == 3) masaTahun = "Maret"
            if(data.masa1 == 4) masaTahun = "April"
            if(data.masa1 == 5) masaTahun = "Mei"
            if(data.masa1 == 6) masaTahun = "Juni"
            if(data.masa1 == 7) masaTahun = "Juli"
            if(data.masa1 == 8) masaTahun = "Agustus"
            if(data.masa1 == 9) masaTahun = "September"
            if(data.masa1 == 10) masaTahun = "Oktober"
            if(data.masa1 == 11) masaTahun = "November"
            if(data.masa1 == 12) masaTahun = "Desember"
          }
          if(data.tahun) masaTahun += " / " + data.tahun
          taxType = "SPT PPN dan PPnBM 1111"
          if(this.state.wpsMap[item.npwp]) address = this.state.wpsMap[data.npwp].address
          if(this.state.wpsMap[item.npwp]) name = this.state.wpsMap[item.npwp].name
          if(this.state.wpsMap[item.npwp] && this.state.wpsMap[item.npwp].npwp22) {
            if (this.state.wpsMap[item.npwp].npwp22 != '' || this.state.wpsMap[item.npwp].npwp22 != null) {
              npwp16 = this.state.wpsMap[item.npwp].npwp22.substring(0, 16);
              nitku = this.state.wpsMap[item.npwp].npwp22;
            }
          }
          if(this.state.wpsMap[item.npwp]) npwp = item.npwp
          // paremeter
          var templateBpe = SptBpe.bpe(taxType, npwp, npwp16, nitku, name, address, masaTahun, pembetulan, status, amount, "002", timeStamp, ntpa, ntte);
          var htmlRender = await this.context.showDialog(()=> ({
            title: null,
            initialValue: {},
            okText: 'Close',
            cancelText: 'Print BPE',
            width: 800,
            height: 800,
            contentStyle: {
              height: 800
            },
            text: (
              <div>
                <Button style={{width: '100%'}} raised primary onClick={this.savePDF.bind(this)}>SAVE PDF</Button>
                <br />
                <br />
                <div dangerouslySetInnerHTML={ {__html: templateBpe} }></div>
              </div>
            )
          })) 
          if(htmlRender){
          } else {
            var mywindow = window.open('', 'PRINT', 'height=550,width=580');
            mywindow.document.write(templateBpe);
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            mywindow.close();
          }
        } catch (e){
          console.log(e)
        }
    }

    savePDF(){
        html2canvas(document.querySelector(".bpe-container"), {scale: 2}).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 190; 
          var pageHeight = 275;  
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPdf('p', 'mm');
          var position = 0;
          doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, '', 'FAST');
            heightLeft -= pageHeight;
          }
          doc.save( '.bpe-container' );
        })
    }

    _tableActions = [
        {
            label: counterpart("word.openSpt"),
            iconClassName:'mdi mdi-send',
            onClick:(item) => this.openSPT(item),
            // ifCon: "item.statusUpload != 'Sukses dibuat' && item.statusUpload != 'Posting Gagal' && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')"
        },
        {
            label: counterpart('word.postingSPT'),
            iconClassName:"mdi mdi-upload",
            onClick: (item) => this.postingSpt(item),
            ifCon: "item.fgStatusRekam == 0"
            // ifCon: "item.statusLapor != 'Sudah Lapor' && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')"
        },
        {
            label: counterpart("word.laporSPT"),
            iconClassName:'mdi mdi-send',
            onClick:(item) => this.lapors(item),
            ifCon: "item.fgStatusRekam == 0"
            // ifCon: "(item.statusUpload == 'Posting Sukses' || item.statusUpload == 'Gagal Submit Lampiran AB' || item.statusUpload == 'Gagal Submit Induk' || item.statusUpload == 'Gagal Lapor' || item.statusUpload == 'Gagal Upload Attachment') && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')"
        },
        {
            label: counterpart("word.pembetulan"),
            iconClassName:'mdi mdi-file-import',
            onClick:(item) => this.pembetulan(item),
            ifCon: "item.fgStatusRekam == 1"
            // ifCon: "item.statusLapor == 'Sudah Lapor' && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')"
        },
        {
            label:"Preview BPE",
            iconClassName:"mdi mdi-eye",
            onClick: (item) => this.previewBPE(item),
            ifCon: "item.fgStatusRekam == 1"
            // ifCon: "item.statusLapor == 'Sudah Lapor' && (item.statusUpload != 'Gagal Menarik NTTE' || item.statusUpload == 'Berhasil Menarik NTTE')"
        },
        // {
        //     label:"Detail Data",
        //     iconClassName:"mdi mdi-bell",
        //     onClick: (item) => this.editItem(item),
        //     ifCon: "item.statusUpload == 'Sukses dibuat' || item.statusUpload == 'Gagal Posting'"
        // },
        {label:"divider", iconClassName:"-"},
        {
            label:"Hapus",
            iconClassName:"mdi mdi-delete",
            onClick:(item, callback) => {
                this.deleteItem(item, callback)
            },
            ifCon: "item.fgStatusRekam == 0",
            // ifCon: "item.statusLapor != 'Sudah Lapor' && (item.statusUpload != 'Sedang dibuat' && item.statusUpload != 'Sedang Posting' && item.statusUpload != 'Sedang Submit Lampiran AB' && item.statusUpload != 'Sedang Submit Induk' && item.statusUpload != 'Sedang Upload Attachment' && item.statusUpload != 'Sedang Lapor')",
            confirmation:{
                title:"sentence.custom.deleteItem",
                message:"sentence.custom.deleteItemConfirmation"
            }
        }
    ]

    columns=[
        {label: "word.companyNpwp", searchField: "companyNpwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.companyName", searchField: "companyName", value: (d)=> {return this.state.wpsMap[d.npwp].name}, isDefaultSort:false, isSortable:true, show:true, isSearchable:true, type: 'func'},
        // {label: "word.hasAttachment", searchField: "hasAttachment", value: "hasAttachment", type: 'boolean', isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.masaPajak2", searchField: "masa1", value: "masa1", type:'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.tahun", searchField: "tahun", value: (d)=> { return d.tahun }, type:'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.pembetulan", searchField: "revNo", value: "revNo", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.fgLbkbn", searchField: "fgLbkbn", value: (d)=>{
          if(d.fgLbkbn == 0 || !d.fgLbkbn){
            return "Nihil"
          } else if(d.fgLbkbn == 1){
            return "Kurang Bayar"
          } else if(d.fgLbkbn == 2){
            return "Lebih Bayar"
          } else {
            return "-"
          }
        }, type: "func", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.nilaiLbkbn", searchField: "nilaiLbkbn", value: (d)=> {
          if(d.fgLbkbn == 2) {
            return `(${utilservice.number.format2(d.nilaiLbkbn)})`
          } else {
            
            return utilservice.number.format2(d.nilaiLbkbn)
          }
        }, type: 'func', isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        // {label: "word.nilaiLbkbn2", searchField: "jmlIIFPPN", value: "jmlIIFPPN", type: 'number', isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
       
        {label: "word.statusLapor", searchField: "fgStatusRekam",  value: (d)=>{
          if(d.fgStatusRekam == 0){
            return "Belum Lapor"
          } else if(d.fgStatusRekam == 1){
            return "Sudah Lapor"
          }
        }, type: "func", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},

        {label: "word.idNtte", searchField: "idNtte", value: "idNtte", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},

        // {label: "word.statusUpload", searchField: "fgStatusTransfer", value: (d)=>{
        //   if(d.fgStatusTransfer == 0){
        //     return "Menunggu"
        //   } else if(d.fgStatusTransfer == 1){
        //     return "Berhasil"
        //   } else if(d.fgStatusTransfer == -1){
        //     return "Gagal"
        //   }
        // }, type: "func", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.statusUploadMsg", searchField: "keterangan", value: "keterangan", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        // {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        // {label: "word.createdDate", searchField: "createdDate", valueName:"createdDate", value: (d)=> {
        //     return moment(d.createdDate).format('DD-MM-YYYY HH:mm:ss')
        // }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
        // {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        // {label: "word.lastModifiedDate", searchField: "lastModifiedDate", valueName:"lastModifiedDate", value: (d)=> {
        //     return moment(d.lastModifiedDate).format('DD-MM-YYYY HH:mm:ss')
        // }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    ]

    customSearch=true
    searchColumn(){
        var columns = [
            // { searchLabel: 'Company Npwp', searchField: 'companyNpwp', type: 'string', searchAction: 'contains' },
            // { searchLabel: 'Company Name', searchField: 'companyName', type: 'string', searchAction: 'contains' },
            // { searchLabel: 'Dari Masa', searchField: 'masa', type: 'string', searchAction: 'greaterThanOrEqual' },
            // { searchLabel: 'Ke Masa', searchField: 'masa', type: 'string', searchAction: 'lessThanOrEqual' },
            // { searchLabel: 'Dari Tahun', searchField: 'tahun', type: 'string', searchAction: 'greaterThanOrEqual' },
            // { searchLabel: 'Ke Tahun', searchField: 'tahun', type: 'string', searchAction: 'lessThanOrEqual' },
            // { searchLabel: 'Pembetulan', searchField: 'pembetulan', type: 'string', searchAction: 'equals' }
            { searchLabel: 'Tahun', searchField: 'tahun', type: 'number', searchAction: 'equals' }
        ]
        return columns
    }

    defaultCustomSearchForm(){
      var columns = this.searchColumn()
      return (
        <div className='md-grid' style={{margin: 0, padding:0, width: '100%'}}>
            {columns.map((d)=> {
              return (
                <Field
                  label = {d.searchLabel}
                  name  = {`${d.searchField}__${d.searchAction}`}
                  className = "md-cell md-cell--12"
                  component = {TextfieldMask}
                  type = 'number'
                />
              )
            })}
        </div>
      )
    }

};

export default SptView;