import ApiService from '../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../services/utilsService';

class SptService extends ApiService {
  customSearch=true
  name='Spt'
  path='spt-1111'
  baseUrl='spt'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    if(apiMethod == 'delete' || apiMethod == "findOne" || apiMethod == 'update'){
      return `${this.path}/${params.id}`;
    } else {
      return this.path;
    }
  }

  api = {
    saveByParam: async (data) => {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.company.npwp}/spt1111`);
      if (data.revNo) {
        options.params = {
          masa: data.masa,
          tahun: data.tahun,
          revNo: data.revNo?data.revNo.toString():'0',
          isPostingUlang: 'false'
        }
      } else {
        options.params = {
          masa: data.masa,
          tahun: data.tahun,
          revNo: data.pembetulan?data.pembetulan.toString():'0',
          isPostingUlang: 'false'
        }
      }
      options.data = data;
      var res = await httpService.post(options)
      return res
    },

    postingUlang: async (data) => {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.npwp}/spt1111`);
      options.params = {
        masa: data.masa1,
        tahun: data.tahun,
        revNo: data.revNo?data.revNo.toString():'0',
        isPostingUlang: 'true'
      }
      options.data = data;
      var res = await httpService.post(options)
      return res
    },

    delete: async (data) => {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.npwp}/spt1111/hapus`);
      options.params = {
        masa: data.masa1,
        tahun: data.tahun,
        revNo: data.revNo?data.revNo.toString():'0',
        isPostingUlang: 'true'
      }
      options.data = data;
      var res = await httpService.post(options)
      return res
    },

    getSptByNpwpAndTahun: async(param, dispatch, opt) => {
      var tahun = param.tahun
      if (param && param['tahun.equals']) {
        tahun = param['tahun.equals']
      }
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${param.npwp}/spt1111-list?tahun=${tahun}`);
      options.data = param;
      // if(param.tahun && param.npwp) {\

      if(param.npwp && param.tahun) {
        var res = await httpService.get(options)
        return res
      } else {
        return {
          data: {
            data: []
          }
        }
      }
        
    },
    uploadLampiran: async(data, npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/upload-attachment`);
      options.params = {
        masa: masa,
        tahun: tahun,
        revNo: pembetulan?pembetulan:'0'
      }
      options.data = data;
      var res = await httpService.post(options)
      return res
    },
    multiPost: async (ids)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-posting-batch`);
      options.data = {
        type: 'CommonSearchUUID',
        ids: ids
      }
      var res = await httpService.post(options)
      return res
    },
    multiLapor: async (ids, lampiran)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-lapor-batch`);
      options.data = {
        type: 'CommonSearchUUID',
        ids: ids
      }
      options.params = {
        attachment: lampiran
      }
      var res = await httpService.post(options)
      return res
    },
    multiGetNtte: async (ids)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-ntte-batch`);
      options.data = {
        type: 'CommonSearchUUID',
        ids: ids
      }
      var res = await httpService.post(options)
      return res
    },
    saveCustom: async (data)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-create-batch/${data.tahun}/${data.masa}/${data.pembetulan}`);
      options.data = data
      var res = await httpService.post(options)
      return res
    },
    getNpwpBulk: async (tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-wp-info/${tahun}/${masa}/${pembetulan}`);
      var res = await httpService.get(options)
      return res
    },
    postSPT: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/${this.replacePath()}/${sptId}/post`);
      var res = await httpService.get(options)
      return res
    },
    laporEfaktur: async(data, npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/lapor`);
      options.params = {
        masa: masa,
        tahun: tahun,
        revNo: pembetulan?pembetulan:'0'
      }
      var res = await httpService.post(options)
      return res
    },
    getNpwp: async()=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-npwp-by-login`);
      var res = await httpService.get(options)
      return res
    },
    getNpwpInfo: async(npwp)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111-wp-info-by-npwp/${npwp}`);
      var res = await httpService.get(options)
      return res
    },
    getAllNpwp: async()=> {
      let options = utilsService.generateGatewayOptions(`/sertel/wp/get-wp-with-info`);
      var res = await httpService.get(options)
      return res
    }
  }
  
}

export default new SptService();
