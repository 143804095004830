import React from 'react';
import FormView from '../../../components/Base/FormView';
import SptService from './Spt.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { Card, ExpansionList, ExpansionPanel, TextField, Button, DataTable, TableHeader, TableColumn, TableRow, TableBody, Divider,
    FontIcon,
    List,
    ListItem,
    Subheader, } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection, Switch } from '../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';

@reduxForm({form: 'SptForm', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('SptForm')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class SptListView extends FormView {
    service=SptService;
    viewType=2;

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    async initEdit(){
 
        this.props.initialize({});
    }

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        return (
        <div>
            <Button secondary flat onClick={()=> { 
                var path = this.props.router.location.pathname
                var pathnext = path.split("/")[1]
                this.navService.redirectTo(`/${pathnext}/spt`)
             }} style={{marginRight: 16}}>Kembali</Button>
            {/* <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Simpan</Button> */}
        </div>
        )
    }

    formView() {
        return (
            <div>
                <Card>
                    <div className="md-grid">
                        <div className="md-cell md-cell--4 md-paper md-paper--1">
                            <List>
                                <Subheader primaryText="Lampiran" />
                                <Divider />
                                <ListItem onClick={()=> this.navService.redirectTo('a1')} primaryText={"A1"} secondaryText="Daftar Lampiran A1" rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('a2')} primaryText={"A2"} secondaryText="Daftar Lampiran A2" rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('b1')} primaryText={"B1"} secondaryText="Daftar Lampiran B1" rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('b2')} primaryText={"B2"} secondaryText="Daftar Lampiran B2" rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('b3')} primaryText={"B3"} secondaryText="Daftar Lampiran B3" rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                            </List>
                        </div>
                        <div className="md-cell md-cell--4 md-paper md-paper--1">
                            <List>
                                <Subheader primaryText="Form" />
                                <Divider />
                                <ListItem onClick={()=> this.navService.redirectTo('ab')} primaryText={"AB"}    secondaryText="Formulir AB"    rightIcon={<FontIcon>chevron_right</FontIcon>} ></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('induk')} primaryText={"Induk"} secondaryText="Formulir Induk" rightIcon={<FontIcon>chevron_right</FontIcon>} ></ListItem>
                            </List>
                        </div>
                        <div className="md-cell md-cell--4 md-paper md-paper--1">
                            <List>
                                <Subheader primaryText="PPN DDM & SSP" />
                                <Divider />
                                <ListItem onClick={()=> this.navService.redirectTo('uangdimuka')} primaryText={"PPN DDM"}  secondaryText="Formulir PPN DDM"  rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('spt-1111-ssp-iis')} primaryText={"SSPII"}  secondaryText="Formulir SSP II"  rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('spt-1111-ssp-iiis')} primaryText={"SSPIII"} secondaryText="Formulir SSP III" rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('spt-1111-ssp-ivs')} primaryText={"SSPIV"}  secondaryText="Formulir SSP IV"  rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                                <ListItem onClick={()=> this.navService.redirectTo('spt-1111-ssp-vs')} primaryText={"SSPV"}   secondaryText="Formulir SSP V"   rightIcon={<FontIcon>chevron_right</FontIcon>}></ListItem>
                            </List>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

};

export default SptListView;