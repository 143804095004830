import React from 'react'
import { TabsContainer, Tabs, Tab } from 'react-md';
import SptView from './Spt.view';
import WpView from './../Wp/Wp.view'

class SptWpView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            currentIndex: 0
        }
    }

    componentDidMount(){
        try {
            var c = parseInt(localStorage.getItem('defaultPage'))
            this.setState((state)=> {
                state.currentIndex = c
                return state
            })
        } catch(e){
            console.log(e)
        }
    }

    render(){
        return (
            <div className="mpk-layout column fill" id="onlyhere">
                <div className="flex all mpk-content mpk-tab">
                    <TabsContainer colored style={{width: '100%', height: '100%'}} activeTabIndex={this.state.currentIndex}>
                        <Tabs tabId="simple-tab" style={{ padding: '0px !important;' }}>
                            <Tab label="SPT" onClick={()=> {
                                localStorage.setItem("defaultPage", "0")
                                this.setState({ currentIndex: 0 })
                            }}>
                                <SptView router={this.props.router} />
                            </Tab>
                            <Tab label="Wajib Pajak" onClick={()=> {
                                localStorage.setItem("defaultPage", "1")
                                this.setState({ currentIndex: 1 })
                            }}>
                                <WpView router={this.props.router} />
                            </Tab>
                        </Tabs>
                    </TabsContainer>
                </div>
            </div>
        )
    }
}

export default SptWpView;