import React from "react";
import {
  TabsContainer,
  Tabs,
  Tab,
  DialogContainer,
  Card,
  Button,
  Divider,
} from "react-md";
import ListView from "../../../../components/Base/ListView";
import SSPIVService from "./SSPIV.service";
import { connect } from "react-redux";
import moment from "moment";
import { SearchField } from "../../../../libs/react-mpk";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import {
  validation,
  Searchfield,
  Textfield,
  TextfieldMask,
} from "../../../../components/Form";
import accounting from "accounting-js";
import izitoast from "izitoast";
import SSPIIService from "../SSPII/SSPII.service";

@reduxForm({ form: "SPT1111_SSPIV", destroyOnUnmount: true })
@connect(
  (state) => ({
    ...SSPIVService.stateConnectSetting()(state),
  }),
  SSPIVService.actionConnectSetting()
)
class SSPIVView extends ListView {
  service = SSPIIService;
  plainTable() {
    return false;
  }

  titleHeader() {
    return `entities.SSPIV.title`;
  }

  fetchOption() {
    var params = this.props.router.match.params;
    // console.log(params, "params");
    let path = `tarra-efaktur/${params.npwp}/spt1111/ssp`;
    return {
      path: path,
    };
  }

  headerData = {
    jmlRecord: 0,
  };

  overideTotal(res) {
    return parseInt(this.headerData.jmlRecord);
  }

  overideData(res) {
    var totaSsp = res.data.data.reduce((pv, cv) => {
      pv = pv + cv.jumlahPembayaran;
      return pv;
    }, 0);
    this.props.change("ssp", totaSsp);
    this.props.change("debt", this.props.pengurang);
    var sspText = "";
    var sisa = parseFloat(totaSsp) - parseFloat(this.props.pengurang);

    // console.log(sisa, totaSsp, "sisa");
    var sisaText = accounting.formatNumber(sisa, 2, ".", ",");
    if (sisa == 0) {
      sspText = "Nihil";
    } else if (sisa > 0) {
      sspText = "Lebih Bayar";
    } else if (sisa < 0) {
      sspText = "Kurang Bayar";
    }
    var debtStatus = `${sspText} (${sisaText})`;
    this.props.change("debtStatus", debtStatus);
    return res.data ? res.data.data : [];
  }

  async beforeFetch(params) {
    var p = this.props.router.match.params;
    params.masa = p.masa;
    params.tahun = p.tahun;
    params.revNo = p.pembetulan;
    params.pageNum = params.page ? params.page + 1 : 1;
    params.pageSize = params.size ? params.size : 15;
    params.idBayar = "2";

    // var header = await this.service.api.header(p.npwp, params)
    // this.headerData = header.data?header.data.data:{jmlRecord:0}

    // this.props.change('dpp', header.data.data.jmlDpp)
  }

  footer() {
    return (
      <div style={{ width: "100%" }}>
        <Divider />
        <div className="md-grid" style={{ padding: "0 4px", width: "100%" }}>
          <Field
            label="Total SSP"
            money={true}
            normalize={(e) => parseInt(e)}
            component={TextfieldMask}
            name="ssp"
            className="md-cell md-cell--12"
          />
          <Field
            label="Terutang"
            money={true}
            normalize={(e) => parseInt(e)}
            component={TextfieldMask}
            name="debt"
            className="md-cell md-cell--6"
          />
          <Field
            label="Status"
            money={true}
            component={Textfield}
            name="debtStatus"
            className="md-cell md-cell--6"
          />
        </div>
      </div>
    );
  }

  static contextTypes = {
    showDialog: PropTypes.func,
  };

  async uploadSSP() {
    var ids = [];
    var selected = this.props.table.selected;
    selected.map((d) => {
      ids.push(d.id);
    });
    var res = await this.context.showDialog(() => ({
      title: null,
      initialValue: {},
      width: 800,
      height: 150,
      contentStyle: {
        height: 150,
      },
      text: (
        <div>
          <p> Apa anda yakin akan mengupload data terpilih ?</p>
        </div>
      ),
    }));
    if (res) {
      try {
        let res = await this.service.api.uploadSSP(ids);
        this.removeCheckbox();
        this.fetchData();
        izitoast.success({
          title: "Success",
          message: "Upload SSP berhasil diupload.",
        });
      } catch (e) {
        izitoast.error({
          title: "Error",
          message: "Upload SSP gagal dengan pesan : " + e.message,
        });
      }
    }
  }

  _barActions = [];

  _tableActions = [];

  columns = [
    {
      label: "word.select",
      searchField: "select",
      value: (d) => {
        return (
          <Button
            raised
            primary
            onClick={() => {
              this.props.onSelectSSP(d);
            }}
          >
            Pilih
          </Button>
        );
      },
      type: "func",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.kodeAkunPajak",
      searchField: "kodeAkunPajak",
      value: "kodeAkunPajak",
      isDefaultSort: false,
      isSortable: true,
      show: true,
      isSearchable: true,
    },
    {
      label: "word.kodeJenisSetoran",
      searchField: "kodeJenisSetoran",
      value: "kodeJenisSetoran",
      isDefaultSort: false,
      isSortable: true,
      show: true,
      isSearchable: true,
    },
    {
      label: "word.nilai",
      searchField: "jumlahPembayaran",
      value: "jumlahPembayaran",
      type: "number",
      isDefaultSort: false,
      isSortable: true,
      show: true,
      isSearchable: true,
    },
    {
      label: "word.ntpn",
      searchField: "nomorPembayaran",
      value: "nomorPembayaran",
      isDefaultSort: false,
      isSortable: true,
      show: true,
      isSearchable: true,
    },
    {
      label: "word.pbk",
      searchField: "fgNtpnPbk",
      value: "fgNtpnPbk",
      type: "boolean",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    // {label: "word.approve", searchField: "approve", value: "status.approve", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
    // {label: "word.approveMsg", searchField: "approveMsg", value: "status.approveMsg", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
    // {label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", type: "number", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
    {
      label: "word.tahun",
      searchField: "tahunPajak",
      value: (d) => {
        return d.tahunPajak;
      },
      type: "func",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.masa",
      searchField: "masaPajak",
      value: "masaPajak",
      type: "number",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.tglSsp",
      searchField: "tanggalPembayaran",
      value: "tanggalPembayaran",
      isDefaultSort: false,
      isSortable: true,
      show: true,
      isSearchable: true,
    },
    // {label: "word.createdDate", searchField: "createdDate", valueName:"createdDate", value: (val)=> {
    //   return moment(val.createdDate).format("DD-MM-YYYY HH:mm:ss")
    // }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    // {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    // {label: "word.updateAt", searchField: "lastModifiedDate", value: (val)=> {
    //   return moment(val.createdDate).format("DD-MM-YYYY HH:mm:ss")
    // }, type: 'func', isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    // {label: "word.updateBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ];

  customSearch = true;
  searchColumn() {
    var columns = [
      {
        searchLabel: "Kode Akun Pajak",
        searchField: "kodeAkunPajak",
        type: "string",
        searchAction: "contains",
      },
      {
        searchLabel: "Kode Jenis Setoran",
        searchField: "kodeJenisSetoran",
        type: "string",
        searchAction: "contains",
      },
      {
        searchLabel: "Nilai",
        searchField: "nilai",
        type: "string",
        searchAction: "equals",
      },
      {
        searchLabel: "Tanggal SSP (MM-DD-YYYY)",
        searchField: "tglSsp",
        type: "string",
        searchAction: "equals",
      },
    ];
    return columns;
  }
}

export default SSPIVView;
