import React from 'react';
import { DialogForm, Hint, httpService, rdxConnect } from 'libs/react-mpk';
import t from 'counterpart';
import { TextField, Switch } from 'react-md';
import * as service from '../service'
import { utilsService } from 'services';

const pinLength = 6;
const defaultState = () => ({
  pin: '',
  visible: false,
  isSetup: true,
  pinAsPassword: '',
  initialPin: '',
  showAsPassword: true,
  isStepConfirm: false
})

class PIN extends React.Component{
  constructor(){
    super();
    this.state = defaultState();
  }

  componentDidMount(){
    window.addEventListener('pin', this.pinHandler)
  }

  componentWillUnmount(){
    window.removeEventListener('pin', () => {}, null);
  }

  pinHandler = e => {
    let { eventType } = e.detail;
    switch(eventType){
      case 'setup':
        this.setState({
          visible: true,
          isSetup: true
        });
        break;
      case 'required':
        utilsService.setReqOptionsPin(null)
        this.setState({
          visible:true,
          isSetup: false
        })
        break;
      case 'close':
        this.setState(defaultState());
        break;
      default:
        return;
    }
  }

  formHandleChange = (index, value) => {
    let { pin, pinAsPassword } = this.state;
    value = value.replace(/\D/g,'');
    pin = String(`${pin.substr(0, index)}${value}`).substr(0, pinLength);
    pinAsPassword = String(`${pinAsPassword.substr(0, index)}${ value ? '*' : ''}`).substr(0, pinLength);

    this.setState({pin, pinAsPassword}, () => {
      let next = this[`pin${pin.length}`];
      let prev = this[`pin${pin.length-1}`];
      if(value && next) next.focus();
      if(!value && prev) prev.focus()
    });
  }

  onSubmit = async (callback) => {
    let { pin, initialPin, isSetup } = this.state;
    if(pin.length === pinLength){
      if(isSetup){
        if(initialPin === ''){
          this.setState({
            initialPin: pin, 
            isStepConfirm: true,
            pinAsPassword: ''
          }, () => {
            this.pin0.focus();
            callback(false, null, false);
          })
        } else {
          if(initialPin === pin){
            try {
              await service.postPin({pin});
              this.setState(defaultState());
              callback(false, t.translate('sentence.pin.completeSetup'))
            } catch (error) {
              callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode))
            }
          }else{
            this.setState({
              isStepConfirm: false,
              pin: '',
              pinAsPassword: '',
              initialPin: ''
            }, () => {
              this.pin0.focus();
              callback(true, t.translate('sentence.pin.setupNotMatch'));
            })
          }
        }
      } else {
        window.dispatchEvent(new CustomEvent('on-pin-data', {detail: {eventType: 'submit', pin}}))
        this.setState(defaultState());
        callback();
      }
    } else {
      callback(true, t.translate('sentence.pin.lengthNotMatch', false))
    }
  }

  render(){
    let { visible, isSetup } = this.state;
    return(
      <DialogForm
        baseName="ub-pin-input"
        title={t.translate(isSetup ? 'sentence.pin.setupTitle' : 'sentence.pin.title')}
        visible={visible}
        onCancel={() => {
          this.setState({visible: false}, () => {
            window.dispatchEvent(new CustomEvent('on-pin-data', {detail: {eventType: 'cancel'}}))
          })
        }}
        focusOnMount={false}
        onSubmit={this.onSubmit}
        showCancel={!isSetup}
        submitLabel={isSetup ? t.translate(this.state.isStepConfirm ? 'word.save' : 'word.next') : t.translate('word.next')}
      >
        <Hint>
          {t.translate(isSetup ? (
            this.state.isStepConfirm ? 'sentence.pin.confirmationHintText' : 'sentence.pin.setupHintText'
          ) : 'sentence.pin.hintText')}
        </Hint>
        <div className="mpk-layout align-center mpk-margin-N margin-top">
          {Array.from(Array(pinLength)).map((__, i) => (
            <TextField 
              onChange={ value => this.formHandleChange(i, value)}
              className="mpk-margin-S margin-left margin-right"
              value={this.state[this.state.showAsPassword ? 'pinAsPassword' : 'pin'].charAt(i)}
              inputStyle={{textAlign: 'center'}}
              ref={ref => this[`pin${i}`] = ref}
            />
          ))}
        </div>
        { isSetup && (
          <Switch
            id="toggle-show-password"
            name="toggle show password"
            label={t.translate('sentence.pin.showPin')}
            checked={this.state.showAsPassword}
            onChange={checked => {
              this.setState({showAsPassword: checked})
            }}
          />
        )}
      </DialogForm>
    )
  }
}

PIN.defaultProps = {
  isSetup: true
}

PIN.required = () => {
  return new Promise(resolve => {
    window.dispatchEvent(new CustomEvent('pin', {detail: {eventType: 'required'}}))
    window.addEventListener('on-pin-filled', e => {
      window.removeEventListener('on-pin-filled', () => {}, false);
      utilsService.setReqOptionsPin(e.detail.pin);
      resolve()
    })
  })
}

export default rdxConnect(PIN);