import ApiService from '../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../services/utilsService';

class HistoryService extends ApiService {
  customSearch=true
  name='Wp'
  path='sertel/history/get-history-with-info'
  baseUrl='history'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    if(apiMethod == 'delete' || apiMethod == "findOne" || apiMethod == 'update'){
      return `${this.path}/${params.id}`;
    } else {
      return this.path;
    }
  }

  api = {
    getAll: async(params={}) => {
      delete params.sortBy;
      delete params.sort;
      delete params.total;
      var res = await httpService.get({url: '/api/iams/auditrail', params: params})
      return res
    },
  }
  
}

export default new HistoryService();
