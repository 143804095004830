import React from "react";
import {
  TabsContainer,
  Tabs,
  Tab,
  DialogContainer,
  List,
  ListItem,
  Button,
  Card,
} from "react-md";
import ListView from "../../../components/Base/ListView";
import WpService from "./Wp.service";
import { connect } from "react-redux";
import moment from "moment";
import { SearchField } from "../../../libs/react-mpk";
import PropTypes from "prop-types";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
  validation,
  Searchfield,
  Textfield,
  FileInputNext,
} from "../../../components/Form";
import counterpart from "counterpart";
import izitoast from "izitoast";
import accounting from "accounting-js";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";

@reduxForm({ form: "WpForm", destroyOnUnmount: true })
@connect(
  (state) => ({
    // EPPT CORE
    ...WpService.stateConnectSetting()(state),
    formData: getFormValues("WpForm")(state),
    auth: state.auth.user,
    // EPPT CORE
  }),
  WpService.actionConnectSetting()
)
class WpView extends ListView {
  service = WpService;
  apiPath = "getAll";
  tabs = ["column"];

  plainTable() {
    return false;
  }

  header() {
    return (
      <div>
        <TabsContainer colored activeTabIndex={1}>
          <Tabs tabId="simple-tab" style={{ padding: "0px !important;" }}>
            <Tab
              label="SPT"
              onClick={() => {
                var path = this.props.router.location.pathname;
                var pathnext = path.split("/")[1];
                this.navService.redirectTo(`/${pathnext}/spt`);
              }}
            ></Tab>
            <Tab
              label="Wajib Pajak"
              onClick={() => {
                var path = this.props.router.location.pathname;
                var pathnext = path.split("/")[1];
                this.navService.redirectTo(`/${pathnext}/wp`);
              }}
            ></Tab>
            {/* <Tab
              label="Riwayat"
              onClick={() => {
                var path = this.props.router.location.pathname;
                var pathnext = path.split("/")[1];
                this.navService.redirectTo(`/${pathnext}/history`);
              }}
            ></Tab> */}
          </Tabs>
        </TabsContainer>
        <Card>
          <div className="md-grid">
            <Field
              label="NPWP"
              name="npwp"
              className="md-cell md-cell--10"
              component={Textfield}
              validate={validation.required}
            />
            <div className="md-cell md-cell--1">
              <Button
                secondary
                flat
                style={{ width: "100%", marginTop: 20 }}
                onClick={this.handleSearchClear.bind(this)}
              >
                Hapus
              </Button>
            </div>
            <div className="md-cell md-cell--1">
              <Button
                primary
                raised
                style={{ width: "100%", marginTop: 20 }}
                onClick={this.props.handleSubmit(this.handleSearch.bind(this))}
              >
                Cari
              </Button>
            </div>
          </div>
        </Card>
        <br />
      </div>
    );
  }

  async handleSearchClear() {
    this.props.initialize({});
    this.fetchData();
  }

  async handleSearch() {
    var cariData = this.state.data.filter((d) => {
      return d.npwp.indexOf(this.props.formData.npwp) != -1;
    });
    this.props.tableActions.setProperties({
      isLoading: false,
      data: cariData,
      params: {
        total: cariData.length,
      },
    });
    this.setState({ data: cariData });
  }

  static contextTypes = {
    showDialog: PropTypes.func,
  };

  _barActions = [
    {
      label: "custom.words.refresh",
      iconClassName: "mdi mdi-refresh",
      onClick: () => this.fetchData(),
    },
  ];

  overideTotal(res) {
    return res.data.length;
  }

  _tableActions = [
    {
      label: "Detail Data",
      iconClassName: "mdi mdi-bell",
      onClick: (item) => this.editItems(item),
    },
    {
      label: "Upload Sertifikat",
      iconClassName: "mdi mdi-tag",
      onClick: (item) => this.uploadSertifikat(item),
      ifCon: "item.registered == true",
    },
    // {
    //     label:"Setting Efaktur Key",
    //     iconClassName:"mdi mdi-key",
    //     onClick: (item) => this.efakturKey(item)
    // }
  ];

  editItems(item) {
    this.navService.redirectTo(`wp/${item.npwp}`);
  }

  async uploadSertifikat(item) {
    var _this = this;
    var form = await this.context.showDialog((props, resolve, reject) => ({
      title: null,
      width: 800,
      height: 600,
      okText: "Upload",
      cancelText: "Tutup",
      contentStyle: {
        height: 600,
      },
      text: (
        <div className="md-grid">
          <Field
            label="Sertifkat File (.p12)"
            name="signers[0].name"
            className="md-cell md-cell--12"
            component={FileInputNext}
            accept=".p12"
            validate={validation.required}
            onChange={async (e, value) => {
              try {
                var npwp = value.name.split(".p12")[0];
                props.props.change("npwp", npwp);
                try {
                  var formData = new FormData();
                  formData.append("file", value);
                  formData.append("npwp", npwp);
                  var uploadcert = await _this.service.api.uploadSertel(
                    formData,
                    npwp
                  );
                  izitoast.success({
                    title: "Success",
                    message: "Sertifikat berhasil di upload",
                  });
                } catch (e) {
                  // console.log(e, "TELL ME");
                  izitoast.error({
                    title: "Failed",
                    message: "Sertifikat gagal di upload",
                  });
                }
              } catch (e) {
                izitoast.error({
                  title: "Failed",
                  message:
                    "Format Nama Sertifkat salah seharusnya ( npwp.p12 )",
                });
              }
            }}
          />
          <Field
            label="Npwp"
            name="npwp"
            className="md-cell md-cell--12"
            component={Textfield}
            disabled={true}
            validate={validation.required}
          />
          <Field
            label="Passphrase"
            name="passphrase"
            type="password"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
        </div>
      ),
    }));
    if (form) {
      try {
        if (item.npwp == form.npwp) {
          let res = await this.service.api.updateSertel(
            {
              npwp: form.npwp,
              passphrase: form.passphrase,
            },
            form.npwp
          );
          izitoast.success({
            title: "Success",
            message: "Sertifikat berhasil di update",
          });
          this.fetchData();
        } else {
          izitoast.error({
            title: "Failed",
            message:
              "Format Nama Sertifkat salah seharusnya ( npwp.p12 ) dan file yang diupload tidak sesuai",
          });
        }
      } catch (e) {
        var pesan = "";
        try {
          pesan = e.response.data.detail;
        } catch (e) {}
        izitoast.error({
          title: "Failed",
          message: "Sertifikat gagal di update dengan pesan " + pesan,
        });
      }
    }
  }

  // async efakturKey(item){
  //     var _this = this
  //     var form = await this.context.showDialog((props, resolve, reject)=> ({
  //         title: null,
  //         width: 800,
  //         height: 600,
  //         okText: "Upload",
  //         cancelText: "Tutup",
  //         contentStyle: {
  //             height: 600
  //         },
  //         text: (
  //           <div className="md-grid">
  //             <Field
  //                 label='Key'
  //                 name='certKey'
  //                 className="md-cell md-cell--12"
  //                 component={Textfield}
  //                 validate={validation.required}
  //             />
  //           </div>
  //         )
  //     }))
  //     if(form){
  //         try {
  //             let res = await this.service.api.updateKey({
  //                 npwp: item.info.npwp,
  //                 wp: {
  //                     certKey: form.certKey
  //                 }
  //             }, form.npwp)
  //             izitoast.success({
  //                 title: "Success",
  //                 message: "Sertifikat Key berhasil di update"
  //             })
  //         } catch(e){
  //             izitoast.error({
  //                 title: "Failed",
  //                 message: "Sertifikat Key gagal di update dengan pesan " + e.message
  //             })
  //         }
  //     }
  // }

  _barItem() {
    return <div></div>;
  }

  columns = [
    {
      label: "word.npwp",
      searchField: "npwp",
      value: "npwp",
      isDefaultSort: false,
      isSortable: true,
      show: true,
      isSearchable: true,
    },
    {
      label: "word.registered",
      searchField: "registered",
      value: "registered",
      type: "boolean",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.hasCertificate",
      searchField: "hasCertificate",
      value: "hasCertificate",
      type: "boolean",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.registeredToDjp",
      searchField: "registeredToDjp",
      value: "registeredToDjp",
      type: "boolean",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.certExpiredDate",
      searchField: "certExpiredDate",
      value: (d) => {
        if (d && d.info && d.info.certExpiredDate) {
          try {
            return moment(new Date(d.info.certExpiredDate)).format(
              "DD/MM/YYYY"
            );
          } catch (e) {
            return "-";
          }
        } else {
          return "-";
        }
      },
      type: "func",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
  ];
}

export default WpView;
