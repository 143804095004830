import React, { Component } from 'react';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm, navService } from './../../libs/react-mpk';
import { Card, Switch, Paper, Button, LinearProgress  } from 'react-md';
import izitoast from 'izitoast';

export default class FormView extends Component {
    service;
    translate=true;
    viewType=1;
    initialData={};

    navService=navService

    titleHeader() {
        return `entities.${this.service.name}.title`;
    }

    constructor(props) {
        super(props)
        this.state = {
            onProgress: false,
            isError: false,
            errorMessage: '',
        }
    }

    componentDidMount(){
        this.initData()
    }

    async initEdit(){
        let res = await this.service.api.findOne({
            id: this.props.router.match.params.id,
        });
        this.props.initialize(res.data);
    }

    async initData() {
        if(this.props.router.match.params.id == 'new') {
          this.props.initialize(this.initialData);
        } else {
          this.initEdit()
        }
    }

    async beforeSave(val) { return true; }
    async afterSave(res){
        return res
    }

    enableRedirect = true
    async redirectTo(res){
        if(this.enableRedirect){
            var currentUrl = this.props.router.match.url
            var currentUrlSplit = currentUrl.split('/');
            var newUrl = []
            currentUrlSplit.map((d, index)=> {
                var stopIn = currentUrlSplit.length - 1;
                if(index != stopIn){ newUrl.push(d) }
            })
            this.props.router.history.push(newUrl.join('/'))
        }
    }

    async updateData(res, value){
        res = await this.service.api.update(value)
    }

    async saveData(res, value){
        res = await this.service.api.save(value)
    }
    
    async handleSave(value) {
        var beforeSave = await this.beforeSave(value);
        if(beforeSave) {
            if(typeof beforeSave === 'object') value = beforeSave;
            try {
                this.setState({onProgress: true})
                var res = null
                if(value.id) {
                    await this.updateData(res, value)
                } else {
                    await this.saveData(res, value)
                }
                await this.afterSave(res)
                await this.redirectTo(res);
                this.setState({onProgress: false})
                izitoast.success({
                    title: 'Sukses',
                    message: 'Data berhasil disimpan.'
                })
            } catch(e){
                window.hahaha = e
                var xxx = e.response.data.detail
                if(xxx == null){
                    xxx = e.response.data.message
                }
                izitoast.error({
                    title: 'Gagal',
                    message: 'Data gagal disimpan, pesan error : ' + xxx
                })
                var msg = e.message;
                if(e.response) msg = e.response.data.message;
                this.setState({
                    isError: true,
                    onProgress: false,
                    errorMessage: msg
                })
            }
        }
    }

    _barActions = []

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        return (
        <div>
            <Button secondary flat onClick={()=> { navService.back(); }} style={{marginRight: 16}}>Kembali</Button>
            <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Simpan</Button>
        </div>
        )
    }

    barItem() {return this._barItem()}

    _commandBar(props) {
        var barActions = this._barActions;
        if(this.barActions) barActions = this.barActions;
        return (
            <Commandbar title={this.titleHeader()} translate={this.translate} actions={barActions} rightCorner={this.barItem()}/>
        )
    }
    
    commandBar(props) {
        return this._commandBar(props);
    }

    _viewContainer(props) {
        return <div className="mpk-layout column fill" style={{background: '#EEEEEE' }}>
            {props.children}
        </div>
    }
    
    viewContainer = (props) => {
        return this._viewContainer(props);
    }

    view1() {
        return <this.viewContainer>
            {this.commandBar()}
            <div className="mpk-padding-N all flex">
                {this.state.onProgress ? (
                  <LinearProgress style={{margin: 0}} query={true} />
                ) : (null)}
                <Card className="mpk-dialog mpk-content column">
                    {this.formView()}
                </Card>
            </div>
        </this.viewContainer>
    }
    
    view2() {
        return <this.viewContainer>
            {this.commandBar()}
            <div className="mpk-padding-N all flex mpk-content">
                {this.state.onProgress ? (
                  <LinearProgress style={{margin: 0}} query={true} />
                ) : (null)}
                {this.formView()}
            </div>
        </this.viewContainer>
    }

    render() {
        if(this.viewType === 1) {
          return this.view1()
        } else {
          return this.view2()
        }
    }

    formView() {
        return <div>123123123123</div>
    }

    formatDate(e){
        // if(e){
        //   return moment(e, "DD/MM/YYYY").toDate()
        // } else {
        //   var now = new Date()
        //   var x = moment(now, "DD/MM/YYYY").toDate()
        //   return x
        // }
        return e
    }
};