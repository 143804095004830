import React, { Component } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import _ from 'lodash';
import { Field, initialize } from 'redux-form';
import {
  Tabs, Tab,
  List,
  ListItem,
  Avatar,
  FontIcon,
  Button,
  DialogContainer
} from 'react-md';
import PropTypes from 'prop-types'

import { validation, Searchfield, Textfield } from './../Form'
import izitoast from 'izitoast'

export default class TableSearchCustom extends Component {

  static contextTypes = {
    showDialog: PropTypes.func,
  };

  tabs = ["default"];

  constructor() {
    super();
    this.state = {
      currentTab: 0
    }
  }

  onSearchClick() {
    if(this.props.service.customSearch){
      var params = {}
      Object.keys(this.props.tableFilter2).map((d)=> {
        params[d.split("__").join(".")] = this.props.tableFilter2[d]
      })
      this.props.onSearch(params)
    } else {
      var params = {}
      var column = this.props.tableFilter2.column;
      var searchKey = this.props.tableFilter2.searchKey;
      params[column] = searchKey
      this.props.onSearch(params)
    }
  }

  onTabChange = (index, tabId) => {
    this.setState({ currentTab: index });
  };

  onDeleteSearch(i) {
    var tableFilter = _.cloneDeep(this.props.tableFilter);
    tableFilter.splice(i, 1);
    this.props.service.api.updateFilter(tableFilter, this.props.dispatch);
  }

  async onAddSearch(data = {}, i) {

    var fieldOptions = this.props.columns.map((d, i) => {
      var id = d.value;
      // if(typeof id === 'function') id = id();
      if (d.searchField) id = d.searchField

      return {
        id: id,
        name: counterpart.translate(d.label)
      }
    })

    fieldOptions.splice(-1, 1);

    var res = await this.context.showDialog(() => ({
      title: 'Tambah Pencarian',
      initialValue: data,
      contentProps: {
        style: {
          maxHeight: 300
        }
      },
      text: (
        <div>
          <Field
            label='Field Name'
            name='fieldName'
            className="md-cell md-cell--12"
            options={fieldOptions}
            component={Searchfield}
          />
          <Field
            label='Action'
            name='action'
            className="md-cell md-cell--12"
            options={[{ id: 'contains', name: 'contains' }, { id: 'equals', name: 'equals' }]}
            component={Searchfield}
          />
          <Field
            label='Value'
            name='value'
            className="md-cell md-cell--12"
            component={Textfield}
          />
        </div>
      )
    }))

    if (res) {
      var tableFilter = _.cloneDeep(this.props.tableFilter);
      if (i || i === 0) {
        tableFilter[i] = _.cloneDeep(res);
      } else {
        tableFilter.push(_.cloneDeep(res));
      }


      this.props.service.api.updateFilter(tableFilter, this.props.dispatch);
    }
  }

  switchTab() {
    switch (this.state.currentTab) {
      case 0:
        return (
          this.defaultTab()
        );
      case 1:
        return (
          this.searchTab()
        );
      default:
        return (null);
    }
  }

  onSearchClear(){
    this.props.dispatch(initialize(this.props.service.name+'_search', {}));
  }

  defaultTab() {
    return (
      <div className='mpk-layout column fill'>
        <div className="flex" style={{ overflow: 'auto' }}>
          {this.props.searchForm}
        </div>

        <div className="mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark">
            <Button className='flex' raised primary onClick={() => this.onSearchClick()}><Translate content={"word.search"} /></Button>
        </div>
        <div className="mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark">
            <Button className='flex' raised primary onClick={() => this.onSearchClear()}>Clear</Button>
        </div>
      </div>
    )
  }

  searchTab() {
    return (
      <div className='mpk-layout column fill'>
        <div className='flex' style={{ overflow: 'auto' }}>
          <List>
            {this.props.tableFilter.map((d, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={() => this.onAddSearch(d, i)}
                  rightIcon={<FontIcon onClick={(e) => { this.onDeleteSearch(d, i); e.stopPropagation() }}>delete</FontIcon>}
                  primaryText={d.fieldName}
                  secondaryText={`${d.action}\n${d.value}`}
                  threeLines
                />
              )
            })}
          </List>
        </div>
        <div className="mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark">
          <Button className='flex' raised primary onClick={() => this.onSearchClick()} style={{ marginRight: 5 }}><Translate content={"word.search"} /></Button>
          {/* <div className='flex'/> */}
          <Button className='flex' flat iconClassName='mdi mdi-plus' onClick={() => this.onAddSearch()} style={{ marginLeft: 5 }}>Add</Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="mpk-full height mpk-layout column">
        <div className="flex">
          {this.switchTab()}
        </div>

        <Tabs className='mpk-tab-white' tabId="simple-tab" mobile={true} onTabChange={this.onTabChange}>
          {this.tabs.map((d, i) => (
            <Tab key={i} label={(
              <Translate content={"word." + String(d)} />
            )} />
          ))}
        </Tabs>
      </div>
    )
  }


}
