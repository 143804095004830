/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 10:39:10
 * @modify date 2018-01-17 10:39:10
 * @desc [description]
 */
import axios from "axios";
// import env from 'env';
import izitoast from "izitoast";
import hosts from "./hosts";
import utilsService from "./utilsService";
import cookie from "react-cookies";
import moment from "moment";

import { loadProgressBar } from "axios-progress-bar";

loadProgressBar();

izitoast.settings({
  timeout: 10000,
  pauseOnHover: true,
  resetOnHover: true,
});

export const configureHttp = function () {
  if (cookie.load("MPK_ACCESS_TOKEN")) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.load(
      "MPK_ACCESS_TOKEN"
    )}`;
  }

  axios.interceptors.response.use(
    function (response) {
      if (response.data && response.data.status === 0) {
        izitoast.warning({title: 'Warning', message: response.data.message })
        return Promise.reject({
          statusCode: 400,
          response: {
            statusCode: 400,
            data: { statusCode: 400, message: response.data.message },
          },
        });
      }
      return response;
    },
    function (error) {
      if (error && error.response && error.response.config.dataType == "blob") {
        var json = JSON.parse(arrayBufferToString(error.response.data))
        return Promise.reject({
          response: {
            data: json
          }
        }) 
      } else {
        return Promise.reject(error);
      }
    }
  );
};

function arrayBufferToString(buffer){
  var arr = new Uint8Array(buffer);
  var str = String.fromCharCode.apply(String, arr);
  if(/[\u0080-\uffff]/.test(str)){
      throw new Error("this string seems to contain (still encoded) multibytes");
  }
  return str;
}

configureHttp();

export default axios;
