/**
 * Created by dwiargo on 2/22/18.
 */

import React, { Component } from 'react';
import { httpService, LoaderInfo, mpkEnv, localStorageService, locationService } from 'libs/react-mpk';
import { hosts, utilsService } from 'services';
import { rdxConnect } from 'libs/react-mpk';
import t from 'counterpart';
import { authService } from './libs/react-mpk';
import qs from 'query-string';
import App from './App';

class AppInfo extends Component{
  constructor(){
    super();
    this.state = {
      onProgress:true,
      asWidget: false
    }
  }

  async componentWillMount(){
    t.setLocale('id');
    this.setLocale();
    await this.getParentCredentials();
    this.getApplicationConf();
  }

  getParentCredentials = () => {
    return new Promise(resolve => {
      let parseWidget = window.location.pathname.match(/widget/g);
      let asWidget = parseWidget ? parseWidget[0] === 'widget' : false;

      // if(asWidget) authService.clearCookie();

      const parsedUrl = qs.parseUrl(window.location.href);
      let { accessToken, widgetClientId, accessKey, access_key, origin, androidUri, profileOnly } = parsedUrl.query;
      
      if(accessToken || accessKey) authService.clearCookie();
      if(accessToken) authService.setAccessToken(accessToken);
      if(widgetClientId) utilsService.setReqOptionsClient(widgetClientId);
      if(widgetClientId) utilsService.setReqOptionsClientId(widgetClientId);
      if(!widgetClientId) {
        // // diubah
        // // clientId = "6UGsT9hJsJmNjYKtMWlvMaRttlHuys1ZJor4K6C1" // Dev 
        // // var clientId = "3yQrn1cjBBHZthL1PrOxWkYNtxjicUMl0JCiFmB1" // Prod 
        // // diubah
        // utilsService.setReqOptionsClient(clientId);
        // utilsService.setReqOptionsClientId(clientId);
      }
      
      if(accessKey || access_key) authService.setAccessKey(accessKey || access_key);

      utilsService.setReqOptionsAsWidget(asWidget);
      this.setState({asWidget})
      this.props.tmpObjectActions.setProperties({
        asWidget, widgetClientId, androidUri,
        profileOnly: Number(profileOnly) > 0 ? true : false,
        parentOrigin: origin
      });
      resolve();
    })
  }

  getApplicationConf = () => {
    let { asWidget } = this.state;
    httpService.get({
      url:'/api/portals/host'
    }).then(response => {
      const { appInfo, appConsole, constant, apiGateway, payment, theme, supportUrl, widgetInterface, widget } = response.data
      hosts.setHost(response.data);
      mpkEnv.setTheme(theme);
      mpkEnv.setPortal({channel: 'user-balance-account'});
      // Diubah
      if(!asWidget){
        // utilsService.setReqOptionsClientId(response.data.iam.credentials.client_id);
        utilsService.setReqOptionsClient(widget.widgetClientId);
        utilsService.setReqOptionsClientId(widget.widgetClientId);
      }
      // Diubah
      this.props.globalActions.setProperties({
        readyHosts:true,
        timestamp: new Date(response.data.timestamp),
        appInfo, appConsole, constant, apiGateway, payment,
        supportUrl, widgetInterface
      });
      setTimeout(() => this.setState({onProgress:false}))
    }, error => {
      locationService.errorPage(error);
    })
  }

  setLocale = () => {
    let lang = 'id';
    this.props.globalActions.setProperties({
      localeCode: lang,
      localeList:[
        {label:'Bahasa', code:'id'}
      ]
    });
    t.setLocale(lang);
  }

  render(){
    let { asWidget } = this.state;
    return(
      <div 
        className={`mpk-full ${asWidget ? 'width height' : 'viewport-width viewport-height'}`}
        style={{
          background: asWidget ? 'white' : 'unset'
        }}
      >
        { this.state.onProgress ? (
          <LoaderInfo 
            statusText="loading global information.."
            className={asWidget ? 'mpk-padding-NONE padding-top padding-bottom' : ''}
          />
        ):(
          <App 
            ignoreAuth={false}
            asWidget={asWidget}
          />
        )}
      </div>
    )
  }
}

export default rdxConnect(AppInfo);