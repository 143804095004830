import Service from './service';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import TableService from './table.service';
import { bindActionCreators } from 'redux';

import { httpService } from 'libs/react-mpk';
import utilsService from './utilsService';

export default class ApiService extends Service {
  reducer;
  apiMockup;
  repoType= 'Default'; //Default || QueryDsl
  path;

  replacePath(params, method){
    return this.path
  }

  // API -------------------------------
	_api = {
    find: async (params={}, dispatch=()=>{}, opt={})=> {
      if(opt.path) this.path = opt.path;
      let options = utilsService.generateGatewayOptions(`/${this.replacePath(params, 'find')}`);
      options.params = params;
      var res = await httpService.get(options)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND`,
        data: res.data
      })
      return res
    },

		page: async (params={}, dispatch=()=>{}, opt={})=> {
      if(opt.path) this.path = opt.path;
      let options = utilsService.generateGatewayOptions(`/${this.replacePath(params, 'page')}`);
      options.params = params;
      var res = await httpService.get(options)
      dispatch({
        type: `${this.name.toUpperCase()}_PAGE`,
        data: res.data
      })
      return res
    },

		findOne: async (item, dispatch=()=> {})=> {
      let options = utilsService.generateGatewayOptions(`/${this.replacePath(item, 'findOne')}`);
      var res = await httpService.get(options)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })
      return res
    },

		save: async (data, dispatch=()=>{})=> {
      let options = utilsService.generateGatewayOptions(`/${this.replacePath(data, 'save')}`);
      options.data = data;
      var res = await httpService.post(options)
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })
      return res
    },

		update: async (data, dispatch=()=>{})=> {
      let options = utilsService.generateGatewayOptions(`/${this.replacePath(data, 'update')}`);
      options.data = data;
      var res = await httpService.put(options)
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })
      return res
    },

		delete: async (item, dispatch=()=>{})=> {
      let options = utilsService.generateGatewayOptions(`/${this.replacePath(item, 'delete')}`);
      var res = await httpService.delete(options)
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })
    },

    deleteAll: async (ids, dispatch=()=>{})=> {

    },

    updateFilter: (data, dispatch=()=>{}) => {
      dispatch({
        type: `${this.name.toUpperCase()}_FILTER`,
        data
      });
      return data;
    }
  }

  _apiMockup={
    find: async (param={}, dispatch=()=>{})=> {
      var res = {
        data: this.apiMockup.find? this.apiMockup.find: [],
        headers: {
          'x-total-count': 5
        }
      }

      return res
    },
    findOne: async (id, dispatch=()=> {})=> {
      return this.apiMockup.findOne? this.apiMockup.findOne: {}
    },
    save: async (data, dispatch=()=>{})=> {
      return {}
    },
    update: async (data, dispatch=()=>{})=> {
      return {}
    },
    delete: async (id, dispatch=()=>{})=> {
      return {}
    }
  }

	initialApiState = {
		find: [],
		page: {
			totals: 0,
			results: []
		},
    data: {},
    filter: []
	}

	apiReducer() {
		var obj = this;
		return function(state=obj.initialApiState, action) {
			switch (action.type) {
				case `${obj.name.toUpperCase()}_FIND`:
        return {
          ...state,
          find:action.data
        }
				case `${obj.name.toUpperCase()}_PAGE`:
        return {
          ...state,
          page:action.data
        }
				case `${obj.name.toUpperCase()}_FIND_ONE`:
        return {
          ...state,
          data:action.data
        }
				case `${obj.name.toUpperCase()}_SAVE`:
        return {
          ...state,
          data:action.data
        }
				case `${obj.name.toUpperCase()}_UPDATE`:
        return {
          ...state,
          data:action.data
        }
        case `${obj.name.toUpperCase()}_FILTER`:
        return {
          ...state,
          filter:action.data
        }
				default:
					return state;
			}
		}
  }
  // END API ---------------------------

  // TABLE -----------------------------
  //** @type {TableService} *//
  TableService:tableService;
  tableReducer;
  tableAction={}
  // END TABLE -------------------------

  // CONNECT ---------------------------
  stateConnectSetting() {
    var obj = this;
    return (state) => {
      return {
        searchForm: getFormValues(`${obj.name.toUpperCase()}_SEARCH`)(state),
        tableFilter: state.entity[this.name].api.filter,
        tableFilter2: getFormValues(obj.name+'_search')(state),
        table:state.entity[this.name].table,
        global:state.global
      }
    }
  }

  actionConnectSetting() {
    var obj = this;
    return (dispatch) => {
      return {
        tableActions:bindActionCreators(obj.tableAction, dispatch),
        dispatch: dispatch
      }
    }
  }
  // END OF CONNECT --------------------

  // Method
  constructor() {
    super();
    this.init()
  }

  init() {
    this.initTableService()
		this.initApi()
    this.initReducers()
    return this;
  }

  initTableService() {
    var tableService = new TableService(this.name);
    this.tableService = tableService;
    this.tableAction = tableService.tableAction;
    this.tableReducer = tableService.reducer

  }

	initApi() {
		if(this.api) {
			this.api = {
				...this._api,
				...this.api
			}
		} else {
			this.api = this._api
    }

    if(this.apiMockup) {
      this.api = {
        ...this.api,
        ...this._apiMockup
      }
    }
  }

  initReducers() {
    this.reducer = this.combineReducers({
      api: this.apiReducer(),
      table: this.tableReducer
		})
  }

  initTable() {

  }
}
