/**
 * Created by dwiargo on 2/21/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button } from 'react-md';
import Logo from '../Logo/Logo';
import authService from '../../services/authService';
import renderHTML from 'react-render-html';
import './ErrorPage.scss';

class ErrorPage extends Component{
  clearCookie = () => {
    authService.logout(null, this.props.redirectUri);
  };

  reload = () => {
    let { redirectUri='/', onReload } = this.props;
    if(onReload) onReload();
    else window.location = redirectUri;
  };

  render(){
    let { status, errorMessage, statusText, onClearCookie, reloadLabel="Reload" } = this.props;

    return (
      <div className="mpk-error-page mpk-layout fill column align-center">
        <Paper>
          <div className="mpk-layout column-sm align-center">
            <div className="illustration">
              <div className="sign flex-none">
                <div className="board"></div>
                <h1>!</h1>
              </div>
            </div>
            <div className="flex body-content mpk-layout column">
              <Logo
                style={{
                  height:48
                }}
                />
              <div className="mpk-layout mpk-margin-N bottom top">
                <div className="status">{status}</div>
                <div className="status-text text-body">{statusText}</div>
              </div>
              <p className="error-message mpk-body text-body mpk-dont-break-out">
                {errorMessage ? renderHTML(errorMessage) : 'Something wrong. Please try again later'}
              </p>
              <div className="mpk-margin-N top mpk-layout">
                <Button
                  secondary raised
                  className="mpk-margin-S right"
                  onClick={this.reload}
                  >
                  {reloadLabel}
                </Button>
                {this.props.showRelogin ? (
                  <Button
                    primary raised
                    className="mpk-margin-S right"
                    onClick={() => onClearCookie ? onClearCookie() : this.clearCookie()}
                    >
                    Re-Login
                  </Button>
                ) : null}
                <span className="flex"/>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

ErrorPage.defaultProps = {
  showRelogin: true
}

ErrorPage.propTypes = {
  status:PropTypes.any,
  statusText:PropTypes.string,
  errorMessage:PropTypes.string,
  onReload:PropTypes.func,
  onClearCookie:PropTypes.func
}

export default ErrorPage;