import React from "react";
import {
  TabsContainer,
  Tabs,
  Tab,
  DialogContainer,
  List,
  ListItem,
  Button,
  Card,
} from "react-md";
import ListView from "../../../components/Base/ListView";
import HistoryService from "./history.service";
import { connect } from "react-redux";
import moment from "moment";
import { SearchField } from "../../../libs/react-mpk";
import PropTypes from "prop-types";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
  validation,
  Searchfield,
  Textfield,
  FileInputNext,
} from "../../../components/Form";
import counterpart from "counterpart";
import izitoast from "izitoast";

@reduxForm({ form: "HistoryForm", destroyOnUnmount: true })
@connect(
  (state) => ({
    // EPPT CORE
    ...HistoryService.stateConnectSetting()(state),
    formData: getFormValues("HistoryForm")(state),
    auth: state.auth.user,
    // EPPT CORE
  }),
  HistoryService.actionConnectSetting()
)
class HistoryView extends ListView {
  service = HistoryService;
  apiPath = "getAll";
  tabs = ["column"];

  plainTable() {
    return false;
  }

  header() {
    return (
      <div>
        <TabsContainer colored activeTabIndex={2}>
          <Tabs tabId="simple-tab" style={{ padding: "0px !important;" }}>
            <Tab
              label="SPT"
              onClick={() => {
                var path = this.props.router.location.pathname;
                var pathnext = path.split("/")[1];
                this.navService.redirectTo(`/${pathnext}/spt`);
              }}
            ></Tab>
            <Tab
              label="Wajib Pajak"
              onClick={() => {
                var path = this.props.router.location.pathname;
                var pathnext = path.split("/")[1];
                this.navService.redirectTo(`/${pathnext}/wp`);
              }}
            ></Tab>
            {/* <Tab
              label="Riwayat"
              onClick={() => {
                var path = this.props.router.location.pathname;
                var pathnext = path.split("/")[1];
                this.navService.redirectTo(`/${pathnext}/history`);
              }}
            ></Tab> */}
          </Tabs>
        </TabsContainer>
        
        <br />
      </div>
    );
  }

  async handleSearchClear() {
    this.props.initialize({});
    this.fetchData();
  }

  static contextTypes = {
    showDialog: PropTypes.func,
  };

  _barActions = [
    {
      label: "custom.words.refresh",
      iconClassName: "mdi mdi-refresh",
      onClick: () => this.fetchData(),
    },
  ];

  overideTotal(res) {
    return res.data.hits.total.value;
  }
  overideData(res){
    // console.log(res.data.hits.hits, 'asdads')
    return res.data.hits.hits
  }

  _tableActions = [
    {
      label: "Detail Data",
      iconClassName: "mdi mdi-bell",
      onClick: (item) => this.editItems(item),
    },
    {
      label: "Upload Sertifikat",
      iconClassName: "mdi mdi-tag",
      onClick: (item) => this.uploadSertifikat(item),
      ifCon: "item.registered == true",
    },
    // {
    //     label:"Setting Efaktur Key",
    //     iconClassName:"mdi mdi-key",
    //     onClick: (item) => this.efakturKey(item)
    // }
  ];

  editItems(item) {
    this.navService.redirectTo(`wp/${item.npwp}`);
  }

  _barItem() {
    return <div></div>;
  }

  columns = [
    {
      label: "word.action",
      searchField: "action",
      value: "_source.action",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: true,
    },
    {
      label: "word.req",
      searchField: "req",
      value: "_source.req",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.date",
      searchField: "executionDate",
      value: "_source.executionDate",
      type: "date",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    },
    {
      label: "word.username",
      searchField: "username",
      value: "_source.username",
      isDefaultSort: false,
      isSortable: false,
      show: true,
      isSearchable: false,
    }
  ];
}

export default HistoryView;
