import React from 'react';
import FormView from '../../../components/Base/FormView';
import SptService from './Spt.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { Card, ExpansionList, ExpansionPanel, TextField, Button, DataTable, TableHeader, TableColumn, TableRow, TableBody, List, ListItem, Paper, FontIcon, Dialog, DialogContainer } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection, Switch, Checkbox } from '../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import WpService from '../Wp/Wp.service';

@reduxForm({form: 'SptForm', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('SptForm')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class SptFormView extends FormView {
    service=SptService;
    viewType=2;

    constructor(props){
        super(props);
        this.state = {
            ...this.state,
            getNpwp: false,
            npwpList: [],
            npwpListB: [],
            showDialog: false
        }
    }

    async redirectTo(res){
        if(this.enableRedirect){
            var path = this.props.router.location.pathname
            var pathnext = path.split("/")[1]
            this.navService.redirectTo(`/${pathnext}/spt`)
        }
    }

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    async initEdit(){
        let res = await this.service.api.findOne({
            id: this.props.router.match.params.id,
        });
        this.props.initialize(res.data);
    }

    initialData = {
        masa: new Date().getMonth() + 1,
        tahun: parseInt(new Date().getFullYear()),
        pembetulan: 0,
        companies: []
    }

    async handleNpwp(){
        try {
            // let npwpBulk = await this.service.api.getNpwpBulk(this.props.formData.tahun, this.props.formData.masa, this.props.formData.pembetulan)
            // console.log("NPWP YANG BISA DIBULK", npwpBulk)
            let npwpInfo = await WpService.api.getAll();
            // console.log("NPWP YANG PUNYA INFO", npwpBulk)
            var npwpList = [];
            // var validNpwp = npwpBulk.data.filter((d)=> {  return d.valid == true })
            // console.log("NPWP YANG VALID", validNpwp)
            // validNpwp.map((vn)=> {
            //     npwpInfo.data.map((d)=> {
            //         // console.log("NPWP YANG ADA DI BULK DAN ADA DI INFO", d.npwp, vn.npwp)
            //         if(vn.npwp == d.npwp){
            //             if(d.registered && d.hasCertificate && d.registeredToDjp){
            //                 // console.log("NPWP YANG SUDAH TERMASUK KATEGORY DIATAS DAN TEREGISTER KE DJP PUNYA SERTIFIKAT DLL", d)
            //                 npwpList.push(d)
            //             }
            //             // npwpList.push(d)
            //         }
            //     })
            // })
            // console.log(npwpList, npwpList)
            this.setState({ npwpList: npwpInfo.data.filter(d => d.registered && d.hasCertificate && d.registeredToDjp), npwpListB: npwpInfo.data.filter(d => d.registered && d.hasCertificate && d.registeredToDjp), showDialog: true })
            // this.handleNpwpDialog()
        } catch(e){
            var message = ""
            if(e){
                if(e.message) message = e.message
                if(e.response && e.response.data && e.response.data.detail) message = e.response.data.detail
            }
            izitoast.error({
                title: "Error",
                message: `Get List Npwp gagal dengan pesan ${message}`
            })
        }
    }

    async saveData(res, value){
        await Promise.all(value.companies.map(x => this.service.api.saveByParam({
            company: x.company,
            masa: value.masa, 
            tahun: value.tahun,
            pembetulan: value.pembetulan
        })))
    }

    async handleClearPick(index){
        try {
            var companies = this.props.formData.companies
            var newComp = []
            companies.map((d, i)=> {
                if(i == index){

                } else {
                    newComp.push(d)
                }
            })
            this.props.change('companies', newComp)
        } catch(e){}
    }

    async handlePick(){
        try {
            var npwpSelected = this.state.npwpList.filter((list)=> {
                return list.checked == true
            })
            var companies = []
            if(npwpSelected.length > 0){
                var getDataCompany = await Promise.all(npwpSelected.map( async (npwpData)=>{
                    return new Promise( async (resolve, reject)=> {
                        let getData = await WpService.api.getOne(npwpData.npwp);
                        resolve(getData.data);
                    })
                }))
                getDataCompany.map((npwpData)=> {
                    npwpData = {
                        info: npwpData
                    }
                    var x = {
                        company: {},
                        penandatanganInfo: {}
                    }
                    x.company.npwp = npwpData.npwp
                    if(npwpData.info){
                        x.company.npwp = npwpData.info.npwp
                        x.company.name = npwpData.info.name
                        x.company.alias = npwpData.info.alias
                        x.company.address = npwpData.info.infoAddress
                        x.company.klu = npwpData.info.infoKlu
                        x.company.mobile = npwpData.info.infoMobile
                        x.company.phone = npwpData.info.infoPhone
                        x.company.wajibPpnbm = npwpData.info.infoWajibPpnbm
                        x.penandatanganInfo.kotaLapor = npwpData.info.infoCity
                    }
                    try {
                        if(npwpData.info.signers[0].active){
                            x.penandatanganInfo.chkPKP = true
                            x.penandatanganInfo.chkKuasa = false
                            x.penandatanganInfo.namaPenandatangan = npwpData.info.signers[0].name
                            x.penandatanganInfo.jabatanPenandatangan = npwpData.info.signers[0].occupation
                        }
                        if(npwpData.info.signers[1].active){
                            x.penandatanganInfo.chkPKP = false
                            x.penandatanganInfo.chkKuasa = true
                            x.penandatanganInfo.namaPenandatangan = npwpData.info.signers[1].name
                            x.penandatanganInfo.jabatanPenandatangan = npwpData.info.signers[1].occupation
                        }
                    } catch(e){
                        console.log(e)
                    }
                    companies.push(x)
                })
                this.props.change('companies', companies)
            }
        } catch(e){}
        this.setState({ showDialog: false })
    }

    async handleDialogSearch(){
        var npwp = ''
        if(this.props.formData && this.props.formData.npwp) npwp = this.props.formData.npwp
        var npwpList = this.state.npwpList.filter((d)=> {
            return d.npwp.indexOf(npwp) != -1
        })
        this.setState({ npwpList: npwpList })
    }

    async handleDialogClear(){
        this.props.change('npwp', null)
        this.setState({
            npwpList: this.state.npwpListB
        })
    }

    formView() {
        var companies = []
        if(this.props.formData && this.props.formData.companies) companies = this.props.formData.companies
        return (
            <div className="mpk-center-container">
                <div>
                    <DialogContainer
                        id="Pilih Npwp"
                        initialFocus="Pilih Npwp"
                        visible={this.state.showDialog}
                        title=""
                        width={800}
                        height={600}
                        contentStyle={{
                            height: 600
                        }}
                        onHide={()=> {
                            this.setState({ showDialog: false })
                        }}
                        actions={[
                            { secondary: true, children: 'Tutup', onClick: ()=> { this.setState({ showDialog: false }) }},
                            { primary: true, children: 'Pilih', onClick: ()=> { this.handlePick() }}
                        ]}
                    >
                        <div className="md-grid">
                            <Field
                                label='Npwp'
                                name='npwp'
                                className="md-cell md-cell--10"
                                // normalize={(e)=> parseInt(e) }
                                component={Textfield}
                            />
                            <div className="md-cell md-cell--1">
                                <Button icon raised primary onClick={this.handleDialogSearch.bind(this)} iconEl={<FontIcon>search</FontIcon>}></Button>
                            </div>
                            <div className="md-cell md-cell--1">
                                <Button icon raised primary onClick={this.handleDialogClear.bind(this)} iconEl={<FontIcon>close</FontIcon>}></Button>
                            </div>
                            <div className="md-cell md-cell--12">
                                <DataTable onRowToggle={(rowId, checked)=> {
                                    try {
                                        if((rowId - 1) == -1){
                                            var state = this.state.npwpList
                                            state.map((d)=> d.checked = checked)
                                            this.setState({ npwpList: state })
                                        } else {
                                            var state = this.state.npwpList
                                            state[rowId-1].checked = checked
                                            this.setState({ npwpList: state })
                                        }
                                    } catch(e){}
                                }}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableColumn>NPWP</TableColumn>
                                            <TableColumn>Nama</TableColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {this.state.npwpList.map((d, index)=> {
                                            return (
                                                <TableRow key={index}>
                                                    <TableColumn>{d.npwp}</TableColumn>
                                                    <TableColumn>{d.name}</TableColumn>
                                                    {/* {d.info &&
                                                        <React.Fragment>
                                                            <TableColumn>{d.info.name}</TableColumn>
                                                        </React.Fragment>
                                                    } */}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </DataTable>
                            </div>
                        </div>
                    </DialogContainer>
                </div>
                <Card>
                    <div className="md-grid">
                        <Field
                            label="Masa Pajak"
                            name='masa'
                            className="md-cell md-cell--12"
                            component={Searchfield}
                            validate={validation.required}
                            options={[
                                {id: 1, name: 1},
                                {id: 2, name: 2},
                                {id: 3, name: 3},
                                {id: 4, name: 4},
                                {id: 5, name: 5},
                                {id: 6, name: 6},
                                {id: 7, name: 7},
                                {id: 8, name: 8},
                                {id: 9, name: 9},
                                {id: 10, name: 10},
                                {id: 11, name: 11},
                                {id: 12, name: 12}
                            ]}
                        />
                        <Field
                            label='Tahun Pajak'
                            name='tahun'
                            className="md-cell md-cell--12"
                            // normalize={(e)=> parseInt(e) }
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Pembetulan'
                            name='pembetulan'
                            className="md-cell md-cell--12"
                            component={TextfieldMask}
                            money={true}
                            validate={validation.requiredMoney}
                            normalize={(e)=> parseInt(e) }
                        />
                        {!this.state.getNpwp &&
                            <div className="md-cell md-cell--12">
                                <Button raised primary style={{ marginTop: 20 }} onClick={this.props.handleSubmit(this.handleNpwp.bind(this))}>Pilih NPWP</Button>
                            </div>
                        }
                    </div>
                    {companies.length > 0 &&
                    <div className="md-grid">
                        {companies.map((d, index)=> {
                            // console.log(d)
                            return (
                                <div className="md-cell md-cell--3" key={index}>
                                    <Paper>
                                        <List>
                                            <ListItem rightIcon={<FontIcon onClick={this.handleClearPick.bind(this, index)}>close</FontIcon>} primaryText={`NPWP: ${d.company.npwp}`} secondaryText={`Nama: ${d.company.name}`}></ListItem>
                                        </List>
                                    </Paper>
                                </div>
                            )
                        })}
                    </div>
                    }
                </Card>
            </div>
        )
    }

};

export default SptFormView;