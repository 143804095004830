import React from 'react';
import { FontIcon } from 'react-md';
import './RatePlanCardImg.scss';

const RatePlanCardImg = ({src, size}) => (
    <div className={`mpk-rate-plan-card-img ${size?('size-'+size):''}`}>
        {src ? (
            <img src={src} role="presentation" alt="rate-plan"/>
        ) : (
            <div className="default mpk-layout align-center justify-center">
                <FontIcon iconClassName="mdi mdi-cube"/>
            </div>
        )}
    </div>
)

export default RatePlanCardImg;