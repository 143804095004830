import {
  auth, table, global, toast, dialog, tmpObject
} from 'libs/react-mpk/redux/reducers';

import image from './imageReducer';
import { reducer as formReducer } from 'redux-form'

import {combineReducers} from 'redux';

const entityReducer = combineReducers({
  Spt: require('../../modules/Company/Spt/Spt.service').default.reducer,
  A1: require('../../modules/Company/SptIn/A1/A1.service').default.reducer,
  A2: require('../../modules/Company/SptIn/A2/A2.service').default.reducer,
  B1: require('../../modules/Company/SptIn/B1/B1.service').default.reducer,
  B2: require('../../modules/Company/SptIn/B2/B2.service').default.reducer,
  B3: require('../../modules/Company/SptIn/B3/B3.service').default.reducer,
  AB1111: require('../../modules/Company/SptIn/AB1111/AB1111.service').default.reducer,
  Induk1111: require('../../modules/Company/SptIn/Induk1111/Induk1111.service').default.reducer,
  DDM: require('../../modules/Company/SptIn/DDM/DDM.service').default.reducer,
  SSPII: require('../../modules/Company/SptIn/SSPII/SSPII.service').default.reducer,
  SSPIII: require('../../modules/Company/SptIn/SSPIII/SSPIII.service').default.reducer,
  SSPIV: require('../../modules/Company/SptIn/SSPIV/SSPIV.service').default.reducer,
  SSPV: require('../../modules/Company/SptIn/SSPV/SSPV.service').default.reducer,
  Wp: require('../../modules/Company/Wp/Wp.service').default.reducer,
})

const rootReducer = combineReducers({
  auth, table, global, toast,
  image, dialog, tmpObject,
  form: formReducer,
  entity: entityReducer
});

export default rootReducer;