import React from 'react';
import { TabsContainer, Tabs, Tab, DialogContainer, Card, Divider } from 'react-md';
import ListView from '../../../../components/Base/ListView';
import SSPIIService from './SSPII.service';
import { connect } from 'react-redux';
import moment from 'moment';
import { SearchField } from '../../../../libs/react-mpk';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validation, Searchfield, Textfield, TextfieldMask } from '../../../../components/Form';
import izitoast from 'izitoast';

@reduxForm({form: 'SPT1111_SSPII', destroyOnUnmount: true})
@connect((state)=> ({
    ...SSPIIService.stateConnectSetting()(state)
}), SSPIIService.actionConnectSetting())
class SSPIIView extends ListView {
    service = SSPIIService;
    plainTable(){ return false; }

    fetchOption() {
        var params = this.props.router.match.params
        let path =  `tarra-efaktur/${params.npwp}/spt1111/ssp`
        return {
            path: path
        }
    }

    headerData = {
        jmlRecord:0
    }

    overideTotal(res){
        return parseInt(this.headerData.jmlRecord)
      }
  
      overideData(res){
        return res.data?res.data.data:[]
      }

    async beforeFetch(params){
        
        var p = this.props.router.match.params
        params.masa=p.masa
        params.tahun=p.tahun
        params.revNo=p.pembetulan
        params.pageNum = params.page?params.page+1:1
        params.pageSize = params.size?params.size:15
        params.idBayar = '0'

        // var header = await this.service.api.header(p.npwp, params)
        // this.headerData = header.data?header.data.data:{jmlRecord:0}

        // this.props.change('dpp', header.data.data.jmlDpp)
    }

    deleteItem = async (item, callback) => {
        try {
          await this.service.api.delete(item);
          callback()
          await this.fetchData()
        } catch(e) {
          callback(e.response.data.message, e.response.data.message)
        }
      }


    footer(){
        return (
            <div style={{width: '100%'}}>
                <Divider />
                <div className="md-grid" style={{padding: '0 4px', width: '100%'}}>
                <Field 
                    label="Total SSP"
                    money={true}
                    normalize={(e)=> parseInt(e)}
                    component={TextfieldMask}
                    name="ssp"
                    class="md-cell md-cell--12" />
                </div>
            </div>
        )
    }

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    async uploadSSP(){
        var router = this.props.router.match.params
        var ids = []
        var selected = this.props.table.selected
        selected.map((d)=> {
            ids.push(d.id)
        })
        var res = await this.context.showDialog(()=> ({
            title: null,
            initialValue: {},
            width: 800,
            height: 150,
            contentStyle: {
              height: 150
            },
            text: (
              <div>
               <p> Apa anda yakin akan mengupload data terpilih ?</p>
              </div>
            )
        }))
        if(res){
            try {
                let res = await this.service.api.uploadSSP(ids, router.npwp, router.tahun, router.masa);
                this.removeCheckbox();
                this.fetchData()
                izitoast.success({
                    title: "Success",
                    message: "Upload SSP berhasil diupload."
                })
            } catch(e){
                izitoast.error({
                    title: "Error",
                    message: "Upload SSP gagal silahkan cek pesan status approve message"
                })
            }
        }
    }

    _barActions = [
        {
          label:'word.back',
          iconClassName:'mdi mdi-chevron-left',
          onClick:() => {
            var params = this.props.router.match.params
            var path = this.props.router.location.pathname
            var pathnext = path.split("/")[1]
            this.navService.redirectTo(`/${pathnext}/spt_open/${params.id}/${params.npwp}/${params.tahun}/${params.masa}/${params.pembetulan}/rev`)
          }
        },
        {
            label:'custom.words.create',
            iconClassName:'mdi mdi-plus',
            onClick:() => this.addItem()
        },
        {
            label:'custom.words.refresh',
            iconClassName:'mdi mdi-refresh',
            onClick:() => this.fetchData()
        },
        // {
        //     label:'word.uploadSSP',
        //     iconClassName:'mdi mdi-upload',
        //     onClick:() => this.uploadSSP()
        // }
    ]

    _tableActions = [
        // {
        //   label:"Detail Data",
        //   iconClassName:"mdi mdi-bell",
        //   onClick: (item) => this.editItem(item),
        // //   ifCon: "item.status.approve == 'Reject' || item.status.approve == 'Belum Approve'"
        // },
        {label:"divider", iconClassName:"-"},
        {
        //   ifCon: "item.status.approve == 'Reject' || item.status.approve == 'Belum Approve'",
          label:"Hapus",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => {
            var router = this.props.router.match.params
            item.npwp = router.npwp
            this.deleteItem(item, callback)
          },
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]
    
    columns=[
        {label: "word.kodeAkunPajak", searchField: "kodeAkunPajak", value: "kodeAkunPajak", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.kodeJenisSetoran", searchField: "kodeJenisSetoran", value: "kodeJenisSetoran", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.nilai", searchField: "jumlahPembayaran", value: "jumlahPembayaran", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.ntpn", searchField: "nomorPembayaran", value: "nomorPembayaran", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.pbk", searchField: "fgNtpnPbk", value: "fgNtpnPbk", type: "boolean", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        // {label: "word.approve", searchField: "approve", value: "status.approve", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        // {label: "word.approveMsg", searchField: "approveMsg", value: "status.approveMsg", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        // {label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", type: "number", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.tahun", searchField: "tahunPajak", value: (d)=> { return d.tahunPajak }, type: 'func', isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.masa", searchField: "masaPajak", value: "masaPajak", type: 'number', isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.tglSsp", searchField: "tanggalPembayaran", value: "tanggalPembayaran", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        // {label: "word.createdDate", searchField: "createdDate", valueName:"createdDate", value: (val)=> {
        //   return moment(val.createdDate).format("DD-MM-YYYY HH:mm:ss")
        // }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
        // {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        // {label: "word.updateAt", searchField: "lastModifiedDate", value: (val)=> {
        //   return moment(val.createdDate).format("DD-MM-YYYY HH:mm:ss")
        // }, type: 'func', isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
        // {label: "word.updateBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    ]

    customSearch=true
    searchColumn(){
        var columns = [
            { searchLabel: 'Kode Akun Pajak', searchField: 'kodeAkunPajak', type: 'string', searchAction: 'contains' },
            { searchLabel: 'Kode Jenis Setoran', searchField: 'kodeJenisSetoran', type: 'string', searchAction: 'contains' },
            { searchLabel: 'Nilai', searchField: 'nilai', type: 'string', searchAction: 'equals' },
            { searchLabel: 'Tanggal SSP (MM-DD-YYYY)', searchField: 'tglSsp', type: 'string', searchAction: 'equals' },
        ]
        return columns
    }

};

export default SSPIIView;