import ApiService from '../../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../../services/utilsService';

class SSPVService extends ApiService {
  customSearch=true
  name='SSPV'
  path='spt-1111-ssp-vs'
  baseUrl='spt-1111-ssp-vs'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  // replacePath = (params, apiMethod) => {
  //   if(apiMethod == 'delete' || apiMethod == 'findOne'){
  //     return `spt-1111/${params.npwp}/${params.tahun}/${params.masa}/${this.path}/${params.id}`;
  //   } else {
  //     return `spt-1111/${params.npwp}/${params.tahun}/${params.masa}/${this.path}`;
  //   }
  // }

  api = {
    save: async(data)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.npwp}/spt1111/ssp/validasi`, {
        params: {}
      });
      options.data = data;
      var res = await httpService.post(options)
      return res
    },
    delete: async(data)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.npwp}/spt1111/ssp/hapus`, {
        params: {}
      });
      options.data = data;
      var res = await httpService.post(options)
      return res
    },
    total: async(npwp, tahun, masa, pembetulan)=> {
      var params = {}
      let options = utilsService.generateGatewayOptions(`/spt-1111/${npwp}/${tahun}/${masa}/spt-1111-ssp-vs/sum`, {
        params: params
      });
      var res = await httpService.get(options)
      return res
    },
    uploadSSP: async(ids, npwp, tahun, masa)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${npwp}/${tahun}/${masa}/spt1111-ssp-vs-upload`);
      options.data = {
        type: 'CommonSearchUUID',
        ids: ids
      }
      var res = await httpService.post(options)
      return res
    },
  }
  
}

export default new SSPVService();
