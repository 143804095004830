const menuSpt = [
  {
    "label":{
      "id": "SPT Menu",
      "en": "SPT Menu"
    },
    "children":[
      {
        "label":{
          "id":"Kembali",
          "en":"Back"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-arrow-left",
        "path":"/main/spt",
        "childPath":[]
      },
      {
        "label":{
          "id":"Lampiran A1",
          "en":"Lampiran A1"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"a1",
        "childPath":[]
      },
      {
        "label":{
          "id":"Lampiran A2",
          "en":"Lampiran A2"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"a2",
        "childPath":[]
      },
      {
        "label":{
          "id":"Lampiran B1",
          "en":"Lampiran B1"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"b1",
        "childPath":[]
      },
      {
        "label":{
          "id":"Lampiran B2",
          "en":"Lampiran B2"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"b2",
        "childPath":[]
      },
      {
        "label":{
          "id":"Lampiran B3",
          "en":"Lampiran B3"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"b3",
        "childPath":[]
      },
      {
        "label":{
          "id":"Lampiran 1111 AB",
          "en":"Lampiran 1111 AB"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"ab",
        "childPath":[]
      },
      {
        "label":{
          "id":"Formulir Induk 1111",
          "en":"Formulir Induk 1111"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"induk",
        "childPath":[]
      },
      {
        "label":{
          "id":"PPN DDM",
          "en":"PPN DDM"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"uangdimuka",
        "childPath":[]
      },
      {
        "label":{
          "id":"SSP II",
          "en":"SSP II"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"spt-1111-ssp-iis",
        "childPath":[]
      },
      {
        "label":{
          "id":"SSP III",
          "en":"SSP III"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"spt-1111-ssp-iiis",
        "childPath":[]
      },
      {
        "label":{
          "id":"SSP IV",
          "en":"SSP IV"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"spt-1111-ssp-ivs",
        "childPath":[]
      },
      {
        "label":{
          "id":"SSP V",
          "en":"SSP V"
        },
        "resourceUri":"",
        "iconClassName":"mdi mdi-file",
        "path":"spt-1111-ssp-vs",
        "childPath":[]
      },
    ]
  }
]

export default menuSpt;