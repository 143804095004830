import React from 'react';
import FormView from '../../../components/Base/FormView';
import WpService from './Wp.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { Card, ExpansionList, ExpansionPanel, TextField, Button, DataTable, TableHeader, TableColumn, TableRow, TableBody, CardTitle } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection, Switch } from '../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';

@reduxForm({form: 'WpForm', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('WpForm')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class WpFormView extends FormView {
    service=WpService;
    viewType=2;

    static contextTypes = {
        showDialog: PropTypes.func,
    };
    
    async initEdit(){
        try {
            let res = await this.service.api.getOne(this.props.router.match.params.npwp);
            var data = {}
            data.content = res.data
            try {
                var signersTarra = res.data.signers.filter((d)=> {
                    return d.type == 'TARRA'
                })
                data.signers = signersTarra
                data.id = res.data.id
                if(data.signers){
                    if(data.signers.length > 0){
                        data.signers[0].sign_as = true
                        data.signers[1].sign_as = false
                    } else {
                        data.signers =  [
                            {
                                active: true,
                                npwp: this.props.router.match.params.npwp,
                                sign_as: true,
                                type: 'TARRA',
                                wp: {
                                    npwp: this.props.router.match.params.npwp
                                }
                            },
                            {
                                active: false,
                                npwp: this.props.router.match.params.npwp,
                                sign_as: false,
                                type: 'TARRA',
                                wp: {
                                    npwp: this.props.router.match.params.npwp
                                }
                            }
                        ]
                    }
                }
                delete data.content.signers
            } catch(e){}
            try {
                if(!data.signers[0]){
                    data.signers[0] = {
                        active: true,
                        npwp: this.props.router.match.params.npwp,
                        sign_as: true,
                        type: 'TARRA',
                        wp: {
                            npwp: this.props.router.match.params.npwp
                        },
                        name: ' ',
                        occupation: ' ',
                    }
                }
            } catch(e){}
            try {
                if(!data.signers[1]){
                    data.signers[1] = {
                        active: true,
                        npwp: this.props.router.match.params.npwp,
                        sign_as: true,
                        type: 'TARRA',
                        wp: {
                            npwp: this.props.router.match.params.npwp
                        },
                        name: ' ',
                        occupation: ' ',
                    }
                }
            } catch(e){}
            this.props.initialize(data);
        } catch(e){
            this.props.initialize(this.initialData)
            var message = ""
            if(e && e.message) message = e.message
            if(e && e.response && e.response.data && e.response.data.detail) message = e.response.data.detail
            // console.log(message)
            // izitoast.warning({
            //     title: "Validasi",
            //     message: "Tidak bisa membuka data WP dengan pesan " + message
            // })
            window.hahaha = e
            // this.props.initialize(this.initialData)
        }
    }

    async redirectTo(res){
        if(this.enableRedirect){
            var path = this.props.router.location.pathname
        var pathnext = path.split("/")[1]
        this.navService.redirectTo(`/${pathnext}/wp`)
        }
    }

    initialData = {
        content: {
            npwp: this.props.router.match.params.npwp
        },
        signers: [
            {
                active: true,
                npwp: this.props.router.match.params.npwp,
                sign_as: true,
                type: 'TARRA',
                wp: {
                    npwp: this.props.router.match.params.npwp
                },
            },
            {
                active: false,
                npwp: this.props.router.match.params.npwp,
                sign_as: false,
                type: 'TARRA',
                wp: {
                    npwp: this.props.router.match.params.npwp
                }
            }
        ]
    }

    async beforeSave(values){
        // console.log(values)
        // values.signers = []
        return values
    }

    async saveData(res, value){
        res = await this.service.api.saveManual(value)
    }

    async updateData(res, value){
        res = await this.service.api.updateManual(value)
    }

    formView() {
        var isWapu = false
        var isLampiran1 = false
        window.hahah = this
        if(this.props.formData){
            if(this.props.formData.content){
                var content = this.props.formData.content
                if(content.infoWajibPungut) isWapu = content.infoWajibPungut
                if(content.infoWapuJenisLampiran == '1') isLampiran1 = content.infoWapuJenisLampiran
            }
        }
        return (
            <div className="mpk-center-container">
                <Card>
                    <CardTitle subtitle="Wajib Pajak"></CardTitle>
                    <div className="md-grid">
                        <Field
                            label='NPWP'
                            name='content.npwp'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='Nama'
                            name='content.name'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Alias'
                            name='content.alias'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Kota'
                            name='content.infoCity'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='KLU'
                            name='content.infoKlu'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Alamat'
                            name='content.infoAddress'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Kode Pos'
                            name='content.infoPostal'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Mobile'
                            name='content.infoMobile'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Phone'
                            name='content.infoPhone'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Email'
                            name='content.infoEmail'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Fax'
                            name='content.infoFax'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Wajib PPnBM'
                            name='content.infoWajibPpnbm'
                            className="md-cell md-cell--6"
                            component={Switch}
                        />
                    </div>
                </Card>
                <br />
                <Card>
                    <CardTitle subtitle="Wajib Pungut"></CardTitle>
                    <div className="md-grid">
                        <Field
                            label='Wajib Pungut'
                            name='content.infoWajibPungut'
                            className="md-cell md-cell--12"
                            component={Switch}
                        />
                        {isWapu &&
                            <Field
                                label='Jenis Lampiran'
                                name='content.infoWapuJenisLampiran'
                                className="md-cell md-cell--12"
                                component={Searchfield}
                                options={[
                                    {id: '1', name: 'Lampiran 1'},
                                    {id: '2', name: 'Lampiran 2'}
                                ]}
                            />
                        }
                        {(isWapu && isLampiran1) &&
                            <Field
                                label='Jenis Transaksi'
                                name='content.infoWapuJenisTransaksi'
                                className="md-cell md-cell--12"
                                component={Searchfield}
                                options={[
                                    {id: '1', name: 'PPN dan PPnBM Yang Dipungut Melalui KPPN'},
                                    {id: '2', name: 'PPN dan PPn BM Yang Dipungut Oleh Bendahara Pengeluaran'}
                                ]}
                            />
                        }
                    </div>
                </Card>
                <br />
                <Card>
                    <CardTitle subtitle="Pimpinan"></CardTitle>
                    <div className="md-grid">
                        <Field
                            label='Nama'
                            name='content.infoNamaPemimpin'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Jabatan'
                            name='content.infoJabatanPemimpin'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                    </div>
                </Card>
                <br />
                <br />
                <Card>
                    <CardTitle subtitle="WP Penandatangan"></CardTitle>
                    <div className="md-grid">
                        <Field
                            label='Nama'
                            name='signers[0].name'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Jabatan'
                            name='signers[0].occupation'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Active'
                            name='signers[0].active'
                            className="md-cell md-cell--12"
                            component={Switch}
                            onChange={(e)=> {
                                if(e == false) {
                                    this.props.change('signers[1].active', true)
                                } else {
                                    this.props.change('signers[1].active', false)
                                }
                            }}
                        />
                    </div>
                </Card>
                <br />
                <Card>
                    <CardTitle subtitle="WP Kuasa"></CardTitle>
                    <div className="md-grid">
                        <Field
                            label='Nama'
                            name='signers[1].name'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Jabatan'
                            name='signers[1].occupation'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Active'
                            name='signers[1].active'
                            className="md-cell md-cell--12"
                            component={Switch}
                            onChange={(e)=> {
                                if(e == false){
                                    this.props.change('signers[0].active', true)
                                } else {
                                    this.props.change('signers[0].active', false)
                                }
                            }}
                        />
                    </div>
                </Card>
                <br />
            </div>
        )
    }

};

export default WpFormView;