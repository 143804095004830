/**
 * Created by dwiargo on 10/11/17.
 */

import axios from 'axios';
import superagent from 'superagent';
import oboe from 'oboe';
import fileSaver from 'file-saver';

let interceptors = null;

let errorHandler = null;

const httpService = {
  setInterceptors: (func) => {
    interceptors = func;
  },
  setErrorHandler: (func) => {
    errorHandler = func
  },
  get: async (parameter={}) => {
    let {url, onResolved, onRejected} = parameter;
    let config = parameter.config || {};
    config = interceptors ? (await interceptors(config)) : config;

    let params = parameter.params;
    if(params) url = httpService.utils.generateParams(url, params);
    return new Promise((resolve, reject) => {
      axios.get(url, config).then((response) => {
        if (onResolved) onResolved(response);
        resolve(response)
      }, (err) => {
        if (onRejected) onRejected(err);
        reject(errorHandler ? errorHandler(err) : err)
      })
    })
  },
  post: async (parameter={}) => {
    let {url, data, onResolved, onRejected} = parameter;
    let config = parameter.config || {};
    config = interceptors ? (await interceptors(config)) : config;

    let params = parameter.params;
    if(params) url = httpService.utils.generateParams(url, params);
    
    return new Promise((resolve, reject) => {
      axios.post(url, data, config).then(response => {
        if (onResolved) onResolved(response);
        resolve(response)
      }).catch((err) => {
        if (onRejected) onRejected(err);
        reject(errorHandler ? errorHandler(err) : err)
      })
    })
  },
  put: async (parameter={}) => {
    let {url, data, onResolved, onRejected} = parameter;
    let config = parameter.config || {};
    config = interceptors ? (await interceptors(config)) : config;
    
    return new Promise((resolve, reject) => {
      axios.put(url, data, config).then(response => {
        if (onResolved) onResolved(response);
        resolve(response)
      }).catch((err) => {
        if (onRejected) onRejected(err);
        reject(errorHandler ? errorHandler(err) : err)
      })
    })
  },
  put_superagent: async (parameter={}) => {
    let {url, data, onResolved, onRejected} = parameter;
    let config = parameter.config || {};
    config = interceptors ? (await interceptors(config)) : config;

    let params = parameter.params;
    if(params) url = httpService.utils.generateParams(url, params);

    return new Promise((resolve, reject) => {
      superagent
        .put(url)
        .send(data)
        .set('Authorization', config.headers ? config.headers.Authorization : '')
        .end((err, response) => {
          if (err) {
            if (onRejected) onRejected(err);
            reject(errorHandler ? errorHandler(err) : err);
          } else {
            response.data = response.body;
            if (onResolved) onResolved(response);
            resolve(response);
          }
        })
    })
  },
  delete: async (parameter={}) => {
    let {url, onResolved, onRejected} = parameter;
    let config = parameter.config || {};
    config = interceptors ? (await interceptors(config)) : config;

    let params = parameter.params;
    if(params) url = httpService.utils.generateParams(url, params);

    return new Promise((resolve, reject) => {
      axios.delete(url, config).then(response => {
        if (onResolved) onResolved(response);
        resolve(response);
      }).catch(err => {
        if (onRejected) onRejected(err);
        reject(errorHandler ? errorHandler(err) : err)
      })
    })
  },
  streamGet: async (parameter={}, onData = null, onEnd = null) => {
    let {url} = parameter;
    let config = parameter.config || {};
    config = interceptors ? (await interceptors(config)) : config;

    let params = parameter.params;
    if(params) url = httpService.utils.generateParams(url, params);

    config.url = url;
    return new Promise((resolve, reject) => {
      oboe(config)
        .start((status, headers) => {
          console.log(headers);
          if (status === 200) {
            resolve({
              status: status,
              headers: headers
            });
          } else {
            let err = {
              response: {
                status: status,
                headers: headers
              }
            };
            if(headers) 
              reject(errorHandler ? errorHandler(err) : err);
          }
        })
        .done(model => {
          if(model && Object.keys(model).length > 0) {
            onData(model);
          }
        })
        .fail(err => {
          // console.log(err);
          let error = {response: err}
          if(!err.response.headers){
            if(err.jsonBody) error.response.data = err.jsonBody;
            reject(errorHandler ? errorHandler(error) : error);
          }
        })
    })
  },
  download: async (parameter={}, method='get', fileName, fileType) => {
    let {url, data} = parameter;
    let config = parameter.config || {};
    return new Promise(async (resolve, reject) => {
      config.responseType = 'arraybuffer';
      try{
        let res = await ( method === 'post' ? httpService.post({url, data, config}) : httpService.get({url, config}));
        fileName = fileName || res.headers['content-disposition'].split('filename=')[1];
        let type = fileType || res.headers['content-type'];
        
        const blob = new Blob([res.data], {type: type})
        fileSaver.saveAs(blob, fileName);
        resolve();
      }catch(error){
        reject(error);
      }
    })
  },
  utils: {
    generateParams: (url="", params={}) => {
      const keys = Object.keys(params);
      let result = keys.length > 0 ? url + '?' : url;

      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = params[key];
        if (value && value !== undefined)
          result += (key + '=' + params[key] + '&');
      }

      return keys.length === 0 ? result : result.slice(0, -1);
    },
    fileToFormData: (file, meta = {}, fileLabel='file', fileName) => {
      const data = new FormData();
      data.append(fileLabel, file, fileName || file.name );
      const keys = Object.keys(meta);
      for(let i = 0 ; i < keys.length ; i++){
        const key = keys[i];
        data.append(key, meta[key]);
      }
      return data;
    },
    objectToFormData:function(obj, form, namespace) {

      var fd = form || new FormData();
      var formKey;

      for(var property in obj) {
        if(obj.hasOwnProperty(property)) {

          if(namespace) {
            formKey = namespace + '[' + property + ']';
          } else {
            formKey = property;
          }

          // if the property is an object, but not a File,
          // use recursivity.
          if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

            httpService.utils.objectToFormData(obj[property], fd, property);

          } else {

            // if it's a string or a File object
            fd.append(formKey, obj[property]);
          }

        }
      }

      return fd;
    },
    totalItemWillReceive: ( page, size, total) => {
      let tPage = total < size ? 1 : Math.ceil(total/size);
      let result = page === tPage ? (total - ((tPage-1)*size)) : size;
      return result;
    },
    parseErrorMessage: (error, localeCode) => {
      return error && error.message 
        ? typeof error.message === 'object' ? error.message[localeCode] : error.message
        : 'Something wrong'
    }
  },
  errorCallback:(callback, err) => {
    let message = err.message || err.statusText;
    callback(true, message);
  }
};

export default httpService; 