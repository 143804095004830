import React, { Component } from 'react';
import { Button, DialogContainer, TextField } from 'react-md';
import uuid from 'uuid';
import _ from 'lodash';
import { Field, reduxForm, getFormValues,  } from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

@reduxForm({form: 'global_dialog', initialValues:{}})
@connect((state) => ({
  formValue: getFormValues('global_dialog')(state),
  formError: state.form.global_dialog.syncErrors
}))
class DialogService extends Component {
  static childContextTypes = {
    showDialog: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      resolve: null,
      reject: null,
      initialValue: null
    };
  }

  _resolve = (result) => {
    const { resolve } = this.state;
    this.setState({dialog: null, resolve: null, reject: null, initialValue: null});
    if (resolve) {
      resolve(result);
    }
  };

  _reject = (reason) => {
    const { reject } = this.state;
    this.setState({dialog: null, resolve: null, reject: null, initialValue: null});
    if (reject) {
      reject(reason);
    }
  };

  submitForm = (props) => {
    props.resolve(this.props.formValue)
  }

  showDialog = (create) => {
    var opt = (create(this, this._resolve, this._reject));
    var dialogOpt = {
      ...opt
    }
    if(dialogOpt.text) delete dialogOpt.text;
    if(dialogOpt.initialValue) delete dialogOpt.initialValue;
    if(dialogOpt.okText) delete dialogOpt.okText;
    if(dialogOpt.cancelText) delete dialogOpt.cancelText;

    if(opt.initialValue) {
      this.props.initialize(_.cloneDeep(opt.initialValue))
    } else {
      this.props.initialize({})
    }

    const validateForm = function (props){
      props.resolve(_parent.props.formValue)
    }

    var _parent = this;
    var component = React.createElement(function(props) {
      return <DialogContainer
      id={'globalDialog'}
      style={{ padding: 0 }}
      visible={true}
      onHide={()=> {props.reject()}}
      actions={[{
        secondary: true,
        children: opt.okText ? opt.okText: 'Ok',
        onClick: ()=> {
          if(_parent.props.formError){
            _parent.props.handleSubmit(_parent.submitForm.bind(_parent, props))
          } else {
            validateForm(props)
          }
        }
      },{

        children: opt.cancelText ? opt.cancelText: 'Cancel',
        onClick: ()=> {props.resolve(false)}
      }]}
      title="Change something?"
      {...dialogOpt}
      >{opt.text}</DialogContainer>
    })

    const promise = new Promise((resolve, reject) => {
      this.setState({dialog: component, resolve, reject, initialValue: opt.initialValue});
    });
    return promise;
  };

  getChildContext() {
    return {
      showDialog: this.showDialog
    };
  }

  render() {
    const { dialog, resolve, reject } = this.state;
    const scrimClass = dialog ? 'scrim active' : 'scrim';
    let dialogComponent = null;
    if (dialog) {
      dialogComponent = React.cloneElement(dialog, {
        resolve: this._resolve.bind(this),
        reject: this._reject.bind(this)
      });
    }
    return (
      <div className='container' style={{width: '100%', height: '100%'}}>
        <div className={scrimClass}>
          {dialogComponent}
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default DialogService;