/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 12:17:25
 * @modify date 2018-01-17 12:17:25
 * @desc [description]
*/
import {combineReducers, Dispatch} from 'redux';
import http from './http.service'

export default class ApiService {
  http = http
	apiLocation = '';
  name = 'user';
  emptyFunc = () => {}
  reducer;


	combineReducers = combineReducers;
}
