import ApiService from '../../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../../services/utilsService';

class AB1111Service extends ApiService {
  name='AB1111'
  path='ab'
  baseUrl='ab'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    if(apiMethod == 'update'){
      return `spt-1111/${params.company.npwp}/${params.tahun}/${params.masa}/${params.pembetulan}/ab`
    } else {
      return this.path;
    }
  }

  api = {
    getAB1111: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/formulir-ab`);
      options.params = {
        masa:masa,
        tahun:tahun,
        revNo:pembetulan
      }
      var res = await httpService.get(options)

      var data = {
        ...res.data.data,
        company: {},
        section1: {},
        section2: {},
        section3: {},
      }

      data.company.name = res.data.data.attribute1;
      data.company.npwp = res.data.data.attribute2;
      data.masa = res.data.data.attribute3;
      data.masa2 = res.data.data.attribute4;
      data.tahun = res.data.data.attribute5;
      data.pembetulan = res.data.data.attribute6;
      data.attribute5 = res.data.data.attribute5;
      data.attribute6 = res.data.data.attribute6;
      // section 1
      data.section1.jmlIADPP = res.data.data.attribute7;
      data.section1.jmlIB1DPP = res.data.data.attribute8;
      data.section1.jmlIB1PPN = res.data.data.attribute9;
      data.section1.jmlIB1PPnBM = res.data.data.attribute10;
      data.section1.jmlIB2DPP = res.data.data.attribute11;
      data.section1.jmlIB2PPN = res.data.data.attribute12;
      data.section1.jmlIB2PPnBM = res.data.data.attribute13;
      data.section1.jmlIC1DPP = res.data.data.attribute14;
      data.attribute14P = res.data.data.attribute14P;
      data.section1.jmlIC1PPN = res.data.data.attribute15;
      data.attribute15P = res.data.data.attribute15P;
      data.section1.jmlIC1PPnBM = res.data.data.attribute16;
      data.attribute16P = res.data.data.attribute16P;
      data.section1.jmlIC2DPP = res.data.data.attribute17;
      data.section1.jmlIC2PPN = res.data.data.attribute18;
      data.section1.jmlIC2PPnBM = res.data.data.attribute19;
      data.section1.jmlIC3DPP = res.data.data.attribute20;
      data.section1.jmlIC3PPN = res.data.data.attribute21;
      data.section1.jmlIC3PPnBM = res.data.data.attribute22;
      data.section1.jmlIC4DPP = res.data.data.attribute23;
      data.section1.jmlIC4PPN = res.data.data.attribute24;
      data.section1.jmlIC4PPnBM = res.data.data.attribute25;
      data.section2.jmlIIADPP = res.data.data.attribute26;
      data.section2.jmlIIAPPN = res.data.data.attribute27;
      data.section2.jmlIIAPPnBM = res.data.data.attribute28;
      data.section2.jmlIIBDPP = res.data.data.attribute29;
      data.section2.jmlIIBPPN = res.data.data.attribute30;
      data.section2.jmlIIBPPnBM = res.data.data.attribute31;
      data.section2.jmlIICDPP = res.data.data.attribute32;
      data.section2.jmlIICPPN = res.data.data.attribute33;
      data.section2.jmlIICPPnBM = res.data.data.attribute34;
      data.section2.jmlIIDDPP = res.data.data.attribute35;
      data.section2.jmlIIDPPN = res.data.data.attribute36;
      data.section2.jmlIIDPPnBM = res.data.data.attribute37;
      // section 3
      data.section3.jmlIIIAPPN = res.data.data.attribute38;
      data.section3.jmlIIIB1PPN = res.data.data.attribute39;
      data.section3.jmlIIIB2Masa = res.data.data.attribute40;
      data.section3.jmlIIIB2Tahun = res.data.data.attribute41;
      data.section3.jmlIIIB2PPN = res.data.data.attribute42;
      data.section3.jmlIIIB3PPN = res.data.data.attribute43;
      data.section3.jmlIIIB4PPN = res.data.data.attribute44;
      data.section3.jmlIIICPPN = res.data.data.attribute45;
      return {
        data
      }
    },

    getKompensasi: async(npwp, masa, tahun)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/kompensasi`);
      options.params = {
        masa:masa,
        tahun:tahun
      }
      var res = await httpService.get(options)

      var data = null

      if (res.data.data) {
        data = [
          ...res.data.data
        ]
      }

      return {
        data
      }
    },

    save: async (data) => {
      var options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.company.npwp}/spt1111/formulir-ab?masa=${data.masa}&tahun=${data.tahun}&revNo=${data.pembetulan}`);
      options.params = {
        masa:data.masa,
        tahun:data.tahun,
        revNo:data.pembetulan
      }

      data.attribute7 = data.section1.jmlIADPP;
      data.attribute8 = data.section1.jmlIB1DPP;
      data.attribute9 = data.section1.jmlIB1PPN;
      data.attribute10 = data.section1.jmlIB1PPnBM;
      data.attribute11 = data.section1.jmlIB2DPP;
      data.attribute12 = data.section1.jmlIB2PPN;
      data.attribute13 = data.section1.jmlIB2PPnBM;
      data.attribute14 = data.section1.jmlIC1DPP;
      data.attribute14P = data.attribute14P;
      data.attribute15 = data.section1.jmlIC1PPN;
      data.attribute15P = data.attribute15P;
      data.attribute16 = data.section1.jmlIC1PPnBM;
      data.attribute16P = data.attribute16P;
      data.attribute17 = data.section1.jmlIC2DPP;
      data.attribute18 = data.section1.jmlIC2PPN;
      data.attribute19 = data.section1.jmlIC2PPnBM;
      data.attribute20 = data.section1.jmlIC3DPP;
      data.attribute21 = data.section1.jmlIC3PPN;
      data.attribute22 = data.section1.jmlIC3PPnBM;
      data.attribute23 = data.section1.jmlIC4DPP;
      data.attribute24 = data.section1.jmlIC4PPN;
      data.attribute25 = data.section1.jmlIC4PPnBM;
      data.attribute26 = data.section2.jmlIIADPP;
      data.attribute27 = data.section2.jmlIIAPPN;
      data.attribute28 = data.section2.jmlIIAPPnBM;
      data.attribute29 = data.section2.jmlIIBDPP;
      data.attribute30 = data.section2.jmlIIBPPN;
      data.attribute31 = data.section2.jmlIIBPPnBM;
      data.attribute32 = data.section2.jmlIICDPP;
      data.attribute33 = data.section2.jmlIICPPN;
      data.attribute34 = data.section2.jmlIICPPnBM;
      data.attribute35 = data.section2.jmlIIDDPP;
      data.attribute36 = data.section2.jmlIIDPPN;
      data.attribute37 = data.section2.jmlIIDPPnBM;
      // section 3
      data.attribute38 = data.section3.jmlIIIAPPN;
      data.attribute39 = data.section3.jmlIIIB1PPN;
      data.attribute40 = data.section3.jmlIIIB2Masa;
      data.attribute41 = data.section3.jmlIIIB2Tahun;
      data.attribute42 = data.section3.jmlIIIB2PPN;
      data.attribute43 = data.section3.jmlIIIB3PPN;
      data.attribute44 = data.section3.jmlIIIB4PPN;
      data.attribute45 = data.section3.jmlIIICPPN;

      options.data = data;
      
      var res = await httpService.put(options)
      return res;
    },
    report: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${sptId}/download-pdf`);
      options.data = {
        module: "Lampiran1111AB"
      }
      options.config.responseType="arraybuffer"
      options.config.dataType='blob'
      var res = await httpService.post(options)
      return res
    },

  }
  
}

export default new AB1111Service();
