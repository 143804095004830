/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Main from 'modules/Main/Main';
import Error from 'modules/Error/Error';
import Widget from 'modules/Widget/Widget';

export default () => (
  <Switch>
    <Route path="/" exact render={(props) => (
      <Redirect to="/main/spt"/>
    )}/>
    <Route path="/main" render={(props) => (
      <Main router={props}/>
    )}/>
    <Route path="/error" exact render={(props) => (
      <Error router={props}/>
    )}/>
    <Route path="/widget" render={(props) => (
      <Widget router={props}/>
    )}/>
  </Switch>
);