
import * as validationM from './validation';
import TextfieldM from './Textfield';
import TextfieldMaskM from './TextfieldMask';
import SearchfieldM from './Searchfield';
import Datepickerv2M from './Datepickerv2';
import CheckboxSelectionM from './CheckboxSelection';
import CheckboxM from './Checkbox';
import FileInputNextM from './FileInputNext';
import SwitchM from './Switch';

export const validation          = validationM;
export const Textfield           = TextfieldM;
export const TextfieldMask       = TextfieldMaskM;
export const Searchfield         = SearchfieldM;
export const Datepickerv2        = Datepickerv2M;
export const CheckboxSelection   = CheckboxSelectionM;
export const Checkbox            = CheckboxM;
export const FileInputNext       = FileInputNextM;
export const Switch              = SwitchM;
