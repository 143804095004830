import React from 'react';
import FormView from '../../../../components/Base/FormView';
import SSPIIIService from './SSPIII.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { ExpansionList, ExpansionPanel, TextField, Button, Card, DataTable, TableHeader, TableColumn, TableRow, TableBody } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection, Switch } from '../../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import SSPIIService from '../SSPII/SSPII.service';

@reduxForm({form: 'SSPIIIForm', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('SSPIIIForm')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class SSPIIIFormView extends FormView {
    service=SSPIIIService;
    viewType=2;

    initialData={
        company: null,
        pbk: false
    }

    async updateData(res, value){
      res = await this.service.api.update(value)
  }

  async saveData(res, value){
      var val = {}
      res = await this.service.api.save(value)
  }

    async beforeSave(val) { 
        val.npwp = this.props.router.match.params.npwp
        val.masaPajak = this.props.router.match.params.masa
        val.tahunPajak = this.props.router.match.params.tahun
        val.revNo = this.props.router.match.params.pembetulan
        val.idBayar = '1'
        if(val.fgNtpnPbk) {val.fgNtpnPbk = 1 } else {val.fgNtpnPbk = 0} 
        return val; 
    }

    async initEdit(){
        // let res = await this.service.api.findOne({
        //     id: this.props.router.match.params.id,
        //     npwp: this.props.router.match.params.npwp,
        //     masa: this.props.router.match.params.masa,
        //     tahun: this.props.router.match.params.tahun,
        //     pembetulan: this.props.router.match.params.pembetulan,
        // });
        // var companies = []
        // if(this.props.auth && this.props.auth.organizations) companies = this.props.auth.organizations
        // var getCompany = companies.filter((d)=> {
        //     return d.npwp == res.data.npwp
        // })
        // res.data.company = getCompany[0]
        this.props.initialize({});
    }

    formView() {
      var companies = []
      if(this.props.auth && this.props.auth.organizations) companies = this.props.auth.organizations
      var pbk = false
      if(this.props.formData && this.props.formData.fgNtpnPbk) pbk = this.props.formData.fgNtpnPbk
        return (
            <div>
                <Card>
                  <div className='md-grid'>
                    {/* <TextField 
                      label="Jenis Pembayaran"
                      value="PPN Kegiatan Membangun Sendiri"
                      className="md-cell md-cell--12"
                      disabled={true}
                    />
                    <Field
                      label='Kode Akun Pajak'
                      name='kodeAkunPajak'
                      className="md-cell md-cell--12"
                      component={Searchfield}
                      options={[
                        {id: '411211', name: 'PPN Dalam Negeri'}
                      ]}
                      validate={validation.required}
                    />
                    <Field
                      label='Kode Jenis Setoran'
                      name='kodeJenisSetoran'
                      className="md-cell md-cell--12"
                      component={Searchfield}
                      options={[
                        {id: '103', name: '103 - Setoran Kegiatan Membangun Sendiri'}
                      ]}
                      validate={validation.required}
                      rerender={true}
                    /> */}
                    <Field
                      label='PBK'
                      name='fgNtpnPbk'
                      className="md-cell md-cell--12"
                      component={Switch}
                    />
                    {pbk && 
                      <Field
                        label='NTPN PBK'
                        name='nomorPembayaran'
                        className="md-cell md-cell--12"
                        component={Textfield}
                        validate={validation.required}
                      />
                    }
                    {!pbk && 
                      <Field
                        label='NTPN'
                        name='nomorPembayaran'
                        className="md-cell md-cell--12"
                        component={Textfield}
                        validate={validation.required && validation.maxLength16}
                        mask="_"
                        length={16}
                        maxLength={16}
                        maskFormat="################"
                      />
                    }
                    <Field
                      label='Nilai'
                      name='jumlahPembayaran'
                      className="md-cell md-cell--12"
                      component={TextfieldMask}
                      money={true}
                      validate={validation.required}
                      normalize={(e)=> parseInt(e)}
                    />
                    {/* <Field
                      label='Tanggal SSP'
                      name='tglSsp'
                      className="md-cell md-cell--12"
                      component={Datepickerv2}
                      locales="id-ID"
                      validate={validation.required}
                      inline={true}
                    /> */}
                    
                  </div>
                </Card>
            </div>
        )
    }
};

export default SSPIIIFormView;