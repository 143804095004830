import ApiService from '../../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../../services/utilsService';

class A2Service extends ApiService {
  customSearch=true
  name='A2'
  path='a2'
  baseUrl='a2'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    return this.path;
  }

  api = {
    total: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${npwp}/${tahun}/${masa}/${pembetulan}/a2/sum`);
      var res = await httpService.get(options)
      return res
    },
    tarikData: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${npwp}/${tahun}/${masa}/${pembetulan}/a2/pull`);
      var res = await httpService.get(options)
      return res
    },
    report: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${sptId}/download-pdf`);
      options.data = {
        module: "Lampiran1111A2"
      }
      options.config.responseType="arraybuffer"
      options.config.dataType='blob'
      var res = await httpService.post(options)
      return res
    },
    dcsv: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${sptId}/download-csv`);
      options.data = {
        module: "A2"
      }
      options.config.responseType="arraybuffer"
      options.config.dataType='blob'
      var res = await httpService.post(options)
      return res
    }
  }
  
}

export default new A2Service();
