import React from 'react'
import { TextField } from 'react-md'

export default class TextFieldCustom extends React.Component {

  handleChange(value){
    value = value.replace(/</g, "")
    value = value.replace(/>/g, "")
    value = value.replace(/"/g, "")
    value = value.replace(/'/g, "")
    // value = value.replace(/&/g, " ")
    value = value.replace(/\\/g, "")

    // & menjadi &amp;
    // < menjadi &lt;
    // > menjadi &gt;
    // " menjadi  &quot;
    // ' menjadi &#x27;
    // / menjadi &#x2F;

    // value = value.replace(/"/g, "&quot;")
    // value = value.replace(/'/g, "&#039;");
    this.props.input.onChange(value)
  }

  render() {
    let { input, meta: { touched, error }, ...others } = this.props;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    if(this.props.meta.error === 'Required') others.label = this.props.label+' (*)'
    var type = "text"
    if(this.props.type) type = this.props.type
    return <TextField id={idProps} type={type} {...input} {...others} error={touched && !!error} errorText={error} onChange={this.handleChange.bind(this)}  />
  }
  
}
