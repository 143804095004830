
import React, { Component } from 'react';
import {Subheader, SelectField, DropdownMenu, TextField, Button, FontIcon, DialogContainer, List, ListItem } from 'react-md';
import _ from 'lodash';
import { Textfield } from '.';

export default class Searchfield extends Component {

  static defaultProps = {
    valueField: 'id',
    viewField: 'name',
    apiPath: 'find',
    remoteSearch: false,
    searchField: null,
    searchField2: null,
    itemTemplate: function(d) {
      return {
        primaryText: d[this.props.viewField],
        onClick: () => {
          if(!this.props.disabled){
            this.handleItemClick(d); this.hide()
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewValue: '',
      defData: [],
      menuItems: [],
      visible: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.rerender){
      this.buildMenuItems()
    }
    var curVal = this.props.input.value;
    var nextVal =  nextProps.input.value;
    if(typeof nextProps.input.value === 'object') {
      if(!curVal) curVal = {}
      if(curVal.id !== nextVal.id) {
        this.setState({viewValue: nextProps.input.value[this.props.viewField]})
      }
    } else {
      if(curVal !== nextVal) {
        if(this.props.options) {
          var options = this.props.options
          var option = options.filter((option)=>{
            return option.id == nextVal
          })
          var viewValue = option[0]
          var valueText = ""
          if(viewValue){
            valueText = viewValue.name
          }
          this.setState({ viewValue : valueText })
        } else {
          this.setState({ viewValue : nextVal })
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.options != prevProps.options){
      if(this.props.rerender){
        this.buildMenuItems()
      }
    }
  }

  async componentDidMount() {
    var value = this.props.input.value
    if(typeof value === 'object') {
      if(value.id) this.setState({viewValue: value[this.props.viewField]})
    } else {
      if(this.props.options) {
        var options = this.props.options
        var option = options.filter((option)=>{
          return option.id == value
        })
        var viewValue = option[0]
        var valueText = ""
        if(viewValue){
          valueText = viewValue.name
        }
        this.setState({ viewValue : valueText })
      }
      if(this.props.service){
        let apiParam = {}
        if(this.props.apiParam){
          apiParam = this.props.apiParam
        }
        let res = await this.props.service.api[this.props.apiPath](apiParam, ()=>{}, this.props.fetchOption);
        var options = res.data
        var option = options.filter((option)=>{
          return option.id == value
        })
        var viewValue = option[0]
        var valueText = ""
        if(viewValue){
          valueText = viewValue.name
        }
        this.setState({ viewValue : valueText })
      }
    }

    this.buildMenuItems()
  }

  handleItemClick(d) {
    var val = d[this.props.valueField];
    if(this.props.valueField == 'parent') val = d;
    this.props.input.value = val;
    this.props.input.onChange(val)
    this.setState({viewValue: d[this.props.viewField]})
  }

  async handleViewChange(val) {
    this.setState({viewValue: val});
    if(val.length > 0) {
      var searchField = this.props.viewField;
      if(this.props.searchField) searchField = this.props.searchField;
      var menuItems = [];

      if(!this.props.remoteSearch) {
        var filtered = _.filter(this.state.defData, (item) => {
          return item[searchField].toLowerCase().indexOf(val.toLowerCase()) > -1 || (this.props.searchField2 && item[this.props.searchField2] && item[this.props.searchField2].toLowerCase().indexOf(val.toLowerCase()) > -1)
        })
        menuItems = filtered.map(this.props.itemTemplate.bind(this))
      } else {
        var filter = val
        // let res = await this.props.service.api[this.props.apiPath](filter, ()=>{}, this.props.fetchOption);
        let apiParam = {}
        if(this.props.apiParam){
          apiParam = this.props.apiParam
        }
        let res = await this.props.service.api[this.props.apiPath](apiParam, filter, this.props.fetchOption);
        menuItems = res.data.map(this.props.itemTemplate.bind(this))
      }
      this.setState({menuItems})
    } else {
      menuItems = this.state.defData.map(this.props.itemTemplate.bind(this))
      this.setState({menuItems})
    }
  }

  async buildMenuItems() {
    var menuItems = []
    var defData = []
    if(this.props.service) {
      let apiParam = {}
      if(this.props.apiParam){
        apiParam = this.props.apiParam
      }
      let res = await this.props.service.api[this.props.apiPath](apiParam, ()=>{}, this.props.fetchOption);
      defData = res.data;
      if(this.props.emptyVal){
        if(defData.length > 0){
          var emptyObj = Object.assign({}, defData[0])
          var keys = Object.keys(emptyObj)
          keys.map((d)=> {
            if(d == 'id'){
              emptyObj[d] = null
            } else {
              emptyObj[d] = ' '
            }
          })
          defData.unshift(emptyObj)
        }
      }
      menuItems = res.data.map(this.props.itemTemplate.bind(this))
    } else if(this.props.options) {
      defData = this.props.options;
      menuItems = this.props.options.map(this.props.itemTemplate.bind(this))
    }

    this.setState({
      menuItems,
      defData
    })
    return menuItems;
  }

  hide = () => {
    this.setState({ visible: false });
  };


  render() {
    let input = this.props.input;
    let meta = this.props.meta;
    if(!meta) meta = {}
    let optInput = {...input}
    let disabled = this.props.disabled;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;

    var menuItems = this.state.menuItems;
    if(disabled) menuItems = []

    var label = this.props.label;
    if(this.props.meta.error === "Required") label = this.props.label+' (*)'
    
    // if(menuItems.length > 0){
    //   var getObj = Object.assign({}, menuItems[0])
    //   var keys = Object.keys(getObj)
    //   keys.map((d)=> { getObj[d] = null })
    //   menuItems.unshift(getObj)
    // }

    if(this.props.viewType == 'dialog') {
      return <div className={this.props.className}
      style={{
        display: 'block',
        ...this.props.style
      }}>
        <DialogContainer
          onHide={this.hide}
          id={this.props.id+"_menu"}
          visible={this.state.visible}
          title={this.props.label}
          width={this.props.menuWidth}
        >
          {!disabled &&
            <List>
              <ListItem primaryText='' />
              {this.state.menuItems.map((d, i)=> {
                return <ListItem key={i} {...d} />
              })}
            </List>
          }
        </DialogContainer>
        <TextField
          id={idProps}
          onChange={(val) => this.handleViewChange(val)}
          value={this.state.viewValue}
          label={label}
          disabled={disabled}
          onFocus={()=> this.setState({visible: true})}
          inlineIndicator={<Button icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
          placeholder={this.props.placeholder}
          error={meta.touched && !!meta.error} errorText={meta.error}
        />

      </div>
    } else if (this.props.onlyNumber) {
      if(disabled){
        return <TextField
          style={{marginTop:'-3px'}}
          className={this.props.className}
          id={idProps}
          onChange={(val) => this.handleViewChange(val)}
          value={this.state.viewValue}
          label={label}
          disabled={disabled}
          placeholder={this.props.placeholder}
          error={meta.touched && !!meta.error} errorText={meta.error}
        />
      } else {
        return <DropdownMenu
          id={this.props.id+"_menu"}
          menuItems={menuItems}
          toggleQuery=".md-text-field-container"
          sameWidth
          className={this.props.className}
          style={{
            display: 'block',
            ...this.props.style
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          anchor={{
            x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
            y: DropdownMenu.VerticalAnchors.BOTTOM,
          }}
          position={DropdownMenu.Positions.BELOW}
          {...this.props.dropdownProps}
        >
          <div className="md-grid">
            <TextField
              style={{
                width: '100%',
                marginTop: '-8px'
              }}
              id={idProps}
              onChange={(val) => this.handleViewChange(val)}
              value={this.state.viewValue}
              className={this.props.className}
              label={label}
              disabled={disabled}
              inlineIndicator={<Button style={{
                position: 'absolute',
                marginTop: '-15px'
              }} icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
              placeholder={this.props.placeholder}
              error={meta.touched && !!meta.error} errorText={meta.error}
            />
          </div>
        </DropdownMenu>
      }
    } else {
      if(disabled){
        return <TextField
          className={this.props.className}
          id={idProps}
          onChange={(val) => this.handleViewChange(val)}
          value={this.state.viewValue}
          label={label}
          disabled={disabled}
          placeholder={this.props.placeholder}
          error={meta.touched && !!meta.error} errorText={meta.error}
        />
      } else {
        return <DropdownMenu
          id={this.props.id+"_menu"}
          menuItems={menuItems}
          toggleQuery=".md-text-field-container"
          sameWidth
          className={this.props.className}
          style={{
            display: 'block',
            ...this.props.style
          }}
          anchor={{
            x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
            y: DropdownMenu.VerticalAnchors.BOTTOM,
          }}
          position={DropdownMenu.Positions.BELOW}
          {...this.props.dropdownProps}
        >
          <div className="md-grid">
            <TextField
              style={{
                width: '100%',
                marginTop: '-8px'
              }}
              id={idProps}
              onChange={(val) => this.handleViewChange(val)}
              value={this.state.viewValue}
              className={this.props.className}
              label={label}
              disabled={disabled}
              inlineIndicator={<Button style={{
                position: 'absolute',
                marginTop: '-15px'
              }} icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
              placeholder={this.props.placeholder}
              error={meta.touched && !!meta.error} errorText={meta.error}
            />
          </div>
        </DropdownMenu>
      }
    }
  }
}

