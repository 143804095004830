import React from 'react';
import { SelectionControl, Checkbox, Switch } from 'react-md';

export default class CheckboxCustom extends React.Component {
  check(value){
    this.props.input.onChange(value)
  }
  render() {
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    let { input, meta: { touched, error }, ...others } = this.props;
    let value = this.props.input.value;
    if(!value) value = false;
    if(value == '') value = false;

    var errorText = ''
    if(touched && !!error){
      errorText = error
    }

    return (
      <div className={this.props.className}>
        <Checkbox defaultChecked={false} id={idProps} checked={value} {...input} {...others} onChange={this.check.bind(this)} style={{width: '100%'}} />
        <p className="md-text--error">{errorText}</p>
      </div>
    )
  }
}