import ApiService from '../../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../../services/utilsService';

class B2Service extends ApiService {
  customSearch=true
  name='B2'
  path='b2'
  baseUrl='b2'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    return this.path;
  }

  api = {
    total: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${npwp}/${tahun}/${masa}/${pembetulan}/b2/sum`);
      var res = await httpService.get(options)
      return res
    },
    tarikData: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${npwp}/${tahun}/${masa}/${pembetulan}/b2/pull`);
      var res = await httpService.get(options)
      return res
    },
    report: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${sptId}/download-pdf`);
      options.data = {
        module: "Lampiran1111B2"
      }
      options.config.responseType="arraybuffer"
      options.config.dataType='blob'
      var res = await httpService.post(options)
      return res
    },
    dcsv: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${sptId}/download-csv`);
      options.data = {
        module: "B2"
      }
      options.config.responseType="arraybuffer"
      options.config.dataType='blob'
      var res = await httpService.post(options)
      return res
    }
  }
  
}

export default new B2Service();
