import React from 'react';
import { TabsContainer, Tabs, Tab, DialogContainer, Card, Divider } from 'react-md';
import ListView from '../../../../components/Base/ListView';
import A1Service from './A1.service';
import { connect } from 'react-redux';
import moment from 'moment';
import { SearchField } from '../../../../libs/react-mpk';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validation, Searchfield, Textfield, TextfieldMask } from '../../../../components/Form';
import SptService from './../../Spt/Spt.service';
import izitoast from 'izitoast';
import download from 'downloadjs'
import axios from 'axios'

@reduxForm({form: 'SPT1111_A1', destroyOnUnmount: true})
@connect((state)=> ({
    ...A1Service.stateConnectSetting()(state)
}), A1Service.actionConnectSetting())
class A1View extends ListView {
    service = A1Service;
    plainTable(){ return true; }
    headerData = {jmlRecord: 0}

    fetchOption() {
        var params = this.props.router.match.params
        let path =  `tarra-efaktur/${params.npwp}/spt1111/formulir-a1a2b1b2b3/detail`
        return {
            path: path
        }
    }

    async beforeFetch(params){
        
        var p = this.props.router.match.params
        params.kdForm= "A1"
        params.masa=p.masa
        params.tahun=p.tahun
        params.revNo=p.pembetulan
        params.pageNum = params.page?params.page+1:1
        params.pageSize = params.size?params.size:15

        var header = await this.service.api.header(p.npwp, params)
        this.headerData = header.data?header.data.data:{jmlRecord:0}

        this.props.change('dpp', header.data.data.jmlDpp)
    }

    overideTotal(res){
        return parseInt(this.headerData.jmlRecord)
      }
  
      overideData(res){
        return res.data?res.data.data:[]
      }


    footer(){
        return (
            // <Card style={{margin: '0px 15px 0px', padding: 15}}>
            <div style={{width: '100%'}}>
                <Divider />
                <div className="md-grid" style={{padding: 0, width: '100%'}}>
                    {/* <Field 
                        label="A1 Status"
                        component={Textfield}
                        name="lampiranInfo.a1Status"
                        className="md-cell md-cell--4" 
                    />
                    <Field 
                        label="A1 Status Message"
                        component={Textfield}
                        name="lampiranInfo.a1StatusMsg"
                        className="md-cell md-cell--4" 
                    /> */}
                    <Field 
                        label="Total DPP"
                        money={true}
                        normalize={(e)=> parseInt(e)}
                        component={TextfieldMask}
                        name="dpp"
                        className="md-cell md-cell--4" 
                    />
                </div>
            </div>
            // </Card>
        )
    }

    _tableActions = []

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    async tarikData(){
        var res = await this.context.showDialog(()=> ({
            title: null,
            initialValue: {},
            width: 800,
            height: 150,
            contentStyle: {
              height: 150
            },
            text: (
              <div>
               <p> Apa anda yakin akan menarik Lampiran SPT ?</p>
              </div>
            )
        }))
        if(res){
            try {
                var params = this.props.router.match.params
                let res = await this.service.api.tarikData(params.npwp, params.tahun, params.masa, params.pembetulan);
                this.fetchData()
            } catch(e){
                izitoast.error({
                    title: "Error",
                    message: "Update Status Lampiran gagal dengan pesan : " + e.message
                })
            }
        }
    }

    async handleReport(){
        try {
            var p = this.props.router.match.params
            izitoast.info({
                title: "Print Report",
                message: "mohon ditunggu, sedang dalam proses."
            })
            var res = await this.service.api.reportDownload(p.npwp, {
                reports: [
                  {
                    data: {
                      type: "Spt1111-A1",
                      npwp: p.npwp,
                      masa: p.masa,
                      tahun: p.tahun,
                      pembetulan: p.pembetulan,
                    },
                    invoice: {},
                    mpk: {
                      signature: "78lRwyNhG4u3CLxibFSt26xme+pXgbXFZwlb36zdkaI=",
                    },
                  },
                ],
                config: {
                  skipInvalidSignature: false,
                  skipInvalidType: false,
                },
              });
            var filename = res.headers["content-disposition"].split('=')[1].replace(/"/g,'');
            download(res.data, filename)
            izitoast.success({
                title: "Print Report",
                message: "proses generate laporan berhasil dicetak."
            })
        } catch(e){
            try {
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.response.data.message}`
                })
            } catch(e){
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.message}`
                })
            }
        }
    }

    async handleExport(){
        try {
            
            var p = this.props.router.match.params
            var params = {kdForm: "A1", masa:p.masa, tahun: p.tahun, revNo: p.pembetulan }
            var res = await this.service.api.csvDownload(p.npwp, params)
            // console.log(res, res.headers, 'res.headers')
            var filename = res.headers["content-disposition"].split('=')[1].replace(/"/g,'');
            
            download(res.data, filename)
            izitoast.success({
                title: "Print Report",
                message: "proses generate laporan berhasil dicetak."
            })
        } catch(e){
            console.log(e)
            try {
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.response.data.detail}`
                })
            } catch(e){
                if(e){
                    izitoast.error({
                        title: "Gagal",
                        message: `Proses gagal dengan pesan ${e.message}`
                    })
                }
            }
        }
    }

    _barActions = [
        {
          label:'word.back',
          iconClassName:'mdi mdi-chevron-left',
          onClick:() => window.history.back()
        },
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => this.fetchData()
        },
        // {
        //   label:'word.tarikData',
        //   iconClassName:'mdi mdi-import',
        //   onClick:() => this.tarikData()
        // },
        {
          label:'word.report',
          iconClassName:'mdi mdi-file-pdf',
          onClick:() => this.handleReport()
        },
        {
          label:'word.export',
          iconClassName:'mdi mdi-file-document',
          onClick:() => this.handleExport()
        }
    ]
    
    columns=[
        {label: "word.companyName", searchField: "namaLt", value: "namaLt", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.companyNpwp", searchField: "npwpPasporLt", value: "npwpPasporLt", isDefaultSort:false, isSortable:false, show:true, isSearchable:false},
        {label: "word.nomorDokumen", searchField: "nomor", value: "nomor", isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
        {label: "word.tanggalDokumen", searchField: "tanggal", value: "tanggal", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        // {label: "word.bkpJkp", searchField: "bkpJkp", value: "bkpJkp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.dpp", searchField: "jmlDpp", value: "jmlDpp", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.keterangan", searchField: "ket", value: "ket", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    ]

    customSearch=true
    searchColumn(){
        var columns = [
            { searchLabel: 'Nomor Dokumen', searchField: 'nomorDokumen', type: 'string', searchAction: 'contains' },
            { searchLabel: 'BKPJKP', searchField: 'bkpJkp', type: 'string', searchAction: 'contains' },
        ]
        return columns
    }

};

export default A1View;