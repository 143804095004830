import ApiService from '../../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../../services/utilsService';

class Induk1111Service extends ApiService {
  name='Induk1111'
  path='induk'
  baseUrl='induk'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    if(apiMethod == 'update'){
      return `spt-1111/${params.company.npwp}/${params.tahun}/${params.masa}/${params.pembetulan}/induk`
    } else {
      return this.path;
    }
  }

  api = {
    getInduk1111New: async(npwp, tahun, masa, pembetulan, totalPpnDdm)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/formulir-induk`);
      options.params = {
        masa:masa,
        tahun:tahun,
        revNo:pembetulan?pembetulan:'0'
      }
      var res = await httpService.get(options)

      var data = {
        ...res.data.data,
        indukEfaktur: {},
        company: {},
        section1: {},
        section2Ag: {},
        section2h: {},
        section3: {},
        section4: {},
        section5: {},
        section6: {}
      }

      data.company.name = res.data.data.attribute1;
      data.company.address = res.data.data.attribute2;
      data.company.npwp = res.data.data.attribute6;
      data.masa = res.data.data.attribute7;
      data.masa2 = res.data.data.attribute8;
      data.tahun = res.data.data.attribute9;
      data.pembetulan = res.data.data.attribute12;
      //  indukEfaktur?.attribute1 = res.data.data.attribute1 ;
      //        indukEfaktur?.attribute2 = res.data.data.attribute2 ;
      //        indukEfaktur?.attribute3 = res.data.data.attribute3 ;
      //        indukEfaktur?.attribute4 = res.data.data.attribute4 ;
      //        indukEfaktur?.attribute5 = res.data.data.attribute5 ;
      //        indukEfaktur?.attribute6 = res.data.data.attribute6 ;
      //        indukEfaktur?.attribute7 = res.data.data.attribute7 ;
      //        indukEfaktur?.attribute8 = res.data.data.attribute8 ;
      //        indukEfaktur?.attribute9 = res.data.data.attribute9 ;
      //        indukEfaktur?.attribute10 = res.data.data.attribute10;
      //        indukEfaktur?.attribute11 = res.data.data.attribute11;
      //        logEfaktur.pembetulan = res.data.data.attribute12;
      //        NtoW.convert(logEfaktur.pembetulan?.toLong()!!) = res.data.data.attribute13;
      //        wpInfo.infoWajibPpnbm?.let { if (it) 1 else 0 } ?: 0 = res.data.data.attribute14;
      data.section1.jmlIA1DPP = res.data.data.attribute15;
      data.section1.jmlIA2DPP = res.data.data.attribute16;
      data.section1.jmlIA2PPN = res.data.data.attribute17;
      data.section1.jmlIA3DPP = res.data.data.attribute18;
      data.section1.jmlIA3PPN = res.data.data.attribute19;
      data.section1.jmlIA4DPP = res.data.data.attribute20;
      data.section1.jmlIA4PPN = res.data.data.attribute21;
      data.section1.jmlIA5DPP = res.data.data.attribute22;
      data.section1.jmlIA5PPN = res.data.data.attribute23;
      data.section1.jmlIATotDPP = res.data.data.attribute24;
      data.section1.jmlIATotPPN = res.data.data.attribute25;
      data.section1.jmlIBDPP = res.data.data.attribute26;
      data.section1.jmlICDPP = res.data.data.attribute27;
            // section 2G
      data.section2Ag.jmlIIAPPN = res.data.data.attribute28;
      data.section2Ag.jmlIIBPPN = res.data.data.attribute29;
      data.section2Ag.jmlIICPPN = res.data.data.attribute30;
      data.section2Ag.jmlIIDPPN = res.data.data.attribute31;
      data.section2Ag.jmlIIEPPN = res.data.data.attribute32;
      data.section2Ag.jmlIIFPPN = res.data.data.attribute33;
      data.section2Ag.tglKrgByrII = res.data.data.attribute34;
      data.section2Ag.ntpnII = res.data.data.attribute35;

      // UPDATE PPN DDM
      if (totalPpnDdm > 0) {
        data.section2Ag.jmlIIBPPN = totalPpnDdm;
        if(pembetulan > 0) {
          data.section2Ag.jmlIIDPPN = res.data.data.attribute28 - totalPpnDdm - res.data.data.attribute30
          data.section2Ag.jmlIIFPPN = res.data.data.attribute31 - res.data.data.attribute32
        } else {
          data.section2Ag.jmlIIDPPN = res.data.data.attribute28 - totalPpnDdm - res.data.data.attribute30
        }
      }
      // UPDATE PPN DDM

      // GET CHKNKLII
      if(data.pembetulan === 0) {
        if(data.section2Ag.jmlIIDPPN > 0) {
          // Kurang Bayar
          data.section2Ag.chkNKLII = 1
        } else if(data.section2Ag.jmlIIDPPN < 0) {
          // Lebih Bayar
          data.section2Ag.chkNKLII = 2
        } else {
          // Nihil
          data.section2Ag.chkNKLII = 0
        }
      } else {
        if(data.section2Ag.jmlIIFPPN > 0) {
          // Kurang Bayar
          data.section2Ag.chkNKLII = 1
        } else if(data.section2Ag.jmlIIFPPN < 0) {
          // Lebih Bayar
          data.section2Ag.chkNKLII = 2
        } else {
          // Nihil
          data.section2Ag.chkNKLII = 0
        }
      }
            // section 2H
      data.section2h.chkLB1 = res.data.data.attribute36;
      data.section2h.chkLB2 = res.data.data.attribute37;
      data.section2h.chkLB3 = res.data.data.attribute38;
      data.section2h.chkLB4 = res.data.data.attribute39;
      data.section2h.chkLB5 = res.data.data.attribute40;
      data.section2h.chkLB6 = res.data.data.attribute41;
      data.section2h.chkLB7 = res.data.data.attribute42;
      data.section2h.masaPajakLb = res.data.data.attribute43;
      data.section2h.tahunPajakLb = res.data.data.attribute44;
      data.section2h.chkLB8 = res.data.data.attribute45;
      data.section2h.chkLB9 = res.data.data.attribute46;
      data.section2h.chkLB10 = res.data.data.attribute47;
      data.section2h.chkLB11 = res.data.data.attribute48;
      data.section2h.chkLB12 = res.data.data.attribute49;
      data.section2h.chkLB13 = res.data.data.attribute50;
      data.section2h.chkLB14 = res.data.data.attribute51;
      data.section2h.chkLB15 = res.data.data.attribute52;
            // section 3
      data.section3.jmlIIIA = res.data.data.attribute53;
      data.section3.jmlIIIB = res.data.data.attribute54;
      data.section3.tglKrgByrIII = res.data.data.attribute55;
      data.section3.ntpnIII = res.data.data.attribute56;
            // section 4
      data.section4.jmlIVA = res.data.data.attribute57;
      data.section4.tglKrgByrIV = res.data.data.attribute58;
      data.section4.ntpnIV = res.data.data.attribute59;
            // section 5
      data.section5.jmlVAPPN = res.data.data.attribute60;
      data.section5.jmlVBPPN = res.data.data.attribute61;
      data.section5.jmlVCPPN = res.data.data.attribute62;
      data.section5.jmlVDPPN = res.data.data.attribute63;
      data.section5.jmlVEPPN = res.data.data.attribute64;
      data.section5.tglKrgByrV = res.data.data.attribute65;
      data.section5.ntpnV = res.data.data.attribute66;
            // section 6
      data.section6.chkLamp1 = res.data.data.attribute67;
      data.section6.chkLamp2 = res.data.data.attribute68;
      data.section6.chkLamp3 = res.data.data.attribute69;
      data.section6.chkLamp4 = res.data.data.attribute70;
      data.section6.chkLamp5 = res.data.data.attribute71;
      data.section6.chkLamp6 = res.data.data.attribute72;
      data.section6.chkLamp7 = res.data.data.attribute73;
      data.section6.chkLamp8 = res.data.data.attribute74;
      data.section6.jmlLembar7 = res.data.data.attribute75;
      data.section6.jmlLembar8 = res.data.data.attribute76;
      data.section6.chkLamp9 = res.data.data.attribute77;
      data.section6.chkLamp10 = res.data.data.attribute78;
      data.section6.nmLamp10 = res.data.data.attribute79;
      data.section6.jmlLembar10 = res.data.data.attribute80;
      data.section6.kotaLapor = res.data.data.attribute81;
      data.section6.tglSPT = res.data.data.attribute82;
      data.section6.chkPKP = res.data.data.attribute83;
      data.section6.chkKuasa = res.data.data.attribute84;
      data.section6.namaPenandatangan = res.data.data.attribute86;
      data.section6.jabatanPenandatangan = res.data.data.attribute87;


      // console.log(data, 'data')
      return {
        data
      }
    },

    getInduk1111: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/formulir-induk`);
      options.params = {
        masa:masa,
        tahun:tahun,
        revNo:pembetulan?pembetulan:'0'
      }
      var res = await httpService.get(options)

      var data = {
        ...res.data.data,
        indukEfaktur: {},
        company: {},
        section1: {},
        section2Ag: {},
        section2h: {},
        section3: {},
        section4: {},
        section5: {},
        section6: {}
      }

      data.company.name = res.data.data.attribute1;
      data.company.npwp = res.data.data.attribute6;
      data.masa = res.data.data.attribute7;
      data.masa2 = res.data.data.attribute8;
      data.tahun = res.data.data.attribute9;
      data.pembetulan = res.data.data.attribute12;
      //  indukEfaktur?.attribute1 = res.data.data.attribute1 ;
      //        indukEfaktur?.attribute2 = res.data.data.attribute2 ;
      //        indukEfaktur?.attribute3 = res.data.data.attribute3 ;
      //        indukEfaktur?.attribute4 = res.data.data.attribute4 ;
      //        indukEfaktur?.attribute5 = res.data.data.attribute5 ;
      //        indukEfaktur?.attribute6 = res.data.data.attribute6 ;
      //        indukEfaktur?.attribute7 = res.data.data.attribute7 ;
      //        indukEfaktur?.attribute8 = res.data.data.attribute8 ;
      //        indukEfaktur?.attribute9 = res.data.data.attribute9 ;
      //        indukEfaktur?.attribute10 = res.data.data.attribute10;
      //        indukEfaktur?.attribute11 = res.data.data.attribute11;
      //        logEfaktur.pembetulan = res.data.data.attribute12;
      //        NtoW.convert(logEfaktur.pembetulan?.toLong()!!) = res.data.data.attribute13;
      //        wpInfo.infoWajibPpnbm?.let { if (it) 1 else 0 } ?: 0 = res.data.data.attribute14;
      data.section1.jmlIA1DPP = res.data.data.attribute15;
      data.section1.jmlIA2DPP = res.data.data.attribute16;
      data.section1.jmlIA2PPN = res.data.data.attribute17;
      data.section1.jmlIA3DPP = res.data.data.attribute18;
      data.section1.jmlIA3PPN = res.data.data.attribute19;
      data.section1.jmlIA4DPP = res.data.data.attribute20;
      data.section1.jmlIA4PPN = res.data.data.attribute21;
      data.section1.jmlIA5DPP = res.data.data.attribute22;
      data.section1.jmlIA5PPN = res.data.data.attribute23;
      data.section1.jmlIATotDPP = res.data.data.attribute24;
      data.section1.jmlIATotPPN = res.data.data.attribute25;
      data.section1.jmlIBDPP = res.data.data.attribute26;
      data.section1.jmlICDPP = res.data.data.attribute27;
            // section 2G
      data.section2Ag.jmlIIAPPN = res.data.data.attribute28;
      data.section2Ag.jmlIIBPPN = res.data.data.attribute29;
      data.section2Ag.jmlIICPPN = res.data.data.attribute30;
      data.section2Ag.jmlIIDPPN = res.data.data.attribute31;
      data.section2Ag.jmlIIEPPN = res.data.data.attribute32;
      data.section2Ag.jmlIIFPPN = res.data.data.attribute33;
      data.section2Ag.tglKrgByrII = res.data.data.attribute34;
      data.section2Ag.ntpnII = res.data.data.attribute35;
      // GET CHKNKLII
      if(data.pembetulan === 0) {
        if(res.data.data.attribute31 > 0) {
          // Kurang Bayar
          data.section2Ag.chkNKLII = 1
        } else if(res.data.data.attribute31 < 0) {
          // Lebih Bayar
          data.section2Ag.chkNKLII = 2
        } else {
          // Nihil
          data.section2Ag.chkNKLII = 0
        }
      } else {
        if(res.data.data.attribute33 > 0) {
          // Kurang Bayar
          data.section2Ag.chkNKLII = 1
        } else if(res.data.data.attribute33 < 0) {
          // Lebih Bayar
          data.section2Ag.chkNKLII = 2
        } else {
          // Nihil
          data.section2Ag.chkNKLII = 0
        }
      }
            // section 2H
      data.section2h.chkLB1 = res.data.data.attribute36;
      data.section2h.chkLB2 = res.data.data.attribute37;
      data.section2h.chkLB3 = res.data.data.attribute38;
      data.section2h.chkLB4 = res.data.data.attribute39;
      data.section2h.chkLB5 = res.data.data.attribute40;
      data.section2h.chkLB6 = res.data.data.attribute41;
      data.section2h.chkLB7 = res.data.data.attribute42;
      data.section2h.masaPajakLb = res.data.data.attribute43;
      data.section2h.tahunPajakLb = res.data.data.attribute44;
      data.section2h.chkLB8 = res.data.data.attribute45;
      data.section2h.chkLB9 = res.data.data.attribute46;
      data.section2h.chkLB10 = res.data.data.attribute47;
      data.section2h.chkLB11 = res.data.data.attribute48;
      data.section2h.chkLB12 = res.data.data.attribute49;
      data.section2h.chkLB13 = res.data.data.attribute50;
      data.section2h.chkLB14 = res.data.data.attribute51;
      data.section2h.chkLB15 = res.data.data.attribute52;
            // section 3
      data.section3.jmlIIIA = res.data.data.attribute53;
      data.section3.jmlIIIB = res.data.data.attribute54;
      data.section3.tglKrgByrIII = res.data.data.attribute55;
      data.section3.ntpnIII = res.data.data.attribute56;
            // section 4
      data.section4.jmlIVA = res.data.data.attribute57;
      data.section4.tglKrgByrIV = res.data.data.attribute58;
      data.section4.ntpnIV = res.data.data.attribute59;
            // section 5
      data.section5.jmlVAPPN = res.data.data.attribute60;
      data.section5.jmlVBPPN = res.data.data.attribute61;
      data.section5.jmlVCPPN = res.data.data.attribute62;
      data.section5.jmlVDPPN = res.data.data.attribute63;
      data.section5.jmlVEPPN = res.data.data.attribute64;
      data.section5.tglKrgByrV = res.data.data.attribute65;
      data.section5.ntpnV = res.data.data.attribute66;
            // section 6
      data.section6.chkLamp1 = res.data.data.attribute67;
      data.section6.chkLamp2 = res.data.data.attribute68;
      data.section6.chkLamp3 = res.data.data.attribute69;
      data.section6.chkLamp4 = res.data.data.attribute70;
      data.section6.chkLamp5 = res.data.data.attribute71;
      data.section6.chkLamp6 = res.data.data.attribute72;
      data.section6.chkLamp7 = res.data.data.attribute73;
      data.section6.chkLamp8 = res.data.data.attribute74;
      data.section6.jmlLembar7 = res.data.data.attribute75;
      data.section6.jmlLembar8 = res.data.data.attribute76;
      data.section6.chkLamp9 = res.data.data.attribute77;
      data.section6.chkLamp10 = res.data.data.attribute78;
      data.section6.nmLamp10 = res.data.data.attribute79;
      data.section6.jmlLembar10 = res.data.data.attribute80;
      data.section6.kotaLapor = res.data.data.attribute81;
      data.section6.tglSPT = res.data.data.attribute82;
      data.section6.chkPKP = res.data.data.attribute83;
      data.section6.chkKuasa = res.data.data.attribute84;
      data.section6.namaPenandatangan = res.data.data.attribute86;
      data.section6.jabatanPenandatangan = res.data.data.attribute87;


      // console.log(data, 'data')
      return {
        data
      }
    },

    getPpnDdm: async(npwp, tahun, masa, pembetulan)=> {
      let options = utilsService.generateGatewayOptions(`/tarra-efaktur/${npwp}/spt1111/ssp/uangdimuka`);
      options.params = {
        masa:masa,
        tahun:tahun,
        revNo:pembetulan?pembetulan:'0'
      }
      var res = await httpService.get(options)
      var totalPpnDdm = res.data.data.reduce((a,v) =>  a = a + v.jumlahPembayaran , 0 )

      return {
        totalPpnDdm
      }
    },

    save: async (data) => {
      var options = utilsService.generateGatewayOptions(`/tarra-efaktur/${data.company.npwp}/spt1111/formulir-induk?masa=${data.masa}&tahun=${data.tahun}&revNo=${data.pembetulan}`);
      options.params = {
        masa:data.masa,
        tahun:data.tahun,
        revNo:data.pembetulan
      }

      // console.log(options, 'options')

      data.attribute15 = data.section1.jmlIA1DPP;
      data.attribute16 = data.section1.jmlIA2DPP;
      data.attribute17 = data.section1.jmlIA2PPN;
      data.attribute18 = data.section1.jmlIA3DPP;
      data.attribute19 = data.section1.jmlIA3PPN;
      data.attribute20 = data.section1.jmlIA4DPP;
      data.attribute21 = data.section1.jmlIA4PPN;
      data.attribute22 = data.section1.jmlIA5DPP;
      data.attribute23 = data.section1.jmlIA5PPN;
      data.attribute24 = data.section1.jmlIATotDPP;
      data.attribute25 = data.section1.jmlIATotPPN;
      data.attribute26 = data.section1.jmlIBDPP;
      data.attribute27 = data.section1.jmlICDPP;
      data.attribute28 = data.section2Ag.jmlIIAPPN;
      data.attribute29 = data.section2Ag.jmlIIBPPN;
      data.attribute30 = data.section2Ag.jmlIICPPN;
      data.attribute31 = data.section2Ag.jmlIIDPPN;
      data.attribute32 = data.section2Ag.jmlIIEPPN? data.section2Ag.jmlIIEPPN : "0";
      data.attribute33 = data.section2Ag.jmlIIFPPN? data.section2Ag.jmlIIFPPN : "0";
      data.attribute34 = data.section2Ag.tglKrgByrII;
      data.attribute35 = data.section2Ag.ntpnII;
            // section 2H
      data.attribute36 = data.section2h.chkLB1? 1 : 0;
      data.attribute37 = data.section2h.chkLB2? 1 : 0;
      data.attribute38 = data.section2h.chkLB3? 1 : 0;
      data.attribute39 = data.section2h.chkLB4? 1 : 0;
      data.attribute40 = data.section2h.chkLB5? 1 : 0;
      data.attribute41 = data.section2h.chkLB6? 1 : 0;
      data.attribute42 = data.section2h.chkLB7? 1 : 0;
      data.attribute43 = data.section2h.masaPajakLb;
      data.attribute44 = data.section2h.tahunPajakLb;
      data.attribute45 = data.section2h.chkLB8? 1 : 0;
      data.attribute46 = data.section2h.chkLB9? 1 : 0;
      data.attribute47 = data.section2h.chkLB10? 1 : 0;
      data.attribute48 = data.section2h.chkLB11? 1 : 0;
      data.attribute49 = data.section2h.chkLB12? 1 : 0;
      data.attribute50 = data.section2h.chkLB13? 1 : 0;
      data.attribute51 = data.section2h.chkLB14? 1 : 0;
      data.attribute52 = data.section2h.chkLB15? 1 : 0;
            // section 3
      data.attribute53 = data.section3.jmlIIIA;
      data.attribute54 = data.section3.jmlIIIB;
      data.attribute55 = data.section3.tglKrgByrIII;
      data.attribute56 = data.section3.ntpnIII;
            // section 4
      data.attribute57 = data.section4.jmlIVA;
      data.attribute58 = data.section4.tglKrgByrIV;
      data.attribute59 = data.section4.ntpnIV;
            // section 5
      data.attribute60 = data.section5.jmlVAPPN;
      data.attribute61 = data.section5.jmlVBPPN;
      data.attribute62 = data.section5.jmlVCPPN;
      data.attribute63 = data.section5.jmlVDPPN? data.section5.jmlVDPPN : "0";
      data.attribute64 = data.section5.jmlVEPPN? data.section5.jmlVEPPN : "0";
      data.attribute65 = data.section5.tglKrgByrV? data.section5.jmlVEPPN : "";
      data.attribute66 = data.section5.ntpnV;
            // section 6
      data.attribute67 = data.section6.chkLamp1? 1 : 0;
      data.attribute68 = data.section6.chkLamp2? 1 : 0;
      data.attribute69 = data.section6.chkLamp3? 1 : 0;
      data.attribute70 = data.section6.chkLamp4? 1 : 0;
      data.attribute71 = data.section6.chkLamp5? 1 : 0;
      data.attribute72 = data.section6.chkLamp6? 1 : 0;
      data.attribute73 = data.section6.chkLamp7? 1 : 0;
      data.attribute74 = data.section6.chkLamp8? 1 : 0;
      data.attribute75 = data.section6.jmlLembar7;
      data.attribute76 = data.section6.jmlLembar8? data.section6.jmlLembar8 : 0;
      data.attribute77 = data.section6.chkLamp9? 1 : 0;
      data.attribute78 = data.section6.chkLamp10? 1 : 0;
      data.attribute79 = data.section6.nmLamp10;
      data.attribute80 = data.section6.jmlLembar10? data.section6.jmlLembar10 : 0;
      data.attribute81 = data.section6.kotaLapor;
      data.attribute82 = data.section6.tglSPT;
      data.attribute83 = data.section6.chkPKP? 1 : 0;
      data.attribute84 = data.section6.chkKuasa? 1 : 0;
      data.attribute85 = null;
      data.attribute86 = data.section6.namaPenandatangan? data.section6.namaPenandatangan : null;
      data.attribute87 = data.section6.jabatanPenandatangan? data.section6.jabatanPenandatangan : null;

      options.data = data;
      
      var res = await httpService.put(options)
      return res;
    },
    report: async(sptId)=> {
      let options = utilsService.generateGatewayOptions(`/spt-1111/${sptId}/download-pdf`);
      options.data = {
        module: "Spt1111Induk"
      }
      options.config.responseType="arraybuffer"
      options.config.dataType='blob'
      var res = await httpService.post(options)
      return res
    },
  }
  
}

export default new Induk1111Service();
