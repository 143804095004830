import ApiService from '../../../services/api.service';
import { httpService } from 'libs/react-mpk';
import utilsService from '../../../services/utilsService';

class WpService extends ApiService {
  customSearch=true
  name='Wp'
  path='sertel/wp/get-wp-with-info'
  baseUrl='Wp'
  constructor(){
    super()
    this.init()
    this.initApi()
  }

  replacePath = (params, apiMethod) => {
    if(apiMethod == 'delete' || apiMethod == "findOne" || apiMethod == 'update'){
      return `${this.path}/${params.id}`;
    } else {
      return this.path;
    }
  }

  api = {
    getAll: async() => {
      let options = utilsService.generateGatewayOptions(`/sertel/wp-info`);
      options.data = {
        npwp: []
      }
      var res = await httpService.post(options)
      return res
    },

    getOne: async(npwp)=> {
      let options = utilsService.generateGatewayOptions(`/sertel/${npwp}/wp-info`);
      var res = await httpService.get(options)
      return res
    },
    updateManual: async(data)=> {
      let options = utilsService.generateGatewayOptions(`/sertel/${data.content.npwp}/wp-info`);
      options.data = data
      var res = await httpService.put(options)
      return res
    },
    saveManual: async(data)=> {
      let options = utilsService.generateGatewayOptions(`/sertel/${data.content.npwp}/wp-info`);
      options.data = data
      var res = await httpService.post(options)
      return res
    },
    uploadSertel: async(data, npwp)=>{
      let options = utilsService.generateGatewayOptions(`/sertel/upload/by-wp/${npwp}`);
      options.data = data
      var res = await httpService.post(options)
      return res
    },
    updateSertel: async(data, npwp)=> {
      let options = utilsService.generateGatewayOptions(`/sertel/wp/update-certificate-info/${npwp}`);
      options.data = data
      var res = await httpService.put(options)
      return res
    },
    updateKey: async(data, npwp)=> {
      let options = utilsService.generateGatewayOptions(`/sertel/wp/update-efaktur-key/${npwp}`);
      options.data = data
      var res = await httpService.put(options)
      return res
    }
  }
  
}

export default new WpService();
