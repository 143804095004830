import React from 'react';
import { SelectionControl, Checkbox, Switch, SelectionControlGroup } from 'react-md';

export default class CheckboxSelectionCustom extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      selectedValue: null
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.selectedValue == null){
      this.setState({ selectedValue: this.props.meta.initial })
    }
  }

  async handleChange(val){
    await this.props.input.onChange(val)
    this.setState({ selectedValue: val })
  }

  render() {
    let { input, meta: { touched, error }, ...others } = this.props;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    var name = this.props.input.name
    var initialValues = null
    if(this.props.formData){
        var values = this.props.formData
        var vak = "values."+name
        initialValues = eval(vak)
    }
    var style = {}
    if(this.props.inline) style={ display: 'inherit' }
    return (
      <div className={this.props.className} style={style}>
        <p>{this.props.label}</p>
        {this.props.options.map((i, index)=> {
          var checked = false;
          if(i.value == this.state.selectedValue){
            checked = true
          }
          if(i.value == initialValues){
            checked = true
          }
          return <Checkbox key={idProps+index} id={idProps+index} checked={checked} label={i.label} disabled={this.props.disabled} onChange={this.handleChange.bind(this, i.value)} />
        })}
        {touched && !!error && <div aria-hidden="false" className="md-text--error">Required</div>}
      </div>
    )
  }
}
