import React from 'react';
import FormView from '../../../../components/Base/FormView';
import SSPIVService from './SSPIV.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { ExpansionList, ExpansionPanel, TextField, Button, Card, DataTable, TableHeader, TableColumn, TableRow, TableBody } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection, Switch } from '../../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import SSPIIService from '../SSPII/SSPII.service';

@reduxForm({form: 'SSPIVForm', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('SSPIVForm')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class SSPIVFormView extends FormView {
    service=SSPIVService;
    viewType=2;

    initialData={
        company: null,
        pbk: false
    }

    async updateData(res, value){
      res = await this.service.api.update(value)
  }

  async saveData(res, value){
      var val = {}
      res = await this.service.api.save(value)
  }

    async beforeSave(val) { 
        val.npwp = this.props.router.match.params.npwp
        val.masaPajak = this.props.router.match.params.masa
        val.tahunPajak = this.props.router.match.params.tahun
        val.revNo = this.props.router.match.params.pembetulan
        val.idBayar = '2'
        if(val.fgNtpnPbk) {val.fgNtpnPbk = 1 } else {val.fgNtpnPbk = 0} 
        return val; 
    }

    async initEdit(){
        // let res = await this.service.api.findOne({
        //     id: this.props.router.match.params.id,
        //     npwp: this.props.router.match.params.npwp,
        //     masa: this.props.router.match.params.masa,
        //     tahun: this.props.router.match.params.tahun,
        //     pembetulan: this.props.router.match.params.pembetulan,
        // });
        // var companies = []
        // if(this.props.auth && this.props.auth.organizations) companies = this.props.auth.organizations
        // var getCompany = companies.filter((d)=> {
        //     return d.npwp == res.data.npwp
        // })
        // res.data.company = getCompany[0]
        this.props.initialize({});
    }
    async handleOptionKodeAkunPajak(e, val, preValue){
      var kodeJenisSetorans = []
      var kd = val
      if(kd == "411211"){
        kodeJenisSetorans = [
          {id: '100', name: '100 - Setoran Masa PPN dalam Negeri'},
          {id: '102', name: '102 - Setoran PPN JKP dari luar Daerah Pabean'},
          {id: '103', name: '103 - Setoran Kegiatan Membangun Sendiri'},
          {id: '104', name: '104 - Setoran Penyerahan Aktiva yang menurut tujuan semula tidak untuk diperjualbelikan dan Setoran Atas Pengalihan Aktiva Dalam Rangka Restrukturisasi Perusahaan'},
          {id: '199', name: '199 - Pembayaran Pendahuluan skp PPN'},
          {id: '300', name: '300 - STP PPN Dalam Negeri'},
          {id: '310', name: '310 - SKPKB (Surat Ketetapan Pajak Kurang Bayar) PPN Dalam Negeri'},
          {id: '311', name: '311 - SKPKB (Surat Ketetapan Pajak Kurang Bayar) PPN Pemanfaatan BKP tidak berwujud dari luar Daerah Pabean'},
          {id: '312', name: '312 - SKPKB (Surat Ketetapan Pajak Kurang Bayar) PPN Pemanfaatan JKP dari luar Daerah Pabean'},
          {id: '313', name: '313 - SKPKB (Surat Ketetapan Pajak Kurang Bayar) PPN Kegiatan Membangun Sendiri'},
          {id: '314', name: '314 - SKPKB (Surat Ketetapan Pajak Kurang Bayar) Pemungut PPN Dalam Negeri'},
          {id: '320', name: '320 - SKPKBT PPN Dalam Negeri'},
          {id: '321', name: '321 - SKPKBT PPN Pemanfaatan BKP tidak berwujud dari luar Daerah Pabean'},
          {id: '322', name: '322 - SKPKBT PPN Peman-faatan JKP dari luar Daerah Pabean'},
          {id: '323', name: '323 - SKPKBT PPN atas Kegiatan Membangun Sendiri'},
          {id: '324', name: '324 - SKPKBT Pemungut PPN Dalam Negeri'},
          {id: '390', name: '390 - Pembayaran atas Surat Keputusan Pembetulan, Surat Keputusan Keberatan, Putusan Banding, atau Putusan Peninjauan Kembali'},
          {id: '900', name: '900 - Pemungut PPN Dalam Negeri'}
        ]
      }
      if(kd == "411212"){
        kodeJenisSetorans = [
          {id: '100', name: '100 - Setoran Masa PPN Impor'},
          {id: '199', name: '199 - Pembayaran Pendahuluan skp PPN Impor'},
          {id: '300', name: '300 - STP PPN Impor'},
          {id: '310', name: '310 - SKPKB PPN Impor'},
          {id: '320', name: '320 - SKPKBT PPN Impor'},
          {id: '390', name: '390 - Pembayaran atas Surat Keputusan Pembetulan, Surat Keputusan Keberatan, Putusan Banding, atau Putusan Peninjauan Kembali'},
          {id: '900', name: '900 - Pemungut PPN Impor'}
        ]
      }
      if(kd == "411221"){
        kodeJenisSetorans = [
          {id: '100', name: '100 - Setoran Masa PPnBM Dalam Negeri'},
          {id: '199', name: '199 - Pembayaran Pendahuluan skp PPnBM Dalam Negeri'},
          {id: '300', name: '300 - STP PPnBM Dalam Negeri'},
          {id: '310', name: '310 - SKPKB Masa PPnBM Dalam Negeri'},
          {id: '311', name: '311 - SKPKB Pemungut PPnBM Dalam Negeri'},
          {id: '320', name: '320 - SKPKBT Masa PPnBM Dalam Negeri'},
          {id: '321', name: '321 - SKPKBT Pemungut PPnBM Dalam Negeri'},
          {id: '390', name: '390 - Pembayaran atas Surat Keputusan Pembetulan, Surat Keputusan Keberatan, Putusan Banding, atau Putusan Peninjauan Kembali'},
          {id: '900', name: '900 - Pemungut PPnBM Dalam Negeri'}
        ]
      }
      if(kd == "411222"){
        kodeJenisSetorans = [
          {id: '100', name: '100 - Setoran Masa PPnBM Impor'},
          {id: '199', name: '199 - Pembayaran Pendahuluan skp PPn'},
          {id: '300', name: '300 - STP PPnBM Impor'},
          {id: '310', name: '310 - SKPKB PPnBM Impor'},
          {id: '320', name: '320 - SKPKBT PPnBM Impor'},
          {id: '390', name: '390 - Pembayaran atas Surat Keputusan Pembetulan, Surat Keputusan Keberatan, Putusan Banding, atau Putusan Peninjauan Kembali'},
          {id: '900', name: '900 - Pemungut PPnBM Impor'}
        ]
      }
      if(kd == "411219"){
        kodeJenisSetorans = [
          {id: '100', name: '100 - Setoran Masa PPN Lainnya'},
          {id: '300', name: '300 - STP PPN Lainnya'},
          {id: '310', name: '310 - SKPKB PPN Lainnya'},
          {id: '320', name: '320 - SKPKBT PPN Lainnya'},
          {id: '390', name: '390 - Pembayaran atas Surat Keputusan Pembetulan, Surat Keputusan Keberatan, Putusan Banding, atau Putusan Peninjauan Kembali'}
        ]
      }
      if(kd == "411229"){
        kodeJenisSetorans = [
          {id: '100', name: '100 - Setoran Masa PPnBM Lainnya'},
          {id: '300', name: '300 - STP PPnBM Lainnya'},
          {id: '310', name: '310 - SKPKB PPnBM Lainnya'},
          {id: '320', name: '320 - SKPKBT PPnBM Lainnya'},
          {id: '390', name: '390 - Pembayaran atas Surat Keputusan Pembetulan, Surat Keputusan Keberatan, Putusan Banding, atau Putusan Peninjauan Kembali'}
        ]
      }
      this.setState({
        kodeJenisSetorans: kodeJenisSetorans
      })
    }  

    formView() {
      var companies = []
      if(this.props.auth && this.props.auth.organizations) companies = this.props.auth.organizations
      var pbk = false
      if(this.props.formData && this.props.formData.fgNtpnPbk) pbk = this.props.formData.fgNtpnPbk
        return (
            <div>
                <Card>
                  <div className='md-grid'>
                    {/* <TextField 
                      label="Jenis Pembayaran"
                      value="Pembayaran Pajak Masukan PKP gagal Produksi"
                      className="md-cell md-cell--12"
                      disabled={true}
                    />
                    <Field
                      label='Kode Akun Pajak'
                      name='kodeAkunPajak'
                      className="md-cell md-cell--12"
                      component={Searchfield}
                      onChange={this.handleOptionKodeAkunPajak.bind(this)}
                      options={[
                        {id: '411211', name: 'PPN Dalam Negeri'},
                        {id: '411212', name: 'PPN Impor'},
                        {id: '411221', name: 'PPnBM Dalam Negeri'},
                        {id: '411222', name: 'PPnBM Impor'},
                        {id: '411219', name: 'PPN Lainnya'},
                        {id: '411229', name: 'PPbBM Lainnya'}
                      ]}
                      validate={validation.required}
                      rerender={true}
                    />
                    <Field
                      label='Kode Jenis Setoran'
                      name='kodeJenisSetoran'
                      className="md-cell md-cell--12"
                      component={Searchfield}
                      options={this.state.kodeJenisSetorans}
                      validate={validation.required}
                      rerender={true}
                    /> */}
                     <Field
                      label='PBK'
                      name='fgNtpnPbk'
                      className="md-cell md-cell--12"
                      component={Switch}
                    />
                    {pbk && 
                      <Field
                        label='NTPN PBK'
                        name='nomorPembayaran'
                        className="md-cell md-cell--12"
                        component={Textfield}
                        validate={validation.required}
                      />
                    }
                    {!pbk && 
                      <Field
                        label='NTPN'
                        name='nomorPembayaran'
                        className="md-cell md-cell--12"
                        component={Textfield}
                        validate={validation.required && validation.maxLength16}
                        mask="_"
                        length={16}
                        maxLength={16}
                        maskFormat="################"
                      />
                    }
                    <Field
                      label='Nilai'
                      name='jumlahPembayaran'
                      className="md-cell md-cell--12"
                      component={TextfieldMask}
                      money={true}
                      validate={validation.required}
                      normalize={(e)=> parseInt(e)}
                    />
                    {/* <Field
                      label='Tanggal SSP'
                      name='tglSsp'
                      className="md-cell md-cell--12"
                      component={Datepickerv2}
                      locales="id-ID"
                      validate={validation.required}
                      inline={true}
                    /> */}
                   
                  </div>
                </Card>
            </div>
        )
    }
};

export default SSPIVFormView;