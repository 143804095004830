import React from 'react';
import { TextField, SelectField, TimePicker, FileInput, Switch, DatePicker } from 'react-md';
import formService from '../../services/formService';
import DialogForm from '../Dialog/DialogForm';
import { cloneDeep } from 'lodash';
import DividerLabel from '../DividerLabel';
import ContainerForm from '../ContainerForm';
import t from 'counterpart';
import DateTimeField from '../DateTimeField';

class FormWrapper extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      formData: props.defaultFormData(),
      errorValidation: formService.defaultErrorValidation([props.validationColumns]),
      isEdit: false
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.asDialog){
      if(nextProps.item && nextProps.visible && !this.props.visible) this.initItem(nextProps.item);
      else this.initDefault();
    }
  }

  componentDidMount(){
    let { item } = this.props;
    if(item) this.initItem(item);
    else this.initDefault();
  }

  initItem = async (item) => {
    let { fetchData } = this.props;
    let formData = fetchData ? await fetchData(item) : cloneDeep(item);
    this.setState({
      formData,
      isEdit: true
    })
  }

  initDefault = () => {
    this.setState({
      formData: this.props.defaultFormData(),
      isEdit: false
    });
  }

  handleFormChange = (key, value) => {
    let { formData } = this.state;
    value = this.props.onBeforeChange(key, value, formData);
    formData = formService.handleChange(formData, key, value);
    this.setState({formData:formData});
  }

  handleFileChange = (key, file) => {
    let { formData } = this.state;
    if(file.type.match('image')){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          formData[key] = file;
          formData[`${key}-preview`] = reader.result;
          this.setState({formData})
          console.log(reader.result)
      };
      reader.onerror = (error) => {
          console.log('Error: ', error);
      };
    }
  }

  handleSubmit = (callback) => {
    let { onSubmit } = this.props;
    let { formData, isEdit } = this.state;
    if(onSubmit) onSubmit(formData, isEdit, callback);
  }

  render(){
    let { formData, isEdit } = this.state;
    let { title, visible, onCancel, definitions, showSubmit, contentOnly, baseId, dialogStyle, asDialog } = this.props;
    const content = (
      <div>
        {definitions.map( d => {
          let { label, placeholder, key, ...componentProps } = d;
          switch(d.inputType){
            case 'input':
              return (
                <TextField
                  id={`form-${baseId}-${key}`}
                  key={`form-${baseId}-${key}`} 
                  label={d.label}
                  placeholder={d.placeholder}
                  value={d.getValue ? d.getValue(d) : formData[d.key]}
                  onChange={ value => this.handleFormChange(d.key, value)}
                  readOnly={d.getReadOnly ? d.getReadOnly(isEdit) : false}
                  {...componentProps}
                />
              )
            case 'select':
              return (
                <SelectField 
                  id={`form-${baseId}-${key}`}
                  key={`form-${baseId}-${key}`}
                  label={d.label}
                  placeholder={d.placeholder}
                  value={d.getValue ? d.getValue(d) : formData[d.key]}
                  onChange={ value => this.handleFormChange(d.key, value)}
                  readOnly={d.getReadOnly ? d.getReadOnly(isEdit) : false}
                  {...componentProps}
                />
              )
            case 'checkbox':
              return (
                <Switch
                  id={`form-${baseId}-${key}`} 
                  key={`form-${baseId}-${key}`} 
                  name={d.label}
                  label={d.label}
                  checked={ d.getValue ? d.getValue(d) : formData[d.key]}
                  onChange={ value => this.handleFormChange(d.key, value)}
                  readOnly={d.getReadOnly ? d.getReadOnly(isEdit) : false}
                />
              )
            case 'date-time':
              return(
                <DateTimeField
                  id={`form-${baseId}-${key}`} 
                  key={`form-${baseId}-${key}`} 
                  name={d.label}
                  label={d.label}
                  defaultValue={ d.getValue ? d.getValue(d) : formData[d.key]}
                  onChange={ value => this.handleFormChange(d.key, value)}
                  readOnly={d.getReadOnly ? d.getReadOnly(isEdit) : false}
                />
              )
            case 'date-picker':
              return (
                <DatePicker
                  id={`form-${baseId}-${key}`} 
                  key={`form-${baseId}-${key}`} 
                  name={d.label}
                  label={d.label}
                  value={ d.getValue ? d.getValue(d) : formData[d.key]}
                  onChange={ value => this.handleFormChange(d.key, value)}
                  readOnly={d.getReadOnly ? d.getReadOnly(isEdit) : false}
                  inline={asDialog}
                  autoOk
                  {...componentProps}
                />
              )
            case 'time-picker':
              return (
                <TimePicker
                  id={`form-${baseId}-${key}`} 
                  key={`form-${baseId}-${key}`} 
                  name={d.label}
                  label={d.label}
                  value={ d.getValue ? d.getValue(d) : formData[d.key]}
                  onChange={ value => this.handleFormChange(d.key, value)}
                  readOnly={d.getReadOnly ? d.getReadOnly(isEdit) : false}
                  inline={asDialog}
                  autoOk
                  {...componentProps}
                />
              )
            case 'divider':
              return (
                <DividerLabel label={d.label}/>
              )
            case 'file-input':
              return (
                <div className="mpk-layout column">
                  {formData[`${key}-preview`] && (
                    <img 
                      src={formData[`${key}-preview`]}
                      alt={`${key}-preview`}
                      style={{
                        width: 64,
                        height: 64,
                        objectFit: 'cover'
                      }}
                    />
                  )}
                  <FileInput
                    id={`file-input-${Math.round(Math.random()*9999)}`} primary
                    onChange={ file => this.handleFileChange(d.key, file)}
                    className="mpk-margin-S top"
                  />
                </div>
              )
            default: return d.component
          }
        })}
        {this.props.children}
        { this.props.onSubmit && !asDialog ? <button type="submit" id={baseId} style={{display: 'none'}}/> : null }
      </div>
    );

    return contentOnly ? (content) : (
      asDialog ? (
        <DialogForm
          focusOnMount={false}
          showSubmit={showSubmit}
          cancelLabel={showSubmit ? null : t.translate('word.close')}
          {...{
            title, visible, onCancel, dialogStyle, baseId, 
            onSubmit: this.handleSubmit
          }}
        >
          {content}
        </DialogForm>
      ) : (
        <ContainerForm
          onSubmit={this.handleSubmit}
          showSubmit={showSubmit}
        >
          {content}
        </ContainerForm>
      )
    )
  }
}

FormWrapper.defaultProps = {
  baseId: 'form-wrapper-id',
  definitions:[],
  showSubmit: true,
  contentOnly: false,
  onSubmit: (___, callback) => {callback(true, 'on-submit props is not defined yet')},
  asDialog: true,
  dialogStyle:{
    width: '100%',
    maxWidth: 320
  },
  visible: false, 
  title: 'Form',
  onCancel: () => {},
  onBeforeChange: () => {},
  fetchData: null,
  defaultFormData: () => ({}),
  validationColumns: [],
  item: null
}

FormWrapper.types = {
  INPUT: 'input',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  DIVIDER: 'divider',
  FILE_INPUT: 'file-input',
  DATE_PICKER: 'date-picker',
  TIME_PICKER: 'time-picker',
  DATE_TIME: 'date-time'
}

export default FormWrapper;