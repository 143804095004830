import React, { Component } from 'react';
import { Commandbar, TableSetting, Empty, DialogConfirm, navService, Table } from './../../libs/react-mpk';
import { Switch, Paper  } from 'react-md';
import iziToast from 'izitoast';
import TableSettingCustom from './TableSettingCustom';
import counterpart from 'counterpart';
import { validation, Searchfield, Textfield } from '../Form';
import { Field, reduxForm } from 'redux-form';

class SearchContainer extends Component {
  render() {
    return (
      <div className="md-grid">
        {this.props.children}
      </div>
    )
  }
}

export default class ListView extends Component {
    constructor(props){
        super(props)
        this.state = {
            showTableSetting: false,
            formData: {},
            data: [],
            showForm: false,
            addDialog: false,
        };
    };

    navService = navService

    // Declaration
    translate=true;
    // --end Declaration

    buildSearchContainer() {
      var cont = reduxForm({form: this.service.name+'_search', destroyOnUnmount: true})(SearchContainer);
      this.SearchContainer = cont;
    }

    componentWillMount() {
      this.buildSearchContainer();
      // this.fetchData();
      this.props.dispatch(
        this.service.tableAction.setProperties({
          index: 0,
          isSelect: false,
          selected: [],
          params: {}
        })
      )
    }

    removeCheckbox(){
      var serviceName = ""
        if(this.service && this.service.name) serviceName = this.service.name
        var dispatchName = serviceName.toUpperCase() + "/TABLE_SET_PROPERTIES"
        this.props.dispatch(
          {
            type: dispatchName,
            properties: {
              index: 0,
              isSelect: false,
              selected: []
            }
          }
      )
      var mpkTable = document.getElementById('mpk-inner-tbl')
      var mpkRow = mpkTable.getElementsByClassName('md-fake-btn')
      var mpkCounter = 0
      while(mpkCounter < mpkRow.length){
          var checkbox = mpkRow[mpkCounter]
          var checkboxIcon = checkbox.getElementsByClassName('md-icon')[0]
          checkbox.classList.remove("md-text--theme-secondary")
          checkboxIcon.innerText = 'check_box_outline_blank'
          mpkCounter++;
      }
    }

    // Command Bar / Top Bar
    titleHeader() {
        return `entities.${this.service.name}.title`;
    }

    _barItem() {
        return (
            <Switch
                id="switch-table-setting"
                name="Switch Table Setting"
                label=""
                checked={this.state.showTableSetting}
                onChange={() => {
                  this.setState({
                    showTableSetting:!this.state.showTableSetting
                  })
                }}
            />
        )
    };

    addItem = () => {
      if(this.addDialog) {
        this.setState({showForm:true, formData: {}})
      } else {
        navService.redirectTo(this.service.baseUrl + "/new");
      }
    }


    editItem = (item) => {
      navService.redirectTo(this.service.baseUrl + "/" + item.id);
    }

    _barActions = [
        {
            label:'custom.words.create',
            iconClassName:'mdi mdi-plus',
            onClick:() => this.addItem()
        },
        {
            label:'custom.words.delete',
            iconClassName:'mdi mdi-delete',
            // onClick:() => {
            //     this.setState({showDialogConfirmDeleteSelected:true})
            // },
            // disabledFunc:() => this.props.table.selected.length === 0
        },
        {
            label:'custom.words.refresh',
            iconClassName:'mdi mdi-refresh',
            onClick:() => this.fetchData()
        },
    ]

    _commandBar(props) {    
        var barActions = this._barActions;
            if(this.barActions) barActions = this.barActions;
            if(typeof this.barActions == 'function'){
            barActions = this.barActions()
        }
        return <Commandbar title={this.titleHeader()} actions={barActions} translate={this.translate} rightCorner={this._barItem()} />
    };

    commandBar(props) {
        return this._commandBar(props);
    };

    belowTopBar() { return null; }
    upperTopBar() { return null; }
    // --end Command Bar / Top Bar


    // Table
    plainTable(){ return true; }
    footer() { return null; }
    _columns = [
        {label: "word.name",  value: "name", isDefaultSort:true, show:true, isSearchable:true}
    ]

    header() {
        return <div/>
    }

    deleteItem = async (item, callback) => {
      try {
        await this.service.api.delete(item);
        callback()
        await this.fetchData()
      } catch(e) {
        callback(e, e)
      }
    }

    _tableActions = [
        {
          label:"Detail Data",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Hapus",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => {
            this.deleteItem(item, callback)
          },
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]

    topTable(){
      return null
    }

    _tableView(props) {
        var columns = Object.assign([], this._columns);
        var tableActions = this._tableActions;
        if(this.columns) columns = Object.assign([], this.columns);
        if(this.tableActions) tableActions= this.tableActions;

        return (
            <div className="flex mpk-layout column">
              {this.header()}
              <Paper className="flex">
                <Table
                  connect={{
                    properties: this.props.table,
                    actions: this.props.tableActions
                  }}
                  plain={this.plainTable()}
                  fullWidth={false}
                  isPaging={true}
                  translate={true}
                  columns={columns}
                  itemActions={tableActions}
                  fetchData={this.fetchData}
                  footer={this.footer()}
                />
              </Paper>
            </div>
        )
    };

    tableView(props) {return this._tableView(props)};

    beforeFetch(params) {}
    apiPath = 'find';
    fetchOption() { return {} }

    overideTotal(res){
      return parseInt(res.headers['x-total-count'])
    }

    overideData(res){
      return res.data
    }

    fetchData = async (params=this.props.table.params, onSuccess, onError) => {
        if(!onSuccess) {
          this.props.tableActions.setProperties({
            isLoading: true
          })
        }
        try {
          await this.beforeFetch(params)
          var res = await this.service.api[this.apiPath](params, this.props.dispatch, this.fetchOption());
          var data = this.overideData(res)
          var total = this.overideTotal(res)
          // data = []
          // total = 0
          if(onSuccess) onSuccess(data, total)
          if(!onSuccess) {
            this.props.tableActions.setProperties({
              isLoading: false,
              data,
              params:{
                total
              }
            })
          }
          this.setState({data})
          return {
            data,
            total
          }
        } catch(e) {
          if(e && e.response && e.response.data){
            iziToast.error({
              title: "Get Data",
              message: e.response.data
            })
          }
        }
    };
    // --end Table

    readCookie(name) {
      var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    render(){
        try {
          var parentParameter = this.props.router.location.search;
          // var parentParameter = x
          var splitParameter = parentParameter.split('&');
          var pParam = {}
          splitParameter.map(function (d){
            try {
              var field = d.split('=')[0]
              var value = d.split('=')[1]
              pParam[field] = value
            } catch(e){
              console.log(e)
            }
          })
          // console.log(this, pParam['companyId'], pParam['companyId'])
          if(pParam['companyId']){
            sessionStorage.setItem("x-tarra-id", pParam['companyId'])
          } else {
            try {
              var companyId = null
              this.props.auth.company.map((d)=> {
                if(companyId){
                  companyId = companyId + "|" + d.id
                } else {
                  companyId = d.id
                }
              })
              sessionStorage.setItem("x-tarra-id", companyId)
            } catch(e){
              console.log(e);
            }
          }
          if(pParam['?accessToken']){
            sessionStorage.setItem("x-tarra-at", pParam['?accessToken'])
          } else {
            try {
              var token = this.readCookie('MPK_ACCESS_TOKEN')
              sessionStorage.setItem("x-tarra-at", token)
            } catch(e){
              console.log(e)
            }
          }
        } catch(e){
          console.log(e)
        }
        return (
            <div className="mpk-layout column fill">
                <div style={{ background: '#EEEEEE' }}>
                    {this.upperTopBar()}
                    {this.commandBar()}
                    {this.belowTopBar()}
                </div>
                <div className="flex mpk-layout mpk-padding-N all" style={{ background: '#EEEEEE' }}>
                    {this.tableView()}
                    {this.tableSetting()}
                </div>
            </div>
        )
    };

    // table setting and search
    tabWidth= 240
    tabs = ["search", "column"];
    tabComponents() { return {} }

    searchForm() {
      if(this.customSearch){
        return <this.SearchContainer>{this.defaultCustomSearchForm()}</this.SearchContainer>
      } else {
        return <this.SearchContainer>{this.defaultSearchForm()}</this.SearchContainer>
      }
    }

    defaultCustomSearchForm(){
      var columns = this.searchColumn()
      return (
        <div className='md-grid' style={{margin: 0, padding:0, width: '100%'}}>
            {columns.map((d)=> {
              return (
                <Field
                  label = {d.searchLabel}
                  name  = {`${d.searchField}__${d.searchAction}`}
                  className = "md-cell md-cell--12"
                  component = {Textfield}
                />
              )
            })}
        </div>
      )
    }

    defaultSearchForm() {
      var columns = []
      this.columns.map((d, i)=>{
        if(d.label == 'word.number' || d.label == 'word.actions' || d.isSearchable == false){
        } else { columns.push(d) }
      })
      var options = columns.map((d, i) => {
        var id = d.value;
        // if(typeof id === 'function') id = id();
        var action = 'equals';
        if(d.searchField) id = d.searchField
        if(d.searchAction) action = d.searchAction;
        return {
          id: id+'.'+action,
          name: counterpart.translate(d.label)
        }
      })
      return (
        <div className='md-grid' style={{margin: 0, padding:0}}>
          <Field
            label='Kolom'
            name='column'
            className="md-cell md-cell--12"
            component={Searchfield}
            options={options}
          />
          <Field
            label='Kata Kunci'
            name='searchKey'
            className="md-cell md-cell--12"
            component={Textfield}
          />
        </div>
      )
    }

    _tableSetting() {
      if(this.state.showTableSetting) {
        return <div className="flex-none mpk-layout">
          <Empty width={16} className="flex-none"/>
          <Paper className="flex-none">
            <TableSettingCustom
              dispatch={this.props.dispatch}
              service={this.service}
              tableFilter={this.props.tableFilter}
              tableFilter2={this.props.tableFilter2}
              tableActions={this.props.tableActions}
              table={this.props.table}
              history={this.props.history}

              width={this.tabWidth}
              tabs={this.tabs}
              tabComponents={this.tabComponents()}
              searchForm={this.searchForm()}
              onSearch={this.fetchData}
              translate={true}
            />
          </Paper>
        </div>
      }
    }

    tableSetting() {return this._tableSetting()}
};