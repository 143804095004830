import React from 'react';
import rdxConnect from './rdxConnect';
import { httpService } from '../';
import { Table, ContainerTransform, Empty, TableSetting, Hint } from '../';
import { Paper, Button } from 'react-md';
import { merge } from 'lodash';
import t from 'counterpart';

export default (getOptions) => (WrappedComponent) => {
  class withTable extends React.Component{
    constructor(props){
      super(props);
      this.opt = merge({
        hint:{
          showIcon: true,
          children: 'This is hint'
        },
        columns: [],
        itemActions: [],
        actions: [],
        fetchData: () => {},
        tableProps: {
          selectableRows: false,
          isPaging: true
        },
        tableSettingProps: {}
      }, getOptions(props, {
        showForm: this.handleShowForm,
        deleteItem: this.handleDeleteItem
      }))
      
      this.state = merge({
        showForm   : false,
        showFilter    : true,
        selectedItem  : null,
        loading       : false
      }, this.opt.state)
    }

    handleShowForm = (showForm=true, selectedItem=null) => this.setState({showForm, selectedItem})
    handleDeleteItem = (item, onDelete = Promise, selector) => {
      selector = selector || {id: item.id};
      this.props.dialogActions.showConfirm(
        t.translate('sentence.dialog.delete.title'),
        t.translate('sentence.dialog.delete.message'),
        async (callback) => {
          try {
            await onDelete(item);
            if(selector) this.props.tableActions.deleteDataItemWithSelector(selector);
            callback(false, t.translate('sentence.info.deletedItem'));
          } catch (error) {
            callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode))
          }
        },
        null,
        t.translate('word.yes'),
        t.translate('word.cancel'),
      )
    }

    handleFetchData = async (params, resolve, reject, onDataItem) => {
      try {
        let { data, total} = await this.opt.fetchData(params, onDataItem);
        resolve(data, total);
      } catch (error) {
        reject(error);
      }
    }

    handleToggleFilter = async () => {
      let { showFilter } = this.state;
      console.log('huuh aya')
      this.setState({showFilter: !showFilter});
    }

    render(){
      let actions = this.opt.actions.filter(d => (String(d.show) !== 'null' ? d.show : true));
      return(
        <WrappedComponent
          {...this.props}
          {...{
            state:this.state,
            handle:{
              toggleFilter  : this.handleToggleFilter,
              showForm      : this.handleShowForm,
              deleteItem    : this.handleDeleteItem
            }
          }}
        >
          <div className="mpk-full width height mpk-position relative mpk-animation slide-in mpk-layout">
            <Paper className="flex mpk-layout column">
              { this.opt.hint && <Hint {...this.opt.hint}/>}
              <div className="flex mpk-layout">
                <Table
                  baseId={`balance-account-list`}
                  itemActions={this.opt.itemActions}
                  columns={this.opt.columns}
                  fetchData={this.handleFetchData}
                  translate={false}
                  {...this.opt.tableProps}
                />
                { actions.length > 0 && (
                  <div className="flex-none mpk-border thin solid dark left mpk-padding-S padding-all">
                    {actions.map(d => {
                      switch(d.type){
                        case 'button':
                        default:
                          return (
                            <Button
                              icon
                              iconClassName={d.iconClassName}
                              onClick={d.onClick}
                            />
                          );
                      }
                    })}
                  </div>
                )}
              </div>
            </Paper>
            {this.state.showFilter && (
              <ContainerTransform className="flex-none mpk-layout">
                <Empty width={16} className="flex-none mpk-hide-xs"/>
                <Paper className="flex-none">
                  <TableSetting
                    onSearch={this.handleFetchData}
                    translate={false}
                    showPeriods={false}
                    {...this.opt.tableSettingProps}
                    />
                </Paper>
              </ContainerTransform>
            )}
          </div>
        </WrappedComponent>
      )
    }
  }

  return rdxConnect(withTable);
}