import React from 'react';
import FormView from '../../../components/Base/FormView';
import SptService from './Spt.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { Card, ExpansionList, ExpansionPanel, TextField, Button, DataTable, TableHeader, TableColumn, TableRow, TableBody } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection, Switch, Checkbox } from '../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import WpService from './../Wp/Wp.service'

@reduxForm({form: 'SptForm', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('SptForm')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class SptFormView extends FormView {
    service=SptService;
    viewType=2;

    constructor(props){
        super(props);
        this.state = {
            ...this.state,
            npwps: [],
            npwpDetails: [],
            getNpwp: false
        }
    }

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    async initEdit(){
        let res = await this.service.api.findOne({
            id: this.props.router.match.params.id,
        });
        this.props.initialize(res.data);
    }

    initialData = {
        masa: new Date().getMonth() + 1,
        tahun: parseInt(new Date().getFullYear()),
        pembetulan: 0,
        company: {
            address: "",
            alias: "",
            klu: "",
            mobile: "",
            name: "",
            npwp: "",
            phone: "",
            wajibPpnbm: false
        },
        npwpDetails: []
    }

    async componentDidMount(){
        try {
            let res = await WpService.api.getAll();
            var npwps = []
            var npwpDetails = []
            res.data.map((d)=> {
                if(d.registered && d.hasCertificate && d.registeredToDjp){
                    npwps.push({
                        id: d.npwp,
                        npwp: d.npwp,
                        name: d.name
                    })
                    npwpDetails.push(d)
                }
            })
            this.setState({ npwps: npwps, npwpDetails: npwpDetails })
        } catch(e){
            try {
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.response.data.detail}`
                })
            } catch(e){
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.message}`
                })
            }
        }
    }

    async updateData(res, value){
        res = await this.service.api.saveByParam(value)
    }

    async saveData(res, value){
        res = await this.service.api.saveByParam(value)
    }

    formView() {
        var companies = []
        if(this.props.auth && this.props.auth.organizations) companies = this.props.auth.organizations
        var npwps = []
        if(this.state.npwps && this.state.npwps.length > 0) npwps = this.state.npwps
        return (
            <div className="mpk-center-container">
                <Card>
                    <div className="md-grid">
                        <Field
                            label="Masa Pajak"
                            name='masa'
                            className="md-cell md-cell--12"
                            component={Searchfield}
                            validate={validation.required}
                            options={[
                                {id: 1, name: 1},
                                {id: 2, name: 2},
                                {id: 3, name: 3},
                                {id: 4, name: 4},
                                {id: 5, name: 5},
                                {id: 6, name: 6},
                                {id: 7, name: 7},
                                {id: 8, name: 8},
                                {id: 9, name: 9},
                                {id: 10, name: 10},
                                {id: 11, name: 11},
                                {id: 12, name: 12}
                            ]}
                        />
                        <Field
                            label='Tahun Pajak'
                            name='tahun'
                            className="md-cell md-cell--12"
                            // normalize={(e)=> parseInt(e) }
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Pembetulan'
                            name='pembetulan'
                            className="md-cell md-cell--12"
                            component={TextfieldMask}
                            money={true}
                            validate={validation.requiredMoney}
                            normalize={(e)=> parseInt(e) }
                        />
                        <Field
                                    label='NPWP Perusahaan'
                                    name='company.npwp'
                                    className="md-cell md-cell--6"
                                    component={Searchfield}
                                    rerender
                                    validate={validation.required}
                                    options={npwps}
                                    itemTemplate  = {function(d) {
                                        return {
                                          primaryText: d[this.props.viewField],
                                          secondaryText: d.npwp+" "+d.name,
                                          onClick: () => {
                                            if(!this.props.disabled){
                                              this.handleItemClick(d); this.hide()
                                            }
                                          }
                                        }
                                      }}
                                    onChange={async (e, value)=> {
                                        var npwp = value
                                        var getDetail = await WpService.api.getOne(npwp)
                                        // var getDetail = this.state.npwpDetails.filter((d)=> {
                                        //     return d.npwp == npwp
                                        // })
                                        try {
                                            var details = { info: getDetail.data }
                                            this.props.change('company.name', details.info.name)
                                            this.props.change('company.alias', details.info.alias)
                                            this.props.change('company.address', details.info.infoAddress)
                                            this.props.change('company.klu', details.info.infoKlu)
                                            this.props.change('company.mobile', details.info.infoMobile)
                                            this.props.change('company.phone', details.info.infoPhone)
                                            this.props.change('company.wajibPpnbm', details.info.infoWajibPpnbm)
                                            this.props.change('penandatanganInfo.kotaLapor', details.info.infoCity)
                                            var signers = details.info.signers.filter((signer)=> {
                                                return signer.type == 'TARRA'
                                            })
                                            // console.log(signers)
                                            try {
                                                if(signers[0].active){
                                                    this.props.change('penandatanganInfo.chkPKP', true)
                                                    this.props.change('penandatanganInfo.chkKuasa', false)
                                                    this.props.change('penandatanganInfo.namaPenandatangan', signers[0].name)
                                                    this.props.change('penandatanganInfo.jabatanPenandatangan', signers[0].occupation)
                                                }
                                            } catch(e){
                                                console.log(e)
                                            }
                                            try {
                                                if(signers[1].active){
                                                    this.props.change('penandatanganInfo.chkPKP', false)
                                                    this.props.change('penandatanganInfo.chkKuasa', true)
                                                    this.props.change('penandatanganInfo.namaPenandatangan', signers[1].name)
                                                    this.props.change('penandatanganInfo.jabatanPenandatangan', signers[1].occupation)
                                                }
                                            } catch(e){
                                                console.log(e)
                                            }
                                        } catch(e){
                                            console.log(e)
                                        }
                            }}
                        />
                        <Field
                            label='Nama Perusahaan'
                            name='company.name'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='Alias'
                            name='company.alias'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='Alamat'
                            name='company.address'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='KLU'
                            name='company.klu'
                            className="md-cell md-cell--4"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='Mobile'
                            name='company.mobile'
                            className="md-cell md-cell--4"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='Telepon'
                            name='company.phone'
                            className="md-cell md-cell--4"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            id="Wajib PPnBM"
                            label='Wajib PPnBM'
                            name='company.wajibPpnbm'
                            className="md-cell md-cell--4"
                            component={Checkbox}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            id="PKP"
                            label='PKP'
                            name='penandatanganInfo.chkPKP'
                            className="md-cell md-cell--4"
                            component={Checkbox}
                            disabled={true}
                        />
                        <Field
                            id="Kuasa"
                            label='Kuasa'
                            name='penandatanganInfo.chkKuasa'
                            className="md-cell md-cell--4"
                            component={Checkbox}
                            disabled={true}
                        />
                        <Field
                            id="Kota Lapor"
                            label='Kota Lapor'
                            name='penandatanganInfo.kotaLapor'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled={true}
                        />
                        <Field
                            id="Nama Penandatangan"
                            label='Nama Penandatangan'
                            name='penandatanganInfo.namaPenandatangan'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled={true}
                        />
                        <Field
                            id="Jabatan Penandatangan"
                            label='Jabatan Penandatangan'
                            name='penandatanganInfo.jabatanPenandatangan'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled={true}
                        />
                    </div>
                </Card>
            </div>
        )
    }

};

export default SptFormView;